"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tradeStopFadePoints = exports.soundFiles = exports.positionOpenFadePoints = exports.SOUNDSTYLES = void 0;
var soundFiles = exports.soundFiles = {
  click: '/sounds/shared/click/pos.m4a',
  bell: {
    a: '/sounds/shared/bell/a.m4a',
    b: '/sounds/shared/bell/b.m4a',
    c: '/sounds/shared/bell/c.m4a',
    d: '/sounds/shared/bell/d.m4a'
  },
  tradeStop: {
    loss: '/sounds/shared/trade_stop/loss.m4a',
    win1: '/sounds/shared/trade_stop/win1.m4a',
    win2: '/sounds/shared/trade_stop/win2.m4a',
    win3: '/sounds/shared/trade_stop/win3.m4a'
  },
  positionOpen: {
    africa: {
      lneg1: '/sounds/africa/position_open/L-1.m4a',
      l0: '/sounds/africa/position_open/L0.m4a',
      l1: '/sounds/africa/position_open/L1.m4a',
      l2: '/sounds/africa/position_open/L2.m4a',
      l3: '/sounds/africa/position_open/L3.m4a',
      l4: '/sounds/africa/position_open/L4.m4a',
      l5: '/sounds/africa/position_open/L5.m4a',
      l6: '/sounds/africa/position_open/L6.m4a'
    },
    classic: {
      lneg1: '/sounds/classic/position_open/L-1.m4a',
      l0: '/sounds/classic/position_open/L0.m4a',
      l1: '/sounds/classic/position_open/L1.m4a',
      l2: '/sounds/classic/position_open/L2.m4a',
      l3: '/sounds/classic/position_open/L3.m4a',
      l4: '/sounds/classic/position_open/L4.m4a',
      l5: '/sounds/classic/position_open/L5.m4a',
      l6: '/sounds/classic/position_open/L6.m4a'
    }
  }
};
var SOUNDSTYLES = exports.SOUNDSTYLES = {
  africa: 'africa',
  classic: 'classic'
};
var positionOpenFadePoints = exports.positionOpenFadePoints = {
  lneg1: {
    fadeInPoint: null,
    fadeOutPoint: 8
  },
  l0: {
    fadeInPoint: 8,
    fadeOutPoint: 17
  },
  l1: {
    fadeInPoint: 17,
    fadeOutPoint: 27
  },
  l2: {
    fadeInPoint: 27,
    fadeOutPoint: 39
  },
  l3: {
    fadeInPoint: 39,
    fadeOutPoint: 53
  },
  l4: {
    fadeInPoint: 53,
    fadeOutPoint: 69
  },
  l5: {
    fadeInPoint: 69,
    fadeOutPoint: 88
  },
  l6: {
    fadeInPoint: 88,
    fadeOutPoint: null
  }
};
var tradeStopFadePoints = exports.tradeStopFadePoints = {
  loss: {
    fadeInPoint: 0,
    fadeOutPoint: 10
  },
  win1: {
    fadeInPoint: 10,
    fadeOutPoint: 30
  },
  win2: {
    fadeInPoint: 30,
    fadeOutPoint: 60
  },
  win3: {
    fadeInPoint: 60,
    fadeOutPoint: 100
  }
};