import React, { PureComponent } from 'react';
import upTriangle from '../icons/up-triangle.svg?url';
import downTriangle from '../icons/down-triangle.svg?url';

export const Direction = {
	Up: 'up',
	Down: 'down',
};

export default class RiseFallIndicator extends PureComponent {
	render() {
		if (this.props.direction === Direction.Up) {
			return <img width="14" height="14" src={upTriangle} alt="up" className="up" />;
		}
		return <img width="14" height="14" src={downTriangle} alt="down" className="down" />;
	}
}
