import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, gatewayService } from 'wintrado-api';
import { LiveAccountOnlyMessage } from 'components/global/LiveAccountOnlyMessage';
import CompetitionCountdown from 'components/main/CompetitionCountdown';
import competitionImage from '../../icons/competition.svg?url';
import Loader from 'components/Loader/Loader';
import LeaderboardContent from 'components/main/LeaderboardContent';
import { isSet } from '../../helpers';
import { Mobile } from 'utils/responsiveHelpers';
import Button from 'components/Button/Button';
import Input from 'components/form/Input';

const _t = require('counterpart');

class Leaderboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nickname: '', 
			nicknameError: null,
			shouldRefreshRankings: true,
		};
	}

	componentDidMount() {
		const { requestCurrentCompetition } = this.props;
		requestCurrentCompetition();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentCompetition !== this.props.currentCompetition) {
			const { currentCompetition } = this.props;
			if (currentCompetition?.data?.competition && !currentCompetition.data.participation) {
				this.getLastNickname();
			}
		}
	}

	getLastNickname = async () => {
		try {
			const response = await gatewayService.getLastNickname();
			if (response.nickname) {
				this.setState({
					nickname: response.nickname,
				});
			}
		} catch (e) {}
	};

	joinCompetition = () => {
		const { nickname } = this.state;
		const { requestJoinCompetition } = this.props;

		if (nickname !== null && nickname.length >= 5 && nickname.length <= 10) {
			this.setState({
				nicknameError: null,
			});
			requestJoinCompetition(nickname);
		} else {
			this.setState({
				nicknameError: 'leaderboard.nickname_length_error',
			});
		}
	};

	handleNicknameInput = (e) => {
		this.setState({
			nickname: e.target.value,
		});
	};

	handleNicknameKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			this.joinCompetition();
		}
	};

	renderNicknameInput = () => {
		const { currentCompetition } = this.props;
		return (
			<div className="nickname-container">
				<span className="subtitle">{_t.translate('leaderboard.nickname_description')}</span>
				<div className="image-container">
					<img src={competitionImage} alt="competition" />
				</div>
				<div className="nickname-input-container">
					<div className="input-wrapper">
						<Input
							type="text"
							id="nickname"
							placeholder={_t.translate('leaderboard.nickname_label')}
							value={this.state.nickname}
							onChange={this.handleNicknameInput}
							onKeyDown={this.handleNicknameKeyDown}
							required
						/>
						{this.state.nicknameError && (
							<div className="invalid-feedback d-block">{_t.translate(this.state.nicknameError)}</div>
						)}
						{this.props.joinCompetition?.error && (
							<div className="invalid-feedback d-block">{_t.translate('error.unexpected_error_occurred')}</div>
						)}
					</div>
					<Button type="submit" green fullWidth className="save" onClick={this.joinCompetition}>
						{_t.translate('leaderboard.nickname_submit_label')}
					</Button>
					{isSet(currentCompetition.data?.competition?.tcLink) && (
						<a href={currentCompetition.data?.competition?.tcLink} target="_blank" rel="noreferrer">
							<Button black fullWidth className="terms">
								{_t.translate('main.terms_conditions')}
							</Button>
						</a>
					)}
				</div>
			</div>
		);
	};

	renderNoActiveCompetition = () => {
		const { currentCompetition } = this.props;
		return (
			<>
				<span className="subtitle">
					{_t.translate('leaderboard.no_active_competition_label')}
					<br />
					{_t.translate('leaderboard.please_check_back_later')}
				</span>

				<div className="image-wrapper">
					<img
						className="no-active-competitions"
						src={require('../../images/no_active_competitions.png')}
						alt="no-active-competitions"
					/>
				</div>

				{Boolean(currentCompetition?.data?.competition) && (
					<CompetitionCountdown startDate={new Date(currentCompetition.data.competition.start)} />
				)}
			</>
		);
	};

	disableRefreshRankings = () => {
		this.setState({
			shouldRefreshRankings: false,
		});
	};

	renderLeaderboardContent = () => {
		const { currentCompetition } = this.props;

		if (!currentCompetition || currentCompetition.loading) {
			return <Loader transparentBackground />;
		}

		if (currentCompetition.error) {
			return this.renderNoActiveCompetition();
		}

		const isCompetitionActive =
			Boolean(currentCompetition?.data?.competition) &&
			new Date(currentCompetition.data.competition.start) <= new Date();

		if (!isCompetitionActive) {
			return this.renderNoActiveCompetition();
		}

		if (isCompetitionActive) {
			return (
				<>
					{currentCompetition.data.participation ? (
						<LeaderboardContent
							shouldRefreshRankings={this.state.shouldRefreshRankings}
							disableRefreshRankings={this.disableRefreshRankings}
						/>
					) : (
						this.renderNicknameInput()
					)}
					<Mobile>
						{currentCompetition.data.competition !== null && (
							<CompetitionCountdown
								endDate={new Date(currentCompetition.data.competition.end)}
								onCompetitionEnded={this.disableRefreshRankings}
							/>
						)}
					</Mobile>
				</>
			);
		}
	};

	renderLiveAccountOnlyMessage = () => (
		<div className="live-account-only-container">
			<LiveAccountOnlyMessage />
		</div>
	);

	render() {
		const { isDemo } = this.props;

		return (
			<div className="leaderboard__container" style={{ height: '100%' }}>
				<div className="leaderboard content-box">
					<div className="leaderboard__holder">
						<div className="leaderboard__heading">
							<div style={{width: "100%"}}>
								<h3>{_t.translate('leaderboard.heading')}</h3>
							</div>
						</div>
						{isDemo ? this.renderLiveAccountOnlyMessage() : this.renderLeaderboardContent()}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		joinCompetition: state.leaderboard.joinCompetition,
		currentCompetition: state.leaderboard.currentCompetition,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			requestCurrentCompetition: actions.requestCurrentCompetition,
			requestJoinCompetition: actions.requestJoinCompetition,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
