import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { formatCurrency } from 'utils/currency';
import styles from './BalancePill.module.scss';
import { useAppSelector } from 'wintrado-api';
const BalancePill = ({ balance }) => {
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    return _jsx("span", Object.assign({ className: styles.balancePill }, { children: formatCurrency(balance, accountCurrency) }));
};
export default React.memo(BalancePill);
