import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { apiActions, apiDisconnected, displayError, fetchMessagesUnreadCount, getTrendQuotes, LOGIN_REQUEST, LOGOFF_REQUEST, API_LOGIN_SUCCESS, REQUEST_OAUTH_TOKENS, requestCurrentCompetition, requestOpenPositionsList, setOAuthTokens, userSaveLanguage, receiveInstruments, REQUEST_INSTRUMENTS, requestInstruments, requestGreyLabelLogo, fetchUser, clearUserLoginTokens, API_LOGIN_REJECT, fetchOAuthTokensReject, FETCH_OAUTH_TOKENS_REJECT, FETCH_USER_SUCCESS, FETCH_USER_REJECT, } from '../actions';
import { getAllInstruments } from '../services/crmService';
import { getConfig } from '../utils/config';
import { getOAuthTokensWithCode, logout } from '../services/oAuthService';
import { supportsCompetitionsSelector, supportsMessagesSelector, } from '../selectors';
import Analytics from '../utils/Analytics';
function* fetchAllInstruments() {
    try {
        const { instruments, timezone } = yield call(getAllInstruments);
        yield put(receiveInstruments(instruments, timezone));
    }
    catch (error) {
        yield put(displayError('Failed fetching instruments', error.message));
    }
}
function* loginSuccessSaga() {
    yield put(requestGreyLabelLogo());
    yield put(requestInstruments());
    yield put(requestOpenPositionsList());
    const supportsCompetitions = yield select(supportsCompetitionsSelector);
    const supportsMessages = yield select(supportsMessagesSelector);
    if (supportsCompetitions) {
        yield put(requestCurrentCompetition());
    }
    if (supportsMessages) {
        yield put(fetchMessagesUnreadCount());
    }
    yield put(getTrendQuotes());
    const id = yield select((state) => state.user.id);
    if (id != null) {
        Analytics.identifyUser(id);
    }
}
function* loginRejectSaga(action) {
    const { message } = action.payload;
    yield put(apiDisconnected());
    yield put(clearUserLoginTokens());
    yield put(displayError('LOGIN_REJECT', message));
}
function* loginRequest(action) {
    const version = yield select((state) => state.releaseVersion);
    // Fetch user information using the REST client as this will automatically refresh the access token
    // if it has expired. The new access token will then be used for the WebSocket login.
    yield put(fetchUser());
    // Wait for the user fetch to finish so that we can use the latest token.
    // If the user fetch gets rejected, we stop here and don't attempt the WebSocket login.
    // @ts-ignore
    const res = yield take([FETCH_USER_SUCCESS, FETCH_USER_REJECT]);
    if (res.type === FETCH_USER_REJECT) {
        return;
    }
    const { token, communicationLanguage, supportedFeatures } = yield select((state) => state.user);
    if (communicationLanguage) {
        yield put(userSaveLanguage(communicationLanguage, true));
    }
    yield put({
        type: apiActions.LOGIN,
        payload: {
            token,
            version,
            supportedFeatures,
        },
    });
}
function* logoffRequest(action) {
    const { withOAuth } = action.payload;
    const refreshToken = yield select((state) => state.user.refreshToken);
    let redirectUri = null;
    if (withOAuth) {
        try {
            redirectUri = yield call(logout, refreshToken, window.location.origin);
        }
        catch (e) {
            throw new Error(`Failed to log out: ${e}`);
        }
    }
    yield put(clearUserLoginTokens());
    Analytics.resetUserData();
    if (redirectUri !== null) {
        // @ts-expect-error
        window.location = redirectUri;
    }
}
function* requestOAuthTokensSaga(action) {
    const { code, redirectUri } = action.payload;
    const { clientId, clientSecret } = getConfig().oAuth;
    const grantType = 'authorization_code';
    // Clear any existing tokens before requesting new ones to prevent race conditions
    // on login where the old tokens will still be used.
    yield put(clearUserLoginTokens());
    if (!clientId || !clientSecret) {
        throw new Error('clientId & clientSecret must be set in config');
    }
    try {
        const { access_token, refresh_token } = yield call(getOAuthTokensWithCode, code, clientId, clientSecret, redirectUri, grantType);
        yield put(setOAuthTokens(access_token, refresh_token));
    }
    catch (e) {
        yield put(fetchOAuthTokensReject(e.message));
    }
}
function* loginSaga() {
    yield takeLatest(LOGIN_REQUEST, loginRequest);
    yield takeLatest(API_LOGIN_SUCCESS, loginSuccessSaga);
    yield takeLatest([API_LOGIN_REJECT, FETCH_OAUTH_TOKENS_REJECT], loginRejectSaga);
    yield takeLatest(LOGOFF_REQUEST, logoffRequest);
    yield takeLatest(REQUEST_OAUTH_TOKENS, requestOAuthTokensSaga);
    yield takeLatest(REQUEST_INSTRUMENTS, fetchAllInstruments);
}
export default loginSaga;
