import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { crmService } from 'wintrado-api/src';
import TradingStrategyRow from 'components/copyTrading/TradingStrategyRow';
import InlineLoader from 'components/InlineLoader';
import StrategyFilters from 'components/copyTrading/StrategyFilters/StrategyFilters';
import useStrategyFilters from '../../hooks/useStrategyFilters';
const AllStrategies = () => {
    const [filters, setSearchValue, setSortValue, setRangeFilters] = useStrategyFilters();
    const { data, error, isLoading } = useQuery(['copytrading-strategies', filters], () => crmService.getCopyTradingStrategies(100, 0, filters.searchValue, filters.sortValue, filters.rangeFilters));
    const renderContent = useMemo(() => {
        if (error) {
            return _jsx("span", { children: error.toString() });
        }
        if (isLoading) {
            return _jsx(InlineLoader, {});
        }
        return data.strategies.map((strategy) => _jsx(TradingStrategyRow, { strategy: strategy }, strategy.id));
    }, [error, isLoading, data]);
    return (_jsxs(_Fragment, { children: [_jsx(StrategyFilters, { initialValues: filters, searchValueChanged: setSearchValue, sortValueChanged: setSortValue, filtersChanged: setRangeFilters }), renderContent] }));
};
export default React.memo(AllStrategies);
