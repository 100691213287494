import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import config from 'config';
import { playClickSound, requestOAuthLogin } from '../../helpers';
import _t from 'counterpart';
import Button from 'components/Button/Button';
import { OpenAccountButton } from 'components/main/OpenAccountButton';
import { useAppSelector } from 'wintrado-api';
const LoginButton = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const handleLogin = (event) => {
        event.preventDefault();
        event.stopPropagation();
        playClickSound();
        if (config.cashier.loginLink !== undefined) {
            window.location = config.cashier.loginLink(userLanguage);
        }
        else {
            requestOAuthLogin(userLanguage);
        }
    };
    return (_jsx(Button, Object.assign({ green: true, fullWidth: true, onClick: handleLogin }, { children: _t.translate('login.submit') })));
};
const LoginForm = () => {
    return (_jsxs("div", Object.assign({ className: "close-login login__form form" }, { children: [_jsxs("div", Object.assign({ className: "login__submit" }, { children: [_jsx(LoginButton, {}), Boolean(config.cashier.registrationLink) && (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "login__buttons_separator_label" }, { children: _t.translate('main.or') })), _jsx(OpenAccountButton, { fullWidth: true })] }))] })), _jsx("div", { className: "login__links" })] })));
};
export default LoginForm;
