import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { INSTRUMENTS_RECEIVED, INSTRUMENTS_SET_ACTIVE, RESUBSCRIBE_QUOTES, setActiveInstrument, subscribeToQuotesBatchForSymbols, subscribeToQuotesForSymbols, unsubscribeFromBatchQuotes, unsubscribeFromQuotes, updateInstruments, } from '../actions';
import * as instrumentsHelpers from '../utils/instrumentsHelpers';
import { activePositionSelector } from '../selectors';
import { select } from 'redux-saga/effects';
const BATCH_QUOTES_INTERVAL = 1000;
const BATCH_QUOTES_FORMAT = 'protobuf';
function* handleReceiveInstruments(action) {
    const { instruments, timezone } = action.payload;
    const activeInstrumentSymbol = yield select((state) => state.activeInstrumentSymbol);
    const activePosition = yield select(activePositionSelector);
    const instrumentToPreselect = yield select((state) => state.settings.instrumentToPreselect);
    const cleanInstruments = instruments.reduce((acc, dataEntry) => {
        if (dataEntry.symbol === undefined) {
            return acc;
        }
        acc[dataEntry.symbol] =
            instrumentsHelpers.sanitizeBaseInstrument(dataEntry);
        return acc;
    }, {});
    yield put(updateInstruments(cleanInstruments, timezone));
    let instrumentToSetActive = null;
    if (activePosition === null || activePosition === void 0 ? void 0 : activePosition.symbol) {
        instrumentToSetActive = activePosition.symbol;
    }
    else if (instrumentToPreselect && cleanInstruments[instrumentToPreselect]) {
        instrumentToSetActive = instrumentToPreselect;
    }
    else if (activeInstrumentSymbol &&
        cleanInstruments[activeInstrumentSymbol]) {
        instrumentToSetActive = activeInstrumentSymbol;
    }
    if (instruments.length === 0) {
        return;
    }
    if (instrumentToSetActive) {
        yield put(setActiveInstrument(instrumentToSetActive));
    }
    else {
        yield put(setActiveInstrument(instruments[0].symbol));
    }
}
function* resubscribeBatch(symbols) {
    const instrumentsBatchSubscriptionId = yield select((state) => state.instrumentsBatchSubscriptionId);
    if (instrumentsBatchSubscriptionId) {
        yield put(unsubscribeFromBatchQuotes(instrumentsBatchSubscriptionId));
    }
    yield put(subscribeToQuotesBatchForSymbols(symbols, BATCH_QUOTES_FORMAT, BATCH_QUOTES_INTERVAL));
}
function* resubscribeSingle(symbol) {
    const activeInstrumentSubscriptionId = yield select((state) => state.activeInstrumentSubscriptionId);
    if (activeInstrumentSubscriptionId) {
        yield put(unsubscribeFromQuotes(activeInstrumentSubscriptionId));
    }
    yield put(subscribeToQuotesForSymbols([symbol]));
}
function* handleResubscribeQuotes(action) {
    const activeInstrumentSymbol = yield select((state) => state.activeInstrumentSymbol);
    const instruments = yield select((state) => state.instruments);
    const instrumentsForBatch = Object.keys(instruments).filter((i) => i !== activeInstrumentSymbol);
    if (activeInstrumentSymbol) {
        yield resubscribeSingle(activeInstrumentSymbol);
    }
    yield resubscribeBatch(instrumentsForBatch);
}
function* instrumentsSaga() {
    yield takeEvery(INSTRUMENTS_RECEIVED, handleReceiveInstruments);
    yield takeLatest([RESUBSCRIBE_QUOTES, INSTRUMENTS_SET_ACTIVE], handleResubscribeQuotes);
}
export default instrumentsSaga;
