import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './WalletTypeBadge.module.scss';
const WalletTypeBadge = ({ isDemo }) => {
    if (!isDemo) {
        return null;
    }
    return _jsx("div", Object.assign({ className: styles.container }, { children: "Demo" }));
};
export default React.memo(WalletTypeBadge);
