import React from 'react';
import { LeaderboardListRow } from 'components/main/LeaderboardListRow';
import { Flipped, Flipper } from 'react-flip-toolkit';

export class LeaderboardList extends React.PureComponent {
	renderListRow = (item) => {
		return (
			<Flipped key={item.nickname + item.rank} flipId={item.nickname + item.rank}>
				<>
					<LeaderboardListRow
						rank={item.rank}
						username={item.nickname}
						score={item.points}
						isCurrentUser={item.own}
						previousRank={item.previousRank}
						competition={this.props.competition}
					/>
				</>
			</Flipped>
		);
	};

	render() {
		const { ranks } = this.props;

		const currentUserIndex = ranks.slice.findIndex((item) => item.own);
		const currentUserSurroundingRankings =
			(currentUserIndex < 0 && currentUserIndex > ranks.slice.length - 10) >= 0
				? ranks.slice
				: ranks.slice.slice(currentUserIndex - 2, currentUserIndex + 10);
		const joinedRankings = [...ranks.top, ...currentUserSurroundingRankings];
		const rankingIds = joinedRankings.map((item) => item.nickname + item.rank);

		return (
			<Flipper className="flipper" flipKey={rankingIds}>
				<div className="leaderboard-list">{joinedRankings.map(this.renderListRow)}</div>
			</Flipper>
		);
	}
}
