import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../Modal';
import _t from 'counterpart';
import styles from './ModalCompetition.module.scss';
import Button from 'ui/components/Button/Button';
import classNames from 'classnames';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import competition1Prize from '../../../images/modals/competition-1-prize.svg?url';
import competition2Prizes from '../../../images/modals/competition-2-prizes.svg?url';
import competition3Prizes from '../../../images/modals/competition-3-prizes.svg?url';
import { playClickSound } from '../../../helpers';
import AnyLink from 'components/global/AnyLink';
import { useLocation } from 'react-router-dom';
import Spacer from 'components/Spacer/Spacer';
import { useAppSelector } from 'wintrado-api';
const ModalCompetition = ({ setRightSideWidget }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalShown, setModalShown] = useState(false);
    const [leaderboardAlreadyVisited, setLeaderboardAlreadyVisited] = useState(false);
    const location = useLocation();
    const timeout = useRef(null);
    const currentCompetition = useAppSelector((state) => state.leaderboard.currentCompetition);
    const firstPrize = useMemo(() => { var _a, _b; return (_b = (_a = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _a === void 0 ? void 0 : _a.competition) === null || _b === void 0 ? void 0 : _b.firstPrizeInUSD; }, [currentCompetition]);
    const secondPrize = useMemo(() => { var _a, _b; return (_b = (_a = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _a === void 0 ? void 0 : _a.competition) === null || _b === void 0 ? void 0 : _b.secondPrizeInUSD; }, [currentCompetition]);
    const thirdPrize = useMemo(() => { var _a, _b; return (_b = (_a = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _a === void 0 ? void 0 : _a.competition) === null || _b === void 0 ? void 0 : _b.thirdPrizeInUSD; }, [currentCompetition]);
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    const handleMobileJoinClicked = () => {
        setIsOpen(false);
        playClickSound();
    };
    const handleDesktopJoinClicked = () => {
        handleMobileJoinClicked();
        setRightSideWidget('leaderboard');
    };
    const getImageToUse = () => {
        var _a;
        if (!((_a = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _a === void 0 ? void 0 : _a.competition)) {
            return null;
        }
        const { firstPrizeInUSD, secondPrizeInUSD, thirdPrizeInUSD } = currentCompetition.data.competition;
        if (!firstPrizeInUSD) {
            throw new Error('firstPrizeInUSD must be defined for an active competition');
        }
        if (firstPrizeInUSD && secondPrizeInUSD && thirdPrizeInUSD) {
            return competition3Prizes;
        }
        if (firstPrizeInUSD && secondPrizeInUSD) {
            return competition2Prizes;
        }
        return competition1Prize;
    };
    useEffect(() => {
        if (location.pathname.indexOf('leaderboard') >= 0) {
            setLeaderboardAlreadyVisited(true);
        }
    }, [location.pathname]);
    useEffect(() => {
        var _a, _b;
        if (!((_a = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _a === void 0 ? void 0 : _a.competition) ||
            ((_b = currentCompetition === null || currentCompetition === void 0 ? void 0 : currentCompetition.data) === null || _b === void 0 ? void 0 : _b.participation) ||
            !currentCompetition.data.competition.promoted ||
            modalShown ||
            leaderboardAlreadyVisited) {
            return;
        }
        const startTime = new Date(currentCompetition.data.competition.start);
        if (startTime.getTime() < new Date().getTime()) {
            setModalShown(true);
            timeout.current = setTimeout(() => {
                setIsOpen(true);
            }, 5500);
        }
    }, [currentCompetition, modalShown, leaderboardAlreadyVisited]);
    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);
    return (_jsx(Modal, Object.assign({ title: _t('leaderboard.competition_title'), description: _t.translate('leaderboard.competition_description', { amount: `$${firstPrize}` }), onRequestClose: handleCloseModal, isOpen: isOpen, className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.holder }, { children: [_jsxs("div", Object.assign({ className: styles.illustration }, { children: [_jsx("img", { src: getImageToUse(), alt: "prizes" }), _jsxs("div", Object.assign({ className: styles.smallLabels }, { children: [_jsx("span", Object.assign({ className: classNames({ hidden: true }) }, { children: `$${secondPrize}` })), _jsx("span", Object.assign({ className: classNames({ hidden: thirdPrize === null }) }, { children: `$${thirdPrize}` }))] })), _jsx("span", Object.assign({ className: styles.bigLabel }, { children: `$${firstPrize}` }))] })), _jsx(Spacer, { y: true, large: true }), _jsx(Desktop, { children: _jsx(Button, Object.assign({ variant: "primary", className: styles.button, onClick: handleDesktopJoinClicked, flexible: true }, { children: _t.translate('leaderboard.join_now') })) }), _jsx(Mobile, { children: _jsx(AnyLink, Object.assign({ to: "/leaderboard", onClick: handleMobileJoinClicked }, { children: _jsx(Button, Object.assign({ variant: "primary", className: styles.button, flexible: true }, { children: _t.translate('leaderboard.join_now') })) })) })] })) })));
};
export default React.memo(ModalCompetition);
