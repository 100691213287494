import React from 'react';
import { formatCurrency } from 'utils/currency';

const _t = require('counterpart');

export default class TotalFooter extends React.Component {
	render() {
		const { profitLossInAccountCurrency, accountCurrency } = this.props;

		if (profitLossInAccountCurrency === null) return null;

		return (
			<div className="total-footer">
				<span className="total-footer__label">{_t('main.total')}</span>
				<div className={`total-footer__amount ${profitLossInAccountCurrency >= 0 ? 'positive' : 'negative'}`}>
					<span>{formatCurrency(profitLossInAccountCurrency, accountCurrency)}</span>
				</div>
			</div>
		);
	}
}
