import { UPDATE_USER_SUCCESS, SET_OAUTH_TOKENS, UPDATE_USER_LOYALTY_LEVEL, FETCH_USER_SUCCESS, CLEAR_USER_LOGIN_TOKENS, } from '../../actions';
import { isSet } from '../../utils/helpers';
export default function userReducer(state, action) {
    switch (action.type) {
        case FETCH_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { id: action.payload.user.id, name: action.payload.user.name, referralURL: action.payload.user.referralURL, avatarUrl: action.payload.user.avatarUrl, supportedFeatures: action.payload.supportedFeatures ? action.payload.supportedFeatures : null, communicationLanguage: isSet(action.payload.user.communicationLanguage)
                        ? action.payload.user.communicationLanguage
                        : state.user.communicationLanguage, mtLogin: action.payload.user.mtAccountId, wallet: action.payload.wallet, loyaltyLevel: action.payload.user.loyaltyLevel }) });
        case SET_OAUTH_TOKENS:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { token: action.payload.accessToken, refreshToken: action.payload.refreshToken }) });
        case UPDATE_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { communicationLanguage: isSet(action.payload.communicationLanguage)
                        ? action.payload.communicationLanguage
                        : state.user.communicationLanguage }) });
        case UPDATE_USER_LOYALTY_LEVEL:
            return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { loyaltyLevel: action.payload.loyaltyLevel }) });
        case CLEAR_USER_LOGIN_TOKENS:
            return (Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { token: null, refreshToken: null }) }));
        default:
            return state;
    }
}
