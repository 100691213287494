import { all, call, delay, put, race, select, take, takeEvery, takeLatest, } from 'redux-saga/effects';
import { API_DISCONNECTED, appendQuoteForSymbol, mergeQuotes, PRIME_QUOTES_RECEIVED, primeQuotesSet, QUOTE_RECEIVED, QUOTES_RECEIVED, setQuotes, setQuotesForSymbol } from "../actions";
import quotesHelpers from "../utils/quotesHelpers";
import { isSet } from "../utils/helpers";
import { resolutionToStartDate } from "../utils/aggregateCandles";
function* receiveQuote(quote) {
    const { symbol } = quote;
    if (!isSet(symbol)) {
        console.error('Received quote without symbol', quote);
        return;
    }
    const activeInstrumentSymbol = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    const currentQuote = quotesHelpers.sanitizeSingleQuote(quote);
    if (activeInstrumentSymbol === symbol) {
        yield put(appendQuoteForSymbol(symbol, currentQuote));
    }
    else {
        yield put(setQuotesForSymbol(symbol, [currentQuote]));
    }
}
function* receiveQuotes(quotes) {
    const sanitizedQuotes = quotesHelpers.sanitizeQuotes(quotes);
    const activeInstrumentSymbol = yield select((state) => { var _a; return (_a = state.activeInstrument) === null || _a === void 0 ? void 0 : _a.symbol; });
    const quotesBySymbol = sanitizedQuotes.reduce((acc, quote) => {
        if (!acc[quote.symbol]) {
            acc[quote.symbol] = [];
        }
        acc[quote.symbol].push(quote);
        return acc;
    }, {});
    // we don't want to overtwrite the activeInstrument quotes because those are updated in the appendQuoteForSymbol
    // this should absolutely be separated into latestQuotes (one per instrument) and historicalQuotes (one instrument, multiple quotes)
    if (activeInstrumentSymbol) {
        delete quotesBySymbol[activeInstrumentSymbol];
    }
    yield put(mergeQuotes(quotesBySymbol));
}
function* handleQuoteReceived(action) {
    let data = action.payload;
    yield call(receiveQuote, data);
}
function* handleQuotesReceived(action) {
    const { quotes } = action.payload;
    yield call(receiveQuotes, quotes);
}
function* handlePrimeQuotesReceived(action) {
    const { quotes } = action.payload;
    yield call(receiveQuotes, quotes);
    yield put(primeQuotesSet());
}
function* cleanQuotesSaga() {
    while (true) {
        yield delay(20 * 1000);
        const chartResolution = yield select((state) => state.settings.chartResolution);
        const removeOlderThan = resolutionToStartDate(chartResolution);
        const quotes = yield select((state) => state.quotes);
        const clearedQuotes = quotesHelpers.clearOldQuotes(Object.assign({}, quotes), removeOlderThan);
        yield put(setQuotes(clearedQuotes));
    }
}
function* quotesSaga() {
    yield all([
        takeLatest(QUOTES_RECEIVED, handleQuotesReceived),
        takeEvery(QUOTE_RECEIVED, handleQuoteReceived),
        takeEvery(PRIME_QUOTES_RECEIVED, handlePrimeQuotesReceived),
        race([
            call(cleanQuotesSaga),
            take(API_DISCONNECTED),
        ])
    ]);
}
export default quotesSaga;
