import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'wintrado-api';
import Drawer from 'react-motion-drawer';
import OpenPositionsContainer from 'components/OpenPositionsContainer';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import { MainCardHeader } from 'components/MainCardHeader/MainCardHeader';
import { playClickSound } from '../../helpers';
import Button from 'components/Button/Button';
import DoubleArrowIcon from "../../icons/double_arrow-material.svg"
const _t = require('counterpart');

let closeAllSliderOpen = false;

class MainPositionsList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			closeAllSliderVisible: false,
		};
	}

	drawerTransformStyle = {
		position: 'absolute',
		zIndex: 10,
	};

	handleSliderChange = (opened) => {
		if (opened && !closeAllSliderOpen) {
			closeAllSliderOpen = true;
			setTimeout(() => {
				this.setState({
					closeAllSliderVisible: true,
				});
				this.props.positionsCloseAll();
				closeAllSliderOpen = false;
			}, 600);
		} else {
			closeAllSliderOpen = false;
		}
	};

	handleStopAllClicked = (e) => {
		e.preventDefault();
		playClickSound();
		this.props.positionsCloseAll();
	};

	renderOpenPositionsCounterBubble = () => {
		const { openPositions } = this.props;
		if (openPositions.length > 0) {
			return <span className="counter-bubble">{openPositions.length}</span>;
		}
		return null;
	};

	renderStopAllButton = () => {
		const { openPositions, closingAllPositions } = this.props;
		if (openPositions.length > 0 && !closingAllPositions) {
			return (
				<Button id="tradingButtonStop" gray slim onClick={this.handleStopAllClicked}>
					{_t.translate('main.stop-all')}
				</Button>
			);
		}
		return null;
	};

	renderPositions() {
		const { openPositions, closingAllPositions } = this.props;
		return (
			<div className="content-box scroll positions-list" id="positionsSelector">
				<MainCardHeader
					title={_t.translate('main.trades')}
					titleAppend={this.renderOpenPositionsCounterBubble()}
					endComponent={this.renderStopAllButton()}
				/>

				<ul id="runningTradesList" className="trades_list trades_list--running wt-scroll">
					<OpenPositionsContainer />
				</ul>

				{openPositions.length > 0 && !closingAllPositions && (
					<Mobile>
						<Drawer
							open={this.state.closeAllSliderVisible}
							width="100%"
							onChange={this.handleSliderChange}
							className="vertical-bar-blue"
							overlayClassName="vertical-bar-blue__overlay"
							transformStyle={this.drawerTransformStyle}
							right
						>
							<DoubleArrowIcon/>
							<span>Swipe left to close all positions.</span>
						</Drawer>
					</Mobile>
				)}
			</div>
		);
	}

	render() {
		const { openPositions } = this.props;
		if (openPositions.length === 0) {
			return (
				<>
					<Desktop>{this.renderPositions()}</Desktop>
				</>
			);
		}
		return this.renderPositions();
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		activePositionId: state.activePositionId,
		openPositions: selectors.openPositionsWithClosingSelector(state),
		closingAllPositions: state.closingAllPositions,
		accountCurrency: state.user.accountCurrency,
		balance: state.user.balance,
		instruments: state.instruments,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			setActivePositionId: actions.setActivePositionId,
			positionsCloseAll: actions.positionsCloseAll,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPositionsList);
