import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _t from 'counterpart';
import positionsIcon from '../../icons/positions.svg?url';
import marketsIcon from '../../icons/markets.svg?url';
import leaderboardIcon from '../../icons/trophy.svg?url';
import config from 'config';
import Tooltip from 'components/Tooltip/Tooltip';
import referIcon from '../../icons/refer.svg?url';
import targetIcon from '../../icons/target.svg?url';
import depositWithdrawIcon from '../../icons/wallet.png';
import uploadDocumentsIcon from '../../icons/upload-documents.svg?url';
import logoutIcon from '../../icons/logout.svg?url';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import MessagesIcon from 'components/MessagesIcon';
import { selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import { showDepositWithdrawalModal, logoff } from 'wintrado-api/src/actions';
const SidebarRight = ({ isTradingScreenVisible, setRightSideWidget, displayTwoRightWidgets }) => {
    const [referTooltipVisible, setReferTooltipVisible] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const rightSideWidget = useAppSelector((state) => state.tradingScreen.rightSideWidget);
    const referralURL = useAppSelector((state) => state.user.referralURL);
    const referFriendsEnabled = useAppSelector((state) => state.settings.features.referFriendsEnabled);
    const token = useAppSelector((state) => state.user.token);
    const supportsCompetitions = useAppSelector(selectors.supportsCompetitionsSelector);
    const supportsMessages = useAppSelector(selectors.supportsMessagesSelector);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const referralInputRef = useRef(null);
    const hideReferTooltip = useCallback((e) => {
        var _a;
        const mousedownInsideTooltip = Boolean((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.closest('.react-tooltip-lite'));
        if (!mousedownInsideTooltip && referTooltipVisible) {
            setReferTooltipVisible(false);
        }
    }, [referTooltipVisible]);
    useEffect(() => {
        document.addEventListener('mousedown', hideReferTooltip);
        return () => {
            document.removeEventListener('mousedown', hideReferTooltip);
        };
    }, [hideReferTooltip]);
    const toggleReferTip = useCallback(() => {
        setReferTooltipVisible((prevState) => !prevState);
        setCopiedToClipboard(false);
    }, []);
    const handleReferralLinkFocused = useCallback(() => {
        copyReferralToClipboard();
    }, []);
    const copyReferralToClipboard = () => {
        var _a, _b;
        (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        (_b = referralInputRef.current) === null || _b === void 0 ? void 0 : _b.select();
        document.execCommand('copy');
        setCopiedToClipboard(true);
        setTimeout(() => {
            var _a;
            setReferTooltipVisible(false);
            setCopiedToClipboard(false);
            (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.removeAllRanges();
        }, 4500);
    };
    const isRightSideWidgetActive = useCallback((name) => {
        return isTradingScreenVisible && rightSideWidget === name;
    }, [isTradingScreenVisible, rightSideWidget]);
    const isMarketsAndPositionsActive = useMemo(() => {
        if (!isTradingScreenVisible)
            return false;
        return new RegExp('positions|markets').test(rightSideWidget) && displayTwoRightWidgets;
    }, [displayTwoRightWidgets, isTradingScreenVisible, rightSideWidget]);
    const pageIsDepositOrWithdraw = new RegExp('deposit|withdraw').test(location.pathname);
    return (_jsx(_Fragment, { children: _jsx("section", Object.assign({ id: "sidebarRight", className: "sidemenu" }, { children: _jsxs("ul", { children: [_jsx("li", { children: supportsMessages && (_jsx(NavLink, Object.assign({ to: "/messages", className: "messages" }, { children: _jsx(MessagesIcon, {}) }))) }), _jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.open_positions'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({
                                    active: isRightSideWidgetActive('positions') || (isMarketsAndPositionsActive && location.pathname === '/'),
                                }), onClick: () => setRightSideWidget('positions') }, { children: _jsx("img", { src: positionsIcon, alt: "positions-icon" }) })) })) }), _jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.markets'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({
                                    active: isRightSideWidgetActive('markets') || (isMarketsAndPositionsActive && location.pathname === '/'),
                                }), onClick: () => setRightSideWidget('markets') }, { children: _jsx("img", { src: marketsIcon, alt: "markets-icon" }) })) })) }), config.cashier && (config.cashier.depositURL || config.cashier.withdrawURL) && (_jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.withdrawOrDeposit'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", onClick: () => {
                                    dispatch(showDepositWithdrawalModal());
                                }, className: classNames({ active: pageIsDepositOrWithdraw }) }, { children: _jsx("img", { src: depositWithdrawIcon, alt: "deposit-withdraw-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) })) })), config.cashier && config.cashier.uploadDocumentsURL && (_jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.upload-documents'), side: "left" }, { children: _jsx(NavLink, Object.assign({ to: "/upload-documents" }, { children: _jsx("img", { src: uploadDocumentsIcon, alt: "upload-documents-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) })) })), supportsCompetitions && (_jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('leaderboard.heading'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames({
                                    active: isRightSideWidgetActive('leaderboard') && location.pathname === '/',
                                }), onClick: () => setRightSideWidget('leaderboard') }, { children: _jsx("img", { src: leaderboardIcon, alt: "leaderboard" }) })) })) })), referFriendsEnabled && referralURL && (_jsxs("li", { children: [_jsx(Tooltip, Object.assign({ content: _t.translate('main.invite_label'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", className: classNames('refer', {
                                        active: referTooltipVisible,
                                    }), onClick: toggleReferTip }, { children: _jsx("img", { src: referIcon, alt: "refer-icon" }) })) })), _jsx(Tooltip, Object.assign({ isOpen: referTooltipVisible, content: 
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                _jsxs("div", Object.assign({ onClick: copyReferralToClipboard, onKeyDown: copyReferralToClipboard }, { children: [_jsx("div", Object.assign({ role: "button", tabIndex: 0, className: "copied-label" }, { children: copiedToClipboard ? _t.translate('main.copied_long') : _t.translate('main.copy_label') })), _jsx("input", { ref: referralInputRef, className: "referral-link-input", type: "text", value: _t.translate('main.invite_link', {
                                                referralURL,
                                            }), onFocus: handleReferralLinkFocused, readOnly: true })] })), className: "refer-tooltip", side: "left", controlled: true }, { children: _jsx("span", Object.assign({ style: { height: '0px', display: 'block' } }, { children: _jsx("button", { disabled: true, style: { pointerEvents: 'none' } }) })) }))] })), config.affiliatePortalUrl && (_jsx("li", { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.marketing'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", onClick: () => {
                                    window.open(`${config.affiliatePortalUrl}/login?token=${token}`, '_blank');
                                } }, { children: _jsx("img", { src: targetIcon, alt: "target-icon", style: {
                                        width: 17,
                                        height: 17,
                                    } }) })) })) })), _jsx("li", Object.assign({ className: "extra logout" }, { children: _jsx(Tooltip, Object.assign({ content: _t.translate('main.logout'), side: "left" }, { children: _jsx("button", Object.assign({ type: "button", onClick: () => {
                                    dispatch(logoff({ userRequested: true, withOAuth: true }));
                                } }, { children: _jsx("img", { src: logoutIcon, alt: "logout" }) })) })) }))] }) })) }));
};
export default React.memo(SidebarRight);
