import React, { Component } from 'react';
import { Mobile } from 'utils/responsiveHelpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainInstrumentList from 'components/main/InstrumentList';

class MobileInstrumentsDrawer extends Component {
	closeDrawer = () => {
		this.props.closeDrawer();
	};

	handleOverlayClicked = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.closeDrawer();
	};

	render() {
		const { open } = this.props;
		return (
			<Mobile>
				<div onClick={this.handleOverlayClicked} className={`instruments-drawer-overlay${open ? ' show' : ''}`} />
				<div className={`instruments-drawer${open ? ' show' : ''}`}>
					<button className="close-drawer" onClick={this.closeDrawer}>
						<img src={require('../../icons/close.svg?url')} alt="close" />
					</button>
					<MainInstrumentList closeDrawer={this.closeDrawer} />
				</div>
			</Mobile>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({}, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileInstrumentsDrawer);
