import React from 'react';
import classNames from 'classnames';

class Pill extends React.PureComponent {
	render() {
		const { blue, children } = this.props;
		return (
			<div
				className={classNames('pill', {
					blue,
				})}
			>
				<span>{children}</span>
			</div>
		);
	}
}

export default Pill;
