import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Modal.module.scss';
import IconButton from 'components/IconButton/IconButton';
import * as Dialog from '@radix-ui/react-dialog';
import { isSet } from '../../helpers';
import classNames from 'classnames';
import close from "../../icons/close-modal.svg?url";
const Modal = ({ isOpen, onRequestClose, title, description, children, trigger, hideTitleDescription = false, className = '', }) => {
    const handleOpenChange = (open) => {
        if (!open) {
            onRequestClose();
        }
    };
    return (_jsxs(Dialog.Root, Object.assign({ open: isOpen, onOpenChange: handleOpenChange }, { children: [isSet(trigger) && _jsx(Dialog.Trigger, Object.assign({ asChild: true }, { children: trigger })), _jsxs(Dialog.Portal, { children: [_jsx(Dialog.Overlay, { className: styles.overlay }), _jsxs(Dialog.Content, Object.assign({ className: `${styles.body} ${className}` }, { children: [_jsxs("div", Object.assign({ className: styles.header }, { children: [_jsx("div", Object.assign({ className: styles.closeButtonWrapper }, { children: _jsx(Dialog.Close, Object.assign({ asChild: true }, { children: _jsx(IconButton, Object.assign({ size: "small" }, { children: _jsx("img", { src: close, alt: "" }) })) })) })), _jsx(Dialog.Title, Object.assign({ asChild: true }, { children: _jsx("h4", Object.assign({ className: classNames(styles.title, {
                                                [styles.hidden]: hideTitleDescription,
                                            }) }, { children: title })) })), _jsx(Dialog.Description, Object.assign({ className: classNames(styles.description, {
                                            [styles.hidden]: hideTitleDescription,
                                        }) }, { children: description }))] })), _jsx("div", Object.assign({ className: styles.content }, { children: children }))] }))] })] })));
};
export default React.memo(Modal);
