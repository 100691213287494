import React, { PureComponent } from 'react';
import Stat from 'components/main/Stat';
import _t from 'counterpart';
import { NavLink } from 'react-router-dom';
import { LoadingDots } from 'components/global/LoadingDots';

class CoinsStat extends PureComponent {
	render() {
		const { amount } = this.props;
		return (
			<NavLink to="/coins">
				<Stat
					className="coins"
					prepend={<img src={require('../../icons/coins.svg?url')} height={40} width={30} />}
					label={_t.translate('main.coins')}
					data={amount !== null ? amount : <LoadingDots />}
				/>
			</NavLink>
		);
	}
}

export default CoinsStat;
