import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { formatCurrency } from 'utils/currency';
import { selectors, useAppSelector } from 'wintrado-api';
import classNames from 'classnames';
import styles from './PendingTotalProfitLoss.module.scss';
const PendingTotalProfitLoss = () => {
    // custom logic to prevent this component from re-rendering when totalPL is null
    // we need it to not update for CSS Transitions to work nicely
    // @ts-ignore
    const totalPL = useAppSelector(selectors.totalProfitLossSelector, (previous, next) => {
        return previous === null || next === null || previous === next;
    });
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const plPrefix = useMemo(() => {
        if (totalPL > 0) {
            return '+';
        }
        if (totalPL < 0) {
            return '-';
        }
        return '';
    }, [totalPL]);
    return (_jsx("span", Object.assign({ className: classNames(styles.container, {
            [styles.containerWin]: totalPL >= 0,
            [styles.containerLoss]: totalPL < 0,
        }) }, { children: `${plPrefix}${formatCurrency(Math.abs(totalPL), accountCurrency)}` })));
};
export default React.memo(PendingTotalProfitLoss);
