import { HIDE_DEPOSIT_WITHDRAWAL_MODAL, HIDE_INSTRUMENT_TREND_EXPLAINER_MODAL, HIDE_INSUFFICIENT_FUNDS_MODAL, HIDE_MARKET_CLOSED_ERROR_MODAL, SHOW_DEPOSIT_WITHDRAWAL_MODAL, SHOW_INSTRUMENT_TREND_EXPLAINER_MODAL, SHOW_INSUFFICIENT_FUNDS_MODAL, SHOW_MARKET_CLOSED_ERROR_MODAL, } from '../../actions';
export default function modalsReducer(state, action) {
    switch (action.type) {
        case SHOW_INSUFFICIENT_FUNDS_MODAL:
            const { requiredAmount } = action.payload;
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { insufficientFunds: {
                        shown: true,
                        requiredAmount,
                    } }) });
        case HIDE_INSUFFICIENT_FUNDS_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { insufficientFunds: {
                        shown: false,
                        requiredAmount: null,
                    } }) });
        case SHOW_INSTRUMENT_TREND_EXPLAINER_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { instrumentTrendExplainer: {
                        shown: true,
                    } }) });
        case HIDE_INSTRUMENT_TREND_EXPLAINER_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { instrumentTrendExplainer: {
                        shown: false,
                    } }) });
        case SHOW_DEPOSIT_WITHDRAWAL_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { depositWithdrawal: {
                        shown: true,
                    } }) });
        case HIDE_DEPOSIT_WITHDRAWAL_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { depositWithdrawal: {
                        shown: false,
                    } }) });
        case SHOW_MARKET_CLOSED_ERROR_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { marketClosedError: {
                        shown: true,
                    } }) });
        case HIDE_MARKET_CLOSED_ERROR_MODAL:
            return Object.assign(Object.assign({}, state), { modals: Object.assign(Object.assign({}, state.modals), { marketClosedError: {
                        shown: false,
                    } }) });
        default:
            return state;
    }
}
