import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getInitials } from '../../helpers';
import * as Avatar from '@radix-ui/react-avatar';
import styles from './InstrumentIcon.module.scss';
import classNames from 'classnames';
const InstrumentIcon = ({ symbol, imgUrl, className }) => {
    const nameInitials = () => {
        if (!symbol) {
            return null;
        }
        return getInitials(symbol);
    };
    return (_jsxs(Avatar.Root, Object.assign({ className: classNames(styles.avatarRoot, className) }, { children: [_jsx(Avatar.Image, { className: styles.avatarImage, src: imgUrl !== null && imgUrl !== void 0 ? imgUrl : undefined, alt: symbol !== null && symbol !== void 0 ? symbol : '' }), _jsx(Avatar.Fallback, Object.assign({ className: styles.avatarFallback, delayMs: imgUrl ? 600 : 0 }, { children: nameInitials() }))] })));
};
export default React.memo(InstrumentIcon);
