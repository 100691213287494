var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
const getFullConfigFromBrandConfig = (brandConfig, appEnv) => {
    const { cashier, oAuth } = brandConfig, rest = __rest(brandConfig, ["cashier", "oAuth"]);
    return Object.assign({ appEnv: appEnv, sentryUrl: 'https://1652d5561612450188961979cac9222b@sentry.kstg.io/12', sentryProjectId: 12, sentryOrganization: 'kstg', cashier: Object.assign({ depositURL: (user, lang, embedded = true) => `${brandConfig.cashier.baseURL}/payments/deposit/${user.wallet.id}?` +
                `token=${user.token || ''}&embedded=${embedded}&hideBack=${embedded}&lang=${lang}`, withdrawURL: (user, lang, embedded = true) => `${brandConfig.cashier.baseURL}/payments/withdrawal/${user.wallet.id}?` +
                `token=${user.token || ''}&embedded=${embedded}&hideBack=${embedded}&lang=${lang}`, profileURL: (token, lang) => `${brandConfig.cashier.baseURL}/profile?token=${token || ''}&hideBack=true&embedded=true&lang=${lang}`, uploadDocumentsURL: (token, lang) => `${brandConfig.cashier.baseURL}/documents/upload?token=${token || ''}&lang=${lang}&hideBack=true&embedded=true`, registrationLink: (ref, lang) => ref ? `${cashier.baseURL}/signup?ref=${ref}&lang=${lang}` : `${cashier.baseURL}/signup?lang=${lang}` }, cashier), crm: Object.assign({}, brandConfig.crm), oAuth: Object.assign({ authorizeURL: (lang, redirectUri, clientId, state, preselectWalletId) => `${brandConfig.cashier.baseURL}/api/oauth/authorize?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&state=${state}&scope=webtrader&lang=${lang}${preselectWalletId ? `&wallet_id=${preselectWalletId}` : ''}`, tokenURL: `${brandConfig.cashier.baseURL}/api/oauth/token`, userInfoURL: `${brandConfig.cashier.baseURL}/api/users/self`, logoutURL: `${brandConfig.cashier.baseURL}/api/oauth/logout` }, oAuth), features: {
            automaticallySetActivePosition: true,
        } }, rest);
};
export default getFullConfigFromBrandConfig;
