import { SHOW_PROFIT_LOSS_SELECTION, SHOW_DURATION_SELECTION, SHOW_INVEST_SELECTION, SELECT_TRADE_DURATION, SELECT_TRADE_PROFIT_LOSS, SELECT_TRADE_INVEST, HIDE_OVERLAY, SET_TRADE_PROFIT_LOSS, SAVE_INSTRUMENT_PROFIT_LOSS, SET_TRADE_DURATION, SET_RIGHT_SIDE_WIDGET, } from '../../actions';
export default function tradingScreenReducer(state, action) {
    switch (action.type) {
        case SHOW_DURATION_SELECTION: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { durationSelectionVisible: true, overlayVisible: true, durationOffsetLeft: action.payload.pX, durationOffsetBottom: action.payload.pY, durationWidth: action.payload.w }) });
        }
        case SHOW_PROFIT_LOSS_SELECTION: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { profitLossSelectionVisible: true, overlayVisible: true, profitLossOffsetLeft: action.payload.pX, profitLossOffsetBottom: action.payload.pY, profitLossWidth: action.payload.w }) });
        }
        case SHOW_INVEST_SELECTION: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { investSelectionVisible: true, overlayVisible: true, investOffsetLeft: action.payload.pX, investOffsetBottom: action.payload.pY, investWidth: action.payload.w }) });
        }
        case SELECT_TRADE_PROFIT_LOSS: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { profitLossSelected: action.payload.profitLoss, profitLossSelectionVisible: false, durationSelectionVisible: false, investSelectionVisible: true, overlayVisible: true, investOffsetLeft: state.tradingScreen.profitLossOffsetLeft, investOffsetBottom: state.tradingScreen.profitLossOffsetBottom, investWidth: state.tradingScreen.profitLossWidth }) });
        }
        case SET_TRADE_PROFIT_LOSS: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { profitLossSelected: action.payload.profitLoss }) });
        }
        case SELECT_TRADE_DURATION: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { closePositionAfterSeconds: action.payload.closePositionAfterSeconds, profitLossSelectionVisible: false, durationSelectionVisible: false, investSelectionVisible: false, overlayVisible: false }) });
        }
        case SET_TRADE_DURATION: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { closePositionAfterSeconds: action.payload.closePositionAfterSeconds }) });
        }
        case SELECT_TRADE_INVEST: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { investSelected: action.payload.invest, profitLossSelectionVisible: false, durationSelectionVisible: false, investSelectionVisible: false, overlayVisible: false }) });
        }
        case HIDE_OVERLAY: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { profitLossSelectionVisible: false, durationSelectionVisible: false, investSelectionVisible: false, overlayVisible: false }) });
        }
        case SAVE_INSTRUMENT_PROFIT_LOSS: {
            const { profitLoss, instrumentSymbol } = action.payload;
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { profitLossPerInstrument: Object.assign(Object.assign({}, state.tradingScreen.profitLossPerInstrument), { [instrumentSymbol]: profitLoss }) }) });
        }
        case SET_RIGHT_SIDE_WIDGET: {
            return Object.assign(Object.assign({}, state), { tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { rightSideWidget: action.payload.rightSideWidget }) });
        }
        default:
            return state;
    }
}
