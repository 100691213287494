import React, { PureComponent } from 'react';
import downIcon from '../../icons/down-icon.svg?url';
import _t from 'counterpart';
import { formatCurrency } from 'utils/currency';
import StatWithDropdown from 'components/main/StatWithDropdown';
import { LoadingDots } from 'components/global/LoadingDots';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import PendingTotalProfitLoss from 'components/PendingTotalProfitLoss/PendingTotalProfitLoss';

class BalanceStat extends PureComponent {
	render() {
		const { totalPL, balance, accountCurrency, equity, freeMargin, marginCall } = this.props;

		return (
			<StatWithDropdown
				className={classNames('balance', { 'stat__item--warning': marginCall })}
				prepend={<img src={downIcon} />}
				label={_t.translate('main.account_balance')}
				data={balance !== null ? formatCurrency(balance, accountCurrency) : <LoadingDots />}
				dataAppend={
					<CSSTransition in={totalPL !== null} classNames="slide-right" timeout={350} mountOnEnter unmountOnExit>
						<PendingTotalProfitLoss />
					</CSSTransition>
				}
				dropdownItems={[
					{
						label: _t.translate('main.equity'),
						data: equity !== null ? formatCurrency(equity, accountCurrency) : <LoadingDots />,
					},
					{
						label: _t.translate('main.free_margin'),
						data: freeMargin !== null ? formatCurrency(freeMargin, accountCurrency) : <LoadingDots />,
					},
				]}
			/>
		);
	}
}

export default BalanceStat;
