var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './IconButton.module.scss';
import ButtonBase from 'components/ButtonBase/ButtonBase';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
const IconButton = React.forwardRef((_a, ref) => {
    var { children, onClick, to, disabled = false, loading = false, flexibleIconSize = false, className, size = 'normal', isActive = false } = _a, props = __rest(_a, ["children", "onClick", "to", "disabled", "loading", "flexibleIconSize", "className", "size", "isActive"]);
    return (_jsx(ButtonBase, Object.assign({ ref: ref, to: to, onClick: onClick, className: classNames(styles.iconButton, className, {
            [styles.iconButtonSmall]: size === 'small',
            [styles.iconButtonActive]: isActive,
        }), activeClassName: styles.iconButtonActive, disabled: disabled, loading: loading }, props, { children: _jsx("div", Object.assign({ className: classNames(styles.iconWrapper, {
                [styles.iconWrapperFlexible]: flexibleIconSize,
            }) }, { children: loading ? _jsx(Loader, { inline: true }) : children })) })));
});
IconButton.displayName = "IconButton";
export default React.memo(IconButton);
