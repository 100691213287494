import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PureComponent } from "react";
import classNames from "classnames";
import { actions } from "wintrado-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const resolutions = [
    { name: '0M', value: '0', disabled: (chartType) => chartType === 'candle' },
    { name: '1M', value: '1' },
    { name: '5M', value: '5' },
    { name: '15M', value: '15' },
    { name: '30M', value: '30' },
    { name: '1H', value: '60' },
    { name: '4H', value: '240' },
    { name: '1D', value: '1D' },
];
class ChartControls extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            showControls: false,
        };
        this.onLabelClick = () => {
            this.setState((prevState) => ({
                showControls: !prevState.showControls,
            }));
        };
        this.onControlsBlur = () => {
            this.setState({
                showControls: false,
            });
        };
        this.onItemClick = (value) => {
            this.props.setChartResolution(value);
            this.setState({
                showControls: false,
            });
        };
    }
    render() {
        const { showControls } = this.state;
        const { chartResolution, chartType } = this.props;
        const selectedResolution = resolutions.find((item) => item.value === chartResolution);
        if (!selectedResolution) {
            throw Error(`Cannot find selected resolution: ${chartResolution}`);
        }
        return (_jsxs("div", Object.assign({ tabIndex: 0, className: "segmented-controls", onBlur: this.onControlsBlur }, { children: [_jsx("div", Object.assign({ className: "segmented-controls__active", onClick: this.onLabelClick }, { children: _jsx("span", { children: selectedResolution.name }) })), _jsx("ul", Object.assign({ className: classNames('segmented-controls__choices', { expanded: showControls }) }, { children: resolutions.map((item) => {
                        var _a;
                        if ((_a = item.disabled) === null || _a === void 0 ? void 0 : _a.call(item, chartType)) {
                            return null;
                        }
                        return (_jsx("li", Object.assign({ className: "choice", onClick: () => this.onItemClick(item.value) }, { children: _jsx("span", { children: item.name }) }), item.value));
                    }) }))] })));
    }
}
const mapStateToProps = (state) => {
    return {
        chartResolution: state.settings.chartResolution,
        chartType: state.settings.chartType,
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setChartResolution: actions.setChartResolution,
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ChartControls);
