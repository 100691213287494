import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import _t from 'counterpart';
import { selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import marginVideoWebm from '../../../videos/margin-video.webm';
import marginVideo from '../../../videos/margin-video.mp4';
import { setLowMarginModalShown } from 'wintrado-api/src/actions';
import styles from './ModalLowMargin.module.scss';
import Button from 'ui/components/Button/Button';
const ModalLowMargin = ({ onRequestClose }) => {
    const marginCall = useAppSelector(selectors.marginCallSelector);
    const lowMarginModalShown = useAppSelector((state) => state.events.lowMarginModalShown);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const showModal = () => {
        setIsOpen(true);
        dispatch(setLowMarginModalShown());
    };
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (marginCall && !lowMarginModalShown) {
            showModal();
        }
    }, [marginCall, lowMarginModalShown]);
    return (_jsx(Modal, Object.assign({ title: _t('modal.margin_low.title'), description: _t('modal.margin_low.description'), onRequestClose: onRequestClose !== null && onRequestClose !== void 0 ? onRequestClose : handleCloseModal, isOpen: isOpen, className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.holder }, { children: [_jsx("div", Object.assign({ className: styles.videoholder }, { children: _jsxs("video", Object.assign({ muted: true, loop: true, autoPlay: true, playsInline: true }, { children: [_jsx("source", { src: marginVideoWebm, type: "video/webm" }), _jsx("source", { src: marginVideo, type: "video/mp4" })] })) })), _jsx(Button, Object.assign({ variant: "primary", className: styles.button, onClick: handleCloseModal, flexible: true }, { children: _t.translate('main.i_got_it') }))] })) })));
};
export default React.memo(ModalLowMargin);
