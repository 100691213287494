import React, { PureComponent } from 'react';
import up from '../../icons/up-triangle.svg?url';
import down from '../../icons/down-triangle.svg?url';
import { ordinalSuffixFor } from '../../helpers';

class RankStat extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showChange: false,
		};
	}

	componentDidMount() {
		if (this.props.change) {
			this.showChange();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!!this.props.change && prevProps.rank !== this.props.rank) {
			this.showChange();
		}
	}

	showChange = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.setState({
			showChange: true,
		});
		this.timeout = setTimeout(() => {
			this.setState({
				showChange: false,
			});
		}, 5000);
	};

	navigateToLeaderboard = (e) => {
		e.preventDefault();
		this.props.navigateToLeaderboard();
	};

	render() {
		const { change, rank, nickname, small } = this.props;
		const icon = change === 'up' ? up : down;
		return (
			<div className={`rank-stat ${this.state.showChange ? change : ''}`} onClick={this.navigateToLeaderboard}>
				{!small && (
					<div className="nickname-wrapper">
						<span className="nickname">{nickname}</span>
					</div>
				)}
				<div className="stat-wrapper">
					{!!change && this.state.showChange && (
						<div className="arrow">
							<img src={icon} alt={change} />
						</div>
					)}
					<span className="rank">
						{rank}
						<span>{ordinalSuffixFor(rank)}</span>
					</span>
				</div>
			</div>
		);
	}
}

export default RankStat;
