import React from 'react';
import { connect } from 'react-redux';
import TrendChart from 'components/global/TrendChart';
import InstrumentIcon from "components/InstrumentIcon/InstrumentIcon"

class ExchangeComp extends React.Component {
	render() {
		if (!this.props.quotes) return null;
		const quote = this.props.quotes[this.props.quotes.length - 1] || { ask: 0, bid: 0, value: 0 };

		return (
			<li onClick={this.props.onClick}>
				<div className="instrument-icon-wrapper">
					<InstrumentIcon  symbol={this.props.instrument.symbol} imgUrl={this.props.instrument.iconUrl}/>
				</div>
				<div className="instrument">
					<span className="name">{this.props.instrument.symbol}</span>
					<span className="value">{quote.value.toFixed(this.props.instrument.decimals)}</span>
				</div>
				<div className="charticon">
					<TrendChart instrumentSymbol={this.props.instrument.symbol} />
				</div>
			</li>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		quotes: state.quotes[ownProps.instrument.symbol],
	};
};

export default connect(mapStateToProps)(ExchangeComp);
