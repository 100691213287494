import moment from 'moment';
export const correctedMomentLocales = {
    tz: 'sw',
    ke: 'sw',
    zh: 'zh-cn',
};
export const setMomentLocale = (language) => {
    if (Object.keys(correctedMomentLocales).includes(language)) {
        moment.locale(correctedMomentLocales[language]);
    }
    else {
        moment.locale(language);
    }
};
export const getNavigatorLanguage = () => navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
