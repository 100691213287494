import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import _t from 'counterpart';
import { PageLayout } from 'components/global/PageLayout';
import AnyLink from 'components/global/AnyLink';
import AllStrategies from 'components/copyTrading/AllStrategies';
import OwnStrategies from 'components/copyTrading/OwnStrategies';
import Button from 'components/Button/Button';
import FollowingStrategies from 'components/copyTrading/FollowingStrategies';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
const PageCopyTrading = () => {
    const renderCreateStrategyButton = useMemo(() => {
        return (_jsx(AnyLink, Object.assign({ to: "/copy-trading/strategies/create", className: "link link--unstyled" }, { children: _jsx(Button, Object.assign({ green: true, mobileFullWidth: true, inHeader: true }, { children: _t('copy_trading.create_strategy') })) })));
    }, []);
    return (_jsx(PageLayout, Object.assign({ className: "trading-strategies", title: _t('copy_trading.title'), actionItem: renderCreateStrategyButton }, { children: _jsxs(Tabs, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: _t.translate('copy_trading.all') }), _jsx(Tab, { children: _t.translate('copy_trading.following') }), _jsx(Tab, { children: _t.translate('copy_trading.my_strategies') })] }), _jsx(TabPanel, { children: _jsx(AllStrategies, {}) }), _jsx(TabPanel, { children: _jsx(FollowingStrategies, {}) }), _jsx(TabPanel, { children: _jsx(OwnStrategies, {}) })] }) })));
};
export default React.memo(PageCopyTrading);
