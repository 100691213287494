import React from 'react';
import MainInstrumentList from 'components/main/InstrumentList';
import MainPositionsList from 'components/main/PositionsList';
import Leaderboard from 'components/main/Leaderboard';

class RightWidgetWrapper extends React.PureComponent {
	render() {
		const { displayTwoRightWidgets, rightSideWidget, onBackToPositions } = this.props;
		return (
			<div className="width-lg-50 m-pos-1 positions-container" style={{ height: '100%' }}>
				<div className="row" style={{ height: '100%' }}>
					{rightSideWidget !== 'leaderboard' ? (
						<>
							{(displayTwoRightWidgets || rightSideWidget === 'markets') && (
								<div style={{ height: '100%' }} className={displayTwoRightWidgets ? 'width-lg-50' : 'width-lg-100'}>
									<MainInstrumentList key="mainInstrumentList" backToPositions={onBackToPositions} />
								</div>
							)}

							{(displayTwoRightWidgets || rightSideWidget === 'positions') && (
								<div style={{ height: '100%' }} className={displayTwoRightWidgets ? 'width-lg-50' : 'width-lg-100 m-p-0'}>
									<MainPositionsList />
								</div>
							)}
						</>
					) : (
						<Leaderboard />
					)}
				</div>
			</div>
		);
	}
}

export default RightWidgetWrapper;
