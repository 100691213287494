import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const AnimatedSwitch = (props) => {
	return (
		<TransitionGroup>
			<CSSTransition key={props.location.key} classNames="slide" timeout={400}>
				<Switch location={props.location}>{props.children}</Switch>
			</CSSTransition>
		</TransitionGroup>
	);
};

export default withRouter(AnimatedSwitch);
