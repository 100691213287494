import { jsx as _jsx } from "react/jsx-runtime";
import styles from './Backdrop.module.scss';
import classNames from 'classnames';
const Backdrop = ({ onClick, desktopTransparent, mobileTransparent }) => {
    return (_jsx("div", { className: classNames(styles.backdrop, {
            [styles.backdropDesktopTransparent]: desktopTransparent,
            [styles.backdropMobileTransparent]: mobileTransparent,
        }), onClick: onClick }));
};
export default Backdrop;
