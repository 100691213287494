import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useQuery } from 'react-query';
import { crmService } from 'wintrado-api/src';
import TradingStrategyRow from 'components/copyTrading/TradingStrategyRow';
import InlineLoader from 'components/InlineLoader';
const OwnStrategies = () => {
    const { data, error, isLoading } = useQuery('own-copytrading-strategies', () => crmService.getOwnCopyTradingStrategies(200, 0));
    if (error) {
        return _jsx("span", { children: error.toString() });
    }
    if (isLoading) {
        return _jsx(InlineLoader, {});
    }
    return (_jsx(_Fragment, { children: data.strategies.map((strategy) => (_jsx(TradingStrategyRow, { strategy: strategy }, strategy.id))) }));
};
export default React.memo(OwnStrategies);
