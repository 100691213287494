import React, { PureComponent } from 'react';
import { millisecondsToHoursDaysMinutesSeconds } from '../../helpers';
import classNames from 'classnames';

const _t = require('counterpart');

class CompetitionCountdown extends PureComponent {
	competitionEnded = false;

	state = {
		currentTime: new Date(),
	};

	componentDidMount() {
		const { startDate, endDate } = this.props;
		if (!!startDate && !!endDate) {
			throw Error('Cannot accept startTime and endTime');
		}
		this.interval = setInterval(() => this.setState({ currentTime: new Date() }), 1000);
	}

	componentWillUnmount() {
		this.clearRefreshInterval();
	}

	clearRefreshInterval = () => {
		if (this.interval) {
			clearInterval(this.interval);
		}
	};

	render() {
		const { currentTime } = this.state;
		const { startDate, endDate, onCompetitionEnded, inline } = this.props;

		const isCompetitionActive = startDate === undefined;

		let diffMillis = isCompetitionActive ? endDate - currentTime : startDate - currentTime;

		if (isCompetitionActive && diffMillis < 0) {
			diffMillis = 0;

			if (!this.competitionEnded) {
				onCompetitionEnded();
				this.competitionEnded = true;
			}
		}

		const { days, hours, minutes, seconds } = millisecondsToHoursDaysMinutesSeconds(diffMillis);

		return (
			<div className={classNames('competition-countdown', { 'competition-countdown--inline': inline })}>
				<span className="label">
					{isCompetitionActive
						? _t.translate('leaderboard.competition_ends')
						: _t.translate('leaderboard.competition_starts')}
				</span>
				<div className="countdown">
					<span className="days">{days}</span>
					<span className="hours">{hours}</span>
					<span className="minutes">{minutes}</span>
					<span className="seconds">{seconds}</span>
				</div>
				<div className="countdown-labels">
					<span>{_t.translate('leaderboard.days')}</span>
					<span>{_t.translate('leaderboard.hours')}</span>
					<span>{_t.translate('leaderboard.minutes')}</span>
					<span>{_t.translate('leaderboard.seconds')}</span>
				</div>
			</div>
		);
	}
}

export default CompetitionCountdown;
