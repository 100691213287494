import React from 'react';

const _t = require('counterpart');

export const LiveAccountOnlyMessage = () => {
	return (
		<div id="live-account-only">
			<span className="live-account-only-label">{_t.translate('main.live_account_only')}</span>
		</div>
	);
};
