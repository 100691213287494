import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ProgressBar from 'components/ProgressBar';
import { useAppSelector } from 'wintrado-api';
const SoundbankLoader = () => {
    const soundbankLoading = useAppSelector((state) => state.soundbankLoading);
    const loadingPercentage = useAppSelector((state) => state.soundbankLoadingPercentage);
    if (!soundbankLoading) {
        return null;
    }
    return (_jsx("div", Object.assign({ id: "soundbank-loader" }, { children: _jsx("div", Object.assign({ className: "progressbar-container" }, { children: _jsx(ProgressBar, { percent: loadingPercentage, value: "Loading sounds..." }) })) })));
};
export default React.memo(SoundbankLoader);
