import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { DISPLAY_ERROR, errorClear, errorShow } from '../actions';
function* handleDisplayError(action) {
    const { type, message, duration, extra } = action.payload;
    yield put(errorShow(type, message, extra));
    yield delay(duration);
    yield put(errorClear());
}
function* errorSaga() {
    yield all([
        takeLatest(DISPLAY_ERROR, handleDisplayError),
    ]);
}
export default errorSaga;
