import Color from 'color';

export const backgrounds = {
	bright: '#fff',
	darker: Color('#252525').darken(0.3).hex().toString(),
	default: '#252525',
	highlighted: Color('#252525').lighten(0.5).hex().toString(),
	lighter: Color('#252525').lighten(0.4).hex().toString(),
	lighterer: Color('#252525').lighten(0.7).hex().toString(),
};

export const trading = {
  buy: '#55D636',
  sell: '#CD0000',
	positive: '#98B946',
	negative: '#E35953',
	normal: '#12AAEB',
	positiveGrad: 'linear-gradient(103.09deg, #70C157 21.7%, #3E8C3B 59.43%), #3E8C3B',
	negativeGrad: 'linear-gradient(103.09deg, #F60057 21.7%, #BB173E 59.43%), #BB173E',
};

export const buttons = {
	primary: '#12AAEB',
	secondary: '#F60057',
	positive: '#3E8C3B',
	blue: '#12AAEB',
};

export const texts = {
	primary: '#fff',
	light: '#bbb',
	lighter: '#888',
	grey: '#cecece',
	black: '#000',
};

export const progressbar = {
	border: '#128fcb',
	colorStop1: '#12AAEB',
	colorStop2: '#12bfea',
	colorStop3: '#12cdea',
};

export const borderColor = '#383D44';
