import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
const StatWithDropdown = ({ className, prepend = null, label, data, dataAppend = null, onDataClick, dropdownItems, }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const hideDropdown = useCallback(() => {
        setDropdownVisible(false);
    }, [setDropdownVisible]);
    const showDropdown = useCallback(() => {
        setDropdownVisible(true);
    }, [setDropdownVisible]);
    const dataSpan = _jsx("span", Object.assign({ className: "data" }, { children: data }));
    const dataElement = onDataClick ? _jsx("a", Object.assign({ onClick: onDataClick }, { children: dataSpan })) : dataSpan;
    return (_jsxs("div", Object.assign({ className: classNames('stat__item', 'stat__item--with-dropdown', className, {
            expanded: isDropdownVisible,
        }), onMouseEnter: showDropdown, onMouseLeave: hideDropdown }, { children: [prepend !== null && _jsx("div", Object.assign({ className: "prepend" }, { children: prepend })), _jsxs("div", Object.assign({ className: "labelDataWrapper" }, { children: [_jsx("span", Object.assign({ className: "label" }, { children: label })), _jsxs("div", Object.assign({ className: "dataWrapper" }, { children: [dataElement, dataAppend !== null && _jsx("div", Object.assign({ className: "append" }, { children: dataAppend }))] }))] })), _jsx(CSSTransition, Object.assign({ in: isDropdownVisible, timeout: 350, classNames: "balance-dropdown" }, { children: _jsx("div", Object.assign({ className: "stat__dropdown-list" }, { children: dropdownItems.map((item) => (_jsxs("div", Object.assign({ className: "stat__item stat__item--in-dropdown" }, { children: [_jsx("div", { className: "prepend" }), _jsxs("div", Object.assign({ className: "labelDataWrapper" }, { children: [_jsx("div", Object.assign({ className: "labelTinyAppendWrapper" }, { children: _jsx("span", Object.assign({ className: "label" }, { children: item.label })) })), _jsx("div", Object.assign({ className: "dataWrapper" }, { children: _jsx("span", Object.assign({ className: "data" }, { children: item.data })) }))] }))] }), item.label))) })) }))] })));
};
export default StatWithDropdown;
