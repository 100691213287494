import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { isValidElement } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import styles from './Tooltip.module.scss';
const Tooltip = ({ content, children, className = '', side = 'top', align = 'center', skipDelayDuration = 300, delayDuration = 200, isOpen, controlled = false, }) => {
    const preventTriggerDefaultBehaviour = (event) => {
        if (!controlled)
            return;
        event.preventDefault();
        event.stopPropagation();
    };
    return (_jsx(RadixTooltip.Provider, Object.assign({ delayDuration: delayDuration, skipDelayDuration: skipDelayDuration }, { children: _jsxs(RadixTooltip.Root, Object.assign({ open: controlled ? isOpen : undefined }, { children: [_jsx(RadixTooltip.Trigger, Object.assign({ asChild: true }, { children: children })), _jsx(RadixTooltip.Portal, { children: _jsxs(RadixTooltip.Content, Object.assign({ align: align, side: side, className: `${styles.radixTooltipContent} ${className}`, sideOffset: 5, onMouseDown: preventTriggerDefaultBehaviour }, { children: [isValidElement(content) && content, typeof content === 'string' && (_jsx("p", { className: styles.paragraph, dangerouslySetInnerHTML: { __html: content } })), _jsx(RadixTooltip.Arrow, { id: "tooltip_arrow", className: styles.radixTooltipArrow })] })) })] })) })));
};
export default React.memo(Tooltip);
