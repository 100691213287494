import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styles from './RangePicker.module.scss';
import classNames from 'classnames';
const RangePicker = ({ step, min, max, values, onChange }) => {
    return (_jsx("div", Object.assign({ className: styles.container }, { children: _jsx(Range, { values: values, step: step, min: min, max: max, onChange: (values) => onChange(values), renderTrack: ({ props, children }) => (_jsx("div", Object.assign({ onMouseDown: (e) => {
                    e.stopPropagation();
                    props.onMouseDown(e);
                }, onTouchStart: (e) => {
                    e.stopPropagation();
                    props.onTouchStart(e);
                }, className: styles.trackContainer, style: props.style }, { children: _jsx("div", Object.assign({ ref: props.ref, className: styles.track, style: {
                        background: getTrackBackground({
                            values,
                            colors: ['rgba(255, 255, 255, 0.35)', '#4bb84e', 'rgba(255, 255, 255, 0.35)'],
                            min,
                            max,
                        }),
                    } }, { children: children })) }))), renderThumb: ({ index, props, isDragged }) => (_jsx("div", Object.assign({}, props, { className: classNames(styles.thumb, { [styles.thumbDragged]: isDragged }) }))) }) })));
};
export default React.memo(RangePicker);
