var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from 'components/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import { capitalize, extractErrorMessage, urlBase64ToUint8Array } from '../../../helpers';
import _t from 'counterpart';
import { useMutation } from 'react-query';
import { pushSubscribe, getVapidKey, getSubscription, deleteSubscription } from 'wintrado-api/src/services/crmService';
import PopupAlertManager from 'components/PopupAlert/PopupAlertManager';
const PushToggle = () => {
    const [pushEnabled, setPushEnabled] = useState(false);
    const [loading, setIsLoading] = useState();
    const showErrorAlert = useCallback((msg) => {
        PopupAlertManager.showAlert({
            type: 'error',
            title: _t.translate('error.oops'),
            subtitle: msg,
            buttonLabel: _t.translate('main.go_back'),
        });
    }, []);
    const pushSubcribeMutation = useMutation({
        mutationFn: (subsciption) => pushSubscribe(subsciption, 'trading'),
        onSuccess: () => {
            checkPushEnabledAndSet();
            setIsLoading(false);
            setPushEnabled(true);
        },
    });
    const checkPushEnabledAndSet = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                const registration = yield navigator.serviceWorker.register('/push-worker.js');
                yield navigator.serviceWorker.ready;
                const subscription = yield registration.pushManager.getSubscription();
                if (!subscription) {
                    setPushEnabled(false);
                    return;
                }
                const dbSubscription = yield getSubscription(subscription.endpoint, 'trading');
                if (!dbSubscription) {
                    setPushEnabled(false);
                    return;
                }
                setPushEnabled(true);
            }
            catch (e) {
                const er = extractErrorMessage(e);
                showErrorAlert(er);
            }
        }
    });
    const registerPush = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                setIsLoading(true);
                const registration = yield navigator.serviceWorker.register('/push-worker.js');
                yield navigator.serviceWorker.ready;
                const subscription = yield registration.pushManager.getSubscription();
                if (subscription) {
                    yield deleteSubscription(subscription.endpoint, 'trading');
                    yield subscription.unsubscribe();
                }
                const vapidKey = yield getVapidKey();
                const convertedVapidKey = urlBase64ToUint8Array(vapidKey);
                const pushSubscription = yield registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey,
                });
                pushSubcribeMutation.mutate(pushSubscription);
            }
            catch (e) {
                const er = extractErrorMessage(e);
                showErrorAlert(er);
                setIsLoading(false);
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const disablePush = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                setIsLoading(true);
                const registration = yield navigator.serviceWorker.register('/push-worker.js');
                yield navigator.serviceWorker.ready;
                const subscription = yield registration.pushManager.getSubscription();
                if (subscription) {
                    yield deleteSubscription(subscription.endpoint, 'trading');
                    yield subscription.unsubscribe();
                }
                checkPushEnabledAndSet();
            }
            catch (e) {
                const er = extractErrorMessage(e);
                showErrorAlert(er);
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    useEffect(() => {
        checkPushEnabledAndSet();
    }, []);
    const togglePush = () => {
        if (!pushEnabled) {
            registerPush();
        }
        else {
            disablePush();
        }
    };
    return (_jsx(Button, Object.assign({ gray: true, fullWidth: true, withImage: true, loading: loading, faded: !pushEnabled, textStart: true, onClick: togglePush }, { children: _jsx("span", { children: capitalize(_t(pushEnabled ? 'page.on' : 'page.off')) }) })));
};
export default PushToggle;
