import React, { PureComponent } from 'react';

class Stat extends PureComponent {
	render() {
		const { className = '', prepend = null, label, data, dataAppend = null, onDataClick } = this.props;
		const dataSpan = <span className="data">{data}</span>;
		const dataElement = onDataClick ? <a onClick={onDataClick}>{dataSpan}</a> : dataSpan;

		return (
			<div className={`stat__item ${className}`}>
				{prepend !== null && <div className="prepend">{prepend}</div>}
				<div className="labelDataWrapper">
					<div className="labelTinyAppendWrapper">
						<span className="label">{label}</span>
						{dataAppend !== null && <div className="tinyAppend">{dataAppend}</div>}
					</div>
					<div className="dataWrapper">
						{dataElement}
						{dataAppend !== null && <div className="append">{dataAppend}</div>}
					</div>
				</div>
			</div>
		);
	}
}

export default Stat;
