import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import styles from './RangeFiltersDropdown.module.scss';
import RangeInputWithLabel from 'components/RangeInputWithLabel/RangeInputWithLabel';
import _t from 'counterpart';
import Button from 'components/Button/Button';
import Spacer from 'components/Spacer/Spacer';
import Backdrop from 'components/Backdrop/Backdrop';
const RangeFiltersDropdown = React.forwardRef(({ onClose, onApply, currentFilters, filtersScope }, ref) => {
    const [minInvestmentAmount, setMinInvestmentAmount] = useState(null);
    const [roiSinceStart, setRoiSinceStart] = useState(null);
    const [followers, setFollowers] = useState(null);
    const handleApplyFilters = useCallback(() => {
        onApply({
            minInvestmentAmount,
            roiSinceStart,
            followers,
        });
    }, [followers, minInvestmentAmount, roiSinceStart, onApply]);
    return (_jsxs(_Fragment, { children: [_jsx(Backdrop, { onClick: onClose, desktopTransparent: true }), _jsxs("div", Object.assign({ ref: ref, className: styles.dropdown }, { children: [_jsx("h5", Object.assign({ className: styles.title }, { children: _t('copy_trading.filters_label') })), _jsx(RangeInputWithLabel, { label: _t('copy_trading.filters.min_investment_amount'), step: 1, min: filtersScope.minInvestmentAmount.min, max: filtersScope.minInvestmentAmount.max, onChange: setMinInvestmentAmount, initialValues: currentFilters.minInvestmentAmount }), _jsx(RangeInputWithLabel, { label: _t('copy_trading.filters.performance_since_start'), step: 1, min: filtersScope.roiSinceStart.min, max: filtersScope.roiSinceStart.max, onChange: setRoiSinceStart, initialValues: currentFilters.roiSinceStart }), _jsx(RangeInputWithLabel, { label: _t('copy_trading.filters.number_of_followers'), step: 1, min: filtersScope.followers.min, max: filtersScope.followers.max, onChange: setFollowers, initialValues: currentFilters.followers }), _jsxs("div", Object.assign({ className: styles.buttonsContainer }, { children: [_jsx(Button, Object.assign({ onClick: onClose, fullWidth: true, gray: true }, { children: _t('main.cancel') })), _jsx(Spacer, { x: true }), _jsx(Button, Object.assign({ onClick: handleApplyFilters, fullWidth: true, blue: true }, { children: _t('main.apply') }))] }))] }))] }));
});
export default React.memo(RangeFiltersDropdown);
