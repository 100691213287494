import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from 'ui/components/Button/Button';
import { useAppSelector, useAppDispatch } from 'wintrado-api';
import _t from 'counterpart';
import { hideInstrumentTrendExplainerModal } from 'wintrado-api/src/actions';
import buyCircleIcon from '../../../icons/main/buy-circle.svg?url';
import sellCircleIcon from '../../../icons/main/sell-circle.svg?url';
import styles from './ModalInstrumentTrendExplainer.module.scss';
import Modal from '../Modal';
import ModalFooterButtons from 'components/ModalFooterButtons';
const ModalInstrumentTrendExplainer = () => {
    const isModalOpen = useAppSelector((state) => state.modals.instrumentTrendExplainer).shown;
    const dispatch = useAppDispatch();
    const handleCloseModal = () => {
        dispatch(hideInstrumentTrendExplainerModal());
    };
    return (_jsx(Modal, Object.assign({ title: _t.translate('modal.instrument_trend_explainer.title'), onRequestClose: handleCloseModal, isOpen: isModalOpen, className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.instrumentTrendExplainer }, { children: [_jsx("img", { src: sellCircleIcon, className: styles.icon, alt: "sell-icon" }), _jsx("h5", Object.assign({ className: styles.subtitle }, { children: _t.translate('modal.instrument_trend_explainer.sell_positions') })), _jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.instrument_trend_explainer.sell_description') })), _jsx("img", { src: buyCircleIcon, className: styles.icon, alt: "buy-icon" }), _jsx("h5", Object.assign({ className: styles.subtitle }, { children: _t.translate('modal.instrument_trend_explainer.buy_positions') })), _jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.instrument_trend_explainer.buy_description') })), _jsx(ModalFooterButtons, { children: _jsx(Button, Object.assign({ variant: "primary", onClick: handleCloseModal, className: styles.button }, { children: _t.translate('modal.close') })) })] })) })));
};
export default React.memo(ModalInstrumentTrendExplainer);
