import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import config from 'config';
import { isIos } from '../../../helpers';
import AnyLink from 'components/AnyLink/AnyLink';
import _t from 'counterpart';
import Button from 'ui/components/Button/Button';
import Modal from '../Modal';
import styles from './ModalDepositWithdrawal.module.scss';
import { hideDepositWithdrawalModal } from 'wintrado-api/src/actions';
import { useAppDispatch, useAppSelector } from 'wintrado-api';
const ModalDepositWithdrawal = () => {
    const user = useAppSelector((state) => state.user);
    const isModalOpen = useAppSelector((state) => state.modals.depositWithdrawal).shown;
    const dispatch = useAppDispatch();
    const { depositURL, withdrawURL, useIframe } = config.cashier;
    const locale = _t.getLocale();
    const depositLink = isIos() || !useIframe ? depositURL === null || depositURL === void 0 ? void 0 : depositURL(user, locale, false) : '/deposit';
    const withdrawLink = isIos() || !useIframe ? withdrawURL === null || withdrawURL === void 0 ? void 0 : withdrawURL(user, locale, false) : '/withdraw';
    const handleCloseModal = () => {
        dispatch(hideDepositWithdrawalModal());
    };
    return (_jsx(Modal, Object.assign({ title: _t('modal.deposit_withdraw_label'), onRequestClose: handleCloseModal, isOpen: isModalOpen, className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.holder }, { children: [_jsx("div", Object.assign({ className: styles.illustration }, { children: _jsx("img", { src: require('../../../images/modals/withdraw-deposit.svg?url'), alt: "withdraw-deposit-icon" }) })), _jsx("div", Object.assign({ className: styles.actions }, { children: _jsxs("div", Object.assign({ className: styles.holder }, { children: [depositLink != null && (_jsx(AnyLink, Object.assign({ to: depositLink, onClick: handleCloseModal, "aria-label": _t.translate('page.deposit'), className: "left" }, { children: _jsx(Button, Object.assign({ variant: "primary", className: styles.button }, { children: _t.translate('page.deposit') })) }))), withdrawLink != null && (_jsx(AnyLink, Object.assign({ to: withdrawLink, onClick: handleCloseModal, "aria-label": _t.translate('page.withdraw'), className: "right" }, { children: _jsx(Button, Object.assign({ variant: "secondary", className: styles.button }, { children: _t.translate('page.withdraw') })) })))] })) }))] })) })));
};
export default React.memo(ModalDepositWithdrawal);
