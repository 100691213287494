import { PositionStatus } from './protos/positionStatus';
import { Quote, QuotesBatch } from './protos/quote';
import { AccountBalance } from './protos/accountBalance';
export const ProtoIdentifiers = {
    1: {
        messageType: 'Quote',
        proto: Quote,
    },
    2: {
        messageType: 'PositionStatus',
        proto: PositionStatus,
    },
    3: {
        messageType: 'AccountBalance',
        proto: AccountBalance,
    },
    4: {
        messageType: 'QuotesBatch',
        proto: QuotesBatch,
    },
};
