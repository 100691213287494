import React from 'react';
import trophyAnimationData from '../lotties/trophy';
import Lottie from 'lottie-react';
import { connect } from 'react-redux';

class TrophyAnimation extends React.PureComponent {
	lottieRef = React.createRef();

	state = {
		adjustedAnimationData: trophyAnimationData,
		shouldPlay: false,
	};

	componentDidMount() {
		this.playIfNeeded();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.leaderboardUser !== this.props.leaderboardUser) {
			this.playIfNeeded();
		}

		if (prevState.shouldPlay !== this.state.shouldPlay && this.state.shouldPlay) {
			if (this.lottieRef.current) {
				this.lottieRef.current.play();
			}
		}
	}

	playIfNeeded = () => {
		const { leaderboardUser } = this.props;
		if (
			Boolean(leaderboardUser) &&
			leaderboardUser.rank <= 10 &&
			Boolean(leaderboardUser.change) &&
			leaderboardUser.change === 'up'
		) {
			this.adjustAnimationDataForRank(leaderboardUser.rank);
			this.playAnimation();
		}
	};

	playAnimation = () => {
		this.setState({
			shouldPlay: true,
		});
		setTimeout(() => {
			this.setState({
				shouldPlay: false,
			});
		}, 2000);
	};

	adjustAnimationDataForRank = (rank) => {
		trophyAnimationData.layers[0].t.d.k[0].s.t = rank.toString();
		const adjustedAnimationData = { ...trophyAnimationData };

		this.setState({
			adjustedAnimationData,
		});
	};

	render() {
		const { adjustedAnimationData, shouldPlay } = this.state;
		const { leaderboardUser } = this.props;

		if (!leaderboardUser || !shouldPlay) {
			return null;
		}
		return (
			<Lottie
				className="trophy-animation"
				lottieRef={this.lottieRef}
				animationData={adjustedAnimationData}
				autoplay
				loop
				width={400}
				height={400}
				rendererSettings={{
					preserveAspectRatio: 'xMidYMid slice',
				}}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		leaderboardUser: state.leaderboard.user,
	};
};

export default connect(mapStateToProps, null)(TrophyAnimation);
