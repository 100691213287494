import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { PageLayout } from "components/global/PageLayout";
import _t from "counterpart";
import Input from "components/form/Input";
import { useForm } from "react-hook-form";
import TextArea from "components/form/TextArea";
import { useMutation, useQueryClient } from "react-query";
import { crmService } from "wintrado-api/src";
import Notice from "components/global/Notice";
import Loader from "components/Loader/Loader";
import Button from "components/Button/Button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
const schema = z
    .object({
    name: z.string().nonempty(),
    minimumInvest: z.coerce.number().positive(),
    performanceFee: z.coerce.number().min(0).max(100),
    /* managementFee: z.coerce.number().min(0).max(100),
    volumeFee: z.coerce.number().positive(), */
    description: z.string().nonempty(),
    inviteOnly: z.boolean(),
})
    .required();
const PageCreateTradingStrategy = () => {
    const [createdStrategyName, setCreatedStrategyName] = useState(null);
    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: zodResolver(schema),
    });
    const queryClient = useQueryClient();
    const createStrategyMutation = useMutation(({ name, minimumInvest, performanceFee, description, inviteOnly }) => crmService.postCopyTradingStrategy(name, performanceFee, description, inviteOnly, minimumInvest), {
        onSuccess: (data, variables) => {
            setCreatedStrategyName(variables.name);
            queryClient.invalidateQueries('copytrading-strategies');
            queryClient.invalidateQueries('own-copytrading-strategies');
        },
    });
    const onSubmit = (data) => {
        createStrategyMutation.mutate(data);
    };
    const renderContent = useMemo(() => {
        var _a, _b, _c, _d, _e;
        if (createStrategyMutation.isSuccess) {
            return (_jsx(Notice, { type: "success", title: _t('copy_trading.create_strategy_success_title'), subtitle: _t('copy_trading.create_strategy_success_subtitle', { strategyName: createdStrategyName }), buttonLabel: _t('copy_trading.go_to_strategies_list'), buttonNavigateTo: "/copy-trading/strategies" }));
        }
        return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit), className: "d-flex flex-column" }, { children: [_jsx(Input, Object.assign({}, register('name'), { label: _t('copy_trading.strategy_name'), placeholder: _t('copy_trading.enter_strategy_name'), error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })), _jsxs("div", Object.assign({ className: "d-flex my-4" }, { children: [_jsx(Input, Object.assign({}, register('minimumInvest'), { label: _t('copy_trading.min_investment_label'), placeholder: "10", error: (_b = errors.minimumInvest) === null || _b === void 0 ? void 0 : _b.message })), _jsx(Input, Object.assign({}, register('performanceFee'), { label: _t('copy_trading.performance_fee_label'), placeholder: "5", error: (_c = errors.performanceFee) === null || _c === void 0 ? void 0 : _c.message }))] })), _jsx(TextArea, Object.assign({}, register('description'), { label: _t('main.description'), placeholder: _t('copy_trading.enter_description'), error: (_d = errors.description) === null || _d === void 0 ? void 0 : _d.message })), _jsx(Input, Object.assign({}, register('inviteOnly'), { type: "checkbox", label: _t('copy_trading.invite_only'), error: (_e = errors.inviteOnly) === null || _e === void 0 ? void 0 : _e.message })), _jsx(Button, Object.assign({ type: "submit", disabled: createStrategyMutation.isLoading, className: "mt-3", green: true }, { children: _t('copy_trading.publish_strategy') })), createStrategyMutation.isLoading && _jsx(Loader, { semiTransparentBackground: true })] })));
    }, [
        createStrategyMutation.isSuccess,
        createStrategyMutation.isLoading,
        createdStrategyName,
        errors,
        handleSubmit,
        onSubmit,
        register,
    ]);
    return (_jsx(PageLayout, Object.assign({ title: _t('copy_trading.create_strategy'), showDesktopBackButton: true }, { children: _jsx("div", Object.assign({ className: "create-trading-strategy" }, { children: renderContent })) })));
};
export default React.memo(PageCreateTradingStrategy);
