import React from 'react';
import _t from 'counterpart';

const NoInstrumentsFound = () => {
  return  (
    <div className="no-instruments-found">
      <div className="no-instruments-found__wrapper">
        <img
          src={require('../icons/alert.svg?url')}
          className="market-closed-notice__image"
          alt="market-closed"
          color="blue"
        />
        <h1 className="no-instruments-found__white-title">{_t('main.no-instruments')}</h1>
        <span className="no-instruments-found__info">
						{_t('main.no-instruments-configured')}
					</span>
      </div>
    </div>
  );
}

export default NoInstrumentsFound;