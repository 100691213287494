import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import React, { useCallback } from 'react';
const COLORS = [
    '#0074D9',
    '#FF4136',
    '#2ECC40',
    '#FF851B',
    '#7FDBFF',
    '#B10DC9',
    '#FFDC00',
    '#001f3f',
    '#39CCCC',
    '#01FF70',
    '#85144b',
    '#F012BE',
    '#3D9970',
    '#111111',
    '#AAAAAA',
];
const PieChartWithLegend = ({ data }) => {
    const legendFormatter = useCallback((value, entry, index) => {
        return _jsx("span", { children: `${entry.payload.value}% ${value}` });
    }, []);
    return (_jsx(ResponsiveContainer, { children: _jsxs(PieChart
        /* @ts-ignore */
        , Object.assign({ 
            /* @ts-ignore */
            cursor: "pointer" }, { children: [_jsx(Pie, Object.assign({ nameKey: "category", dataKey: "percentage", data: data, innerRadius: "70%", outerRadius: "100%", paddingAngle: 10 }, { children: data.map((entry, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    _jsx(Cell, { fill: COLORS[index % COLORS.length], strokeWidth: 0 }, `cell-${index}`))) })), _jsx(Legend, { cursor: "pointer", layout: "vertical", verticalAlign: "middle", align: "right", iconType: "circle", formatter: legendFormatter, wrapperStyle: { cursor: 'pointer' } })] })) }));
};
export default React.memo(PieChartWithLegend);
