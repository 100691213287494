import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { selectors, useAppSelector } from 'wintrado-api';
import useResizeObserver from 'use-resize-observer';
import { activePositionToPriceLineConfig, addAreaSeriesToChart, addCandlestickSeriesToChart, initializeChart, lastValueToPriceLineConfig, } from 'utils/chartHelpers';
import Loader from 'components/Loader/Loader';
const Chart = () => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRef = useRef(null);
    const activePositionLineRef = useRef(null);
    const lastValueLineRef = useRef(null);
    const fitContentCalledRef = useRef(false);
    const chartType = useAppSelector((state) => state.settings.chartType);
    const chartResolution = useAppSelector((state) => state.settings.chartResolution);
    const candles = useAppSelector((state) => state.candles.data);
    const historyLoading = useAppSelector((state) => state.candles.loading);
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const activePosition = useAppSelector(selectors.activePositionSelector);
    const initChart = useCallback((decimals = 5) => {
        if (chartContainerRef.current) {
            chartRef.current = initializeChart(chartContainerRef.current);
            if (chartType === 'candle') {
                seriesRef.current = addCandlestickSeriesToChart(chartRef.current, decimals);
            }
            else {
                seriesRef.current = addAreaSeriesToChart(chartRef.current, decimals);
            }
        }
    }, [chartType]);
    const transformAndSetData = useCallback(() => {
        var _a, _b, _c;
        if (historyLoading) {
            (_a = seriesRef.current) === null || _a === void 0 ? void 0 : _a.setData([]);
            return;
        }
        let data = [];
        if (chartType === 'candle') {
            data = candles.reduce((acc, candle) => {
                if (candle.open === null && candle.close === null && candle.high === null && candle.low === null) {
                    return acc;
                }
                acc.push({
                    open: Number(candle.open),
                    close: Number(candle.close),
                    high: Number(candle.high),
                    low: Number(candle.low),
                    time: (candle.start / 1000),
                });
                return acc;
            }, []);
        }
        else {
            data = candles.reduce((acc, candle) => {
                if (candle.open === null && candle.close === null && candle.high === null && candle.low === null) {
                    return acc;
                }
                acc.push({
                    value: Number(candle.close),
                    time: (candle.start / 1000),
                });
                return acc;
            }, []);
        }
        (_b = seriesRef.current) === null || _b === void 0 ? void 0 : _b.setData(data);
        if (!fitContentCalledRef.current) {
            const noOfBars = data.length;
            (_c = chartRef.current) === null || _c === void 0 ? void 0 : _c.timeScale().setVisibleLogicalRange({ from: noOfBars - 60, to: noOfBars });
            fitContentCalledRef.current = true;
        }
    }, [candles, chartType, historyLoading]);
    const handleActivePositionMarker = useCallback(() => {
        var _a, _b;
        if (activePositionLineRef.current && seriesRef.current) {
            seriesRef.current.removePriceLine(activePositionLineRef.current);
            (_a = seriesRef.current) === null || _a === void 0 ? void 0 : _a.setMarkers([]);
            activePositionLineRef.current = null;
        }
        if (!(activePosition === null || activePosition === void 0 ? void 0 : activePosition.openPrice) || !(activePosition === null || activePosition === void 0 ? void 0 : activePosition.side)) {
            return;
        }
        activePositionLineRef.current = (_b = seriesRef.current) === null || _b === void 0 ? void 0 : _b.createPriceLine(activePositionToPriceLineConfig(activePosition.side, activePosition.openPrice));
    }, [activePosition === null || activePosition === void 0 ? void 0 : activePosition.openPrice, activePosition === null || activePosition === void 0 ? void 0 : activePosition.side]);
    useResizeObserver({
        ref: chartContainerRef,
        onResize: ({ width, height }) => {
            var _a;
            (_a = chartRef.current) === null || _a === void 0 ? void 0 : _a.applyOptions({
                width,
                height,
            });
        },
    });
    useEffect(() => {
        var _a, _b, _c, _d;
        const currentLastValueLinePrice = (_a = lastValueLineRef.current) === null || _a === void 0 ? void 0 : _a.options().price;
        if (!candles || candles.length < 1) {
            return;
        }
        const lastCandleClose = Number((_b = candles[candles.length - 1]) === null || _b === void 0 ? void 0 : _b.close);
        if (!Number.isNaN(lastCandleClose) && currentLastValueLinePrice !== lastCandleClose) {
            if (lastValueLineRef.current) {
                (_c = seriesRef.current) === null || _c === void 0 ? void 0 : _c.removePriceLine(lastValueLineRef.current);
            }
            lastValueLineRef.current = (_d = seriesRef.current) === null || _d === void 0 ? void 0 : _d.createPriceLine(lastValueToPriceLineConfig(lastCandleClose));
        }
    }, [activePosition === null || activePosition === void 0 ? void 0 : activePosition.side, candles]);
    useEffect(() => {
        handleActivePositionMarker();
    }, [handleActivePositionMarker, activePositionLineRef.current]);
    useEffect(() => {
        var _a;
        (_a = chartRef.current) === null || _a === void 0 ? void 0 : _a.remove();
        fitContentCalledRef.current = false;
        initChart(activeInstrument === null || activeInstrument === void 0 ? void 0 : activeInstrument.decimals);
    }, [initChart, activeInstrument === null || activeInstrument === void 0 ? void 0 : activeInstrument.symbol, activeInstrument === null || activeInstrument === void 0 ? void 0 : activeInstrument.decimals, chartResolution]);
    useEffect(() => {
        transformAndSetData();
    }, [transformAndSetData]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: chartContainerRef, style: {
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                } }), historyLoading && _jsx(Loader, { transparentBackground: true })] }));
};
export default React.memo(Chart);
