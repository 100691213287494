import React from 'react';
import { capitalize, playClickSound } from '../../helpers';
import Select from 'components/Select/Select';
import SelectItem from 'components/Select/SelectItem';
import * as SelectPrimitive from '@radix-ui/react-select';
const chartTypes = ['simple', 'candle'];
import RxChevronDown from '../../icons/chevron-down.svg?url';
import RxChevronUp from '../../icons/chevron-up.svg?url';
export default class ChartTypeSelect extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}



	render() {
		const { currentChartType, handleChangeChartType } = this.props; 
		return (
			<div className="dropupContainer">
				<Select
					open={this.state.isOpen}
					onOpenChange={(isOpen) => this.setState({
						isOpen
					}) }
					dropdownClassName="dropdown-menu chart-type"
					value={currentChartType}
					onValueChange={handleChangeChartType}
					dropdownAlign="center"
					trigger={
						<button onClick={playClickSound} type="button" className="btn dropdown-toggle">
							<span> {capitalize(currentChartType)} </span>
							<SelectPrimitive.Icon className="SelectIcon">							
								{this.state.isOpen ? <img src={RxChevronUp} alt="up" /> : <img src={RxChevronDown} alt='down'/>}
							</SelectPrimitive.Icon>
						</button>
					}
				>
					{chartTypes.map((chartType) => {
						return (
							<SelectItem className='dropdown-item' value={chartType} asChild={false}  key={chartType}>
{capitalize(chartType)}
							</SelectItem>
						);
					})}
				</Select>
			</div>
		);
	}
}
