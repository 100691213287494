import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styles from './LoyaltyLevelIcon.module.scss';
import _t from 'counterpart';
const LoyaltyLevelIcon = ({ levelName }) => {
    const crownIcon = useMemo(() => {
        return require(`../../icons/${levelName}-crown.svg?url`);
    }, [levelName]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("img", { src: crownIcon, alt: levelName, className: styles.icon }), _jsx("span", Object.assign({ className: styles.label }, { children: _t.translate(`loyalty.levels.${levelName}`) }))] })));
};
export default LoyaltyLevelIcon;
