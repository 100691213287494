var IMessageActionType;
(function (IMessageActionType) {
    IMessageActionType["yes"] = "yes";
    IMessageActionType["no"] = "no";
    IMessageActionType["learn-more"] = "learn-more";
})(IMessageActionType || (IMessageActionType = {}));
export var StrategySortBy;
(function (StrategySortBy) {
    StrategySortBy["Rank"] = "RANK";
    StrategySortBy["Performance"] = "ROI";
    StrategySortBy["Date"] = "CREATED_AT";
    StrategySortBy["Followers"] = "FOLLOWERS";
})(StrategySortBy || (StrategySortBy = {}));
