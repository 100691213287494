import { RECEIVED_TRADING_OPTIONS, RECEIVED_MARGIN_CALL_LEVEL } from "../../actions";
import { isSet } from '../../utils/helpers';
export default function configReducer(state, action) {
    switch (action.type) {
        case RECEIVED_TRADING_OPTIONS: {
            const tradingOptions = action.payload.tradingOptions;
            const isCurrentInvestSupported = isSet(state.tradingScreen.investSelected) && tradingOptions.maxRisks.includes(state.tradingScreen.investSelected);
            const isCurrentDurationSupported = isSet(state.tradingScreen.closePositionAfterSeconds) && tradingOptions.positionDurations.includes(state.tradingScreen.closePositionAfterSeconds);
            const investSelected = isCurrentInvestSupported ? state.tradingScreen.investSelected : tradingOptions.defaults.maxRisk;
            const closePositionAfterSeconds = isCurrentDurationSupported ? state.tradingScreen.closePositionAfterSeconds : tradingOptions.defaults.positionDuration;
            return Object.assign(Object.assign({}, state), { config: Object.assign(Object.assign({}, state.config), action.payload.tradingOptions), tradingScreen: Object.assign(Object.assign({}, state.tradingScreen), { investSelected, closePositionAfterSeconds: closePositionAfterSeconds }) });
        }
        case RECEIVED_MARGIN_CALL_LEVEL: {
            return Object.assign(Object.assign({}, state), { config: Object.assign(Object.assign({}, state.config), { marginCallLevel: action.payload.marginCallLevel }) });
        }
        default:
            return state;
    }
}
