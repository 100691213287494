import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import config from 'config';
const TrackingPixel = React.memo(() => {
    if (!config.trackingPixel) {
        return null;
    }
    return (_jsx(_Fragment, { children: _jsx("img", { src: config.trackingPixel, alt: "", width: 1, height: 1 }) }));
});
export default TrackingPixel;
