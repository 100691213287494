var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import { loadLossLottie, loadProfitLottie } from '../../helpers';
const LottieBubbleBurst = ({ profitLoss }) => {
    const lottieRef = useRef(null);
    const [profitJson, setProfitJson] = useState(null);
    const [lossJson, setLossJson] = useState(null);
    useEffect(() => {
        const loadProfitJson = () => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield loadProfitLottie();
            setProfitJson(data);
        });
        const loadLossJson = () => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield loadLossLottie();
            setLossJson(data);
        });
        loadProfitJson();
        loadLossJson();
    }, []);
    useEffect(() => {
        var _a;
        (_a = lottieRef.current) === null || _a === void 0 ? void 0 : _a.setSubframe(false);
    }, []);
    return (_jsx(Lottie
    // @ts-ignore
    , { 
        // @ts-ignore
        renderer: "canvas", lottieRef: lottieRef, className: "bubble-burst-container", animationData: profitLoss < 0 ? lossJson : profitJson, autoplay: true, loop: false, rendererSettings: {
            preserveAspectRatio: 'xMidYMax slice',
        } }));
};
export default React.memo(LottieBubbleBurst);
