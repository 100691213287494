var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { isSet } from '../../helpers';
import classNames from 'classnames';
const Input = React.forwardRef((_a, ref) => {
    var _b;
    var { error, condensed = false, small = false, textCenter = false, suffixIcon = undefined } = _a, rest = __rest(_a, ["error", "condensed", "small", "textCenter", "suffixIcon"]);
    return (_jsxs("div", Object.assign({ className: classNames('form__fieldset', {
            'form__fieldset--condensed': condensed,
            'form__fieldset--small': small,
            'form__fieldset--text-center': textCenter,
            'form__fieldset--with-suffix-icon': isSet(suffixIcon),
        }) }, { children: [!['checkbox', 'radio'].includes((_b = rest.type) !== null && _b !== void 0 ? _b : '') && isSet(rest.label) && (_jsx("label", Object.assign({ className: "form__label", htmlFor: rest.name }, { children: rest.label }))), _jsxs("div", Object.assign({ className: "form__input-box" }, { children: [_jsx("input", Object.assign({ ref: ref, id: rest.name }, rest)), isSet(suffixIcon) && _jsx("img", { className: "form__input-suffix-icon", src: suffixIcon, alt: "icon" })] })), rest.type === 'checkbox' && isSet(rest.label) && (_jsx("label", Object.assign({ className: "form__label form__label--checkbox", htmlFor: rest.name }, { children: rest.label }))), _jsx("label", Object.assign({ htmlFor: rest.name, className: "error" }, { children: error }))] })));
});
export default React.memo(Input);
