import { jsx as _jsx } from "react/jsx-runtime";
import config from 'config';
import { PageLayout } from 'components/global/PageLayout';
import _t from 'counterpart';
import { CashierIframeWrapper } from 'components/CashierIframeWrapper';
import { useAppSelector } from 'wintrado-api';
const PageProfile = () => {
    const token = useAppSelector((state) => state.user.token);
    const { profileURL } = config.cashier;
    if (!profileURL || !token)
        return null;
    const url = profileURL(token, _t.getLocale());
    return (_jsx(PageLayout, Object.assign({ className: "profile", isEmbeddedCashier: true }, { children: _jsx(CashierIframeWrapper, { url: url }) })));
};
export default PageProfile;
