import { jsx as _jsx } from "react/jsx-runtime";
import { PageLayout } from '../global/PageLayout';
import { CashierIframeWrapper } from '../CashierIframeWrapper';
import config from 'config';
import _t from 'counterpart';
import { useAppSelector } from 'wintrado-api';
const PageUploadDocuments = () => {
    const token = useAppSelector((state) => state.user.token);
    if (!config.cashier.uploadDocumentsURL) {
        throw Error('Missing cashier.uploadDocumentsURL and tried to render UploadDocuments');
    }
    if (!token) {
        throw Error('Missing token in the store');
    }
    return (_jsx(PageLayout, Object.assign({ className: "upload-documents", isEmbeddedCashier: true }, { children: _jsx(CashierIframeWrapper, { url: config.cashier.uploadDocumentsURL(token, _t.getLocale()) }) })));
};
export default PageUploadDocuments;
