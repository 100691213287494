var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConfig } from '../utils/config';
import apiClient from './apiClient';
import { stringify } from 'qs';
const getBaseURL = () => {
    const config = getConfig();
    if (!config) {
        throw Error('config not loaded');
    }
    return config.cashier.baseURL;
};
// FIXME: Deprecated? Can this be removed?
export const login = ({ username, password, refreshToken }) => __awaiter(void 0, void 0, void 0, function* () {
    let dataToSend;
    if (refreshToken) {
        dataToSend = {
            refresh_token: refreshToken,
        };
    }
    else {
        dataToSend = {
            username,
            password,
        };
    }
    const response = yield apiClient.post(`${getBaseURL()}/api/sessions`, dataToSend);
    return response.data;
});
export const updateUser = (params) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.put(`${getBaseURL()}/api/users/self`, params);
    return response.data;
});
export const exchangeCoins = (amount) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/coins/exchange`, {
        amount,
    });
    return response.data;
});
export const getCoinsExchangeRate = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/coins/exchange_rate`);
    return response.data;
});
export const getMessages = (offset = 0, limit = 13) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/messages`, {
        params: {
            offset,
            limit,
        },
    });
    return response.data;
});
export const getMessage = (messageId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/messages/${messageId}`);
    return response.data;
});
export const getUnreadMessagesCount = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/messages/unread_count`);
    return response.data;
});
export const markMessageAsRead = (messageId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/messages/${messageId}/mark_as_read`, {});
    return response.data;
});
export const getCopyTradingStrategies = (limit = 10, offset = 0, search, sort, filters) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    const response = yield apiClient.get(`${getBaseURL()}/api/copytrading/strategies`, {
        params: {
            limit,
            offset,
            name: search,
            sort,
            minInvestFrom: (_a = filters.minInvestmentAmount) === null || _a === void 0 ? void 0 : _a.from,
            minInvestTo: (_b = filters.minInvestmentAmount) === null || _b === void 0 ? void 0 : _b.to,
            performanceFrom: (_c = filters.roiSinceStart) === null || _c === void 0 ? void 0 : _c.from,
            performanceTo: (_d = filters.roiSinceStart) === null || _d === void 0 ? void 0 : _d.to,
            noFollowersFrom: (_e = filters.followers) === null || _e === void 0 ? void 0 : _e.from,
            noFollowersTo: (_f = filters.followers) === null || _f === void 0 ? void 0 : _f.to,
        },
    });
    return response.data;
});
export const getOwnCopyTradingStrategies = (limit = 10, offset = 0) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/copytrading/strategies/own`, {
        params: {
            limit,
            offset,
        },
    });
    return response.data;
});
export const getFollowingCopyTradingStrategies = (limit = 10, offset = 0) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/copytrading/strategies/following`, {
        params: {
            limit,
            offset,
        },
    });
    return response.data;
});
export const getCopyTradingStrategy = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/copytrading/strategies/${id}`);
    return response.data.strategy;
});
export const postCopyTradingStrategy = (name, performanceFee, description, inviteOnly, minimumInvest) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/copytrading/strategies`, {
        name,
        feeStructure: {
            model: 'PERFORMANCE',
            performanceFeePercent: performanceFee,
        },
        description,
        inviteOnly,
        minimumInvest,
    });
    return response.data.strategy;
});
export const followStrategy = (id, amount) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/copytrading/strategies/${id}/follow`, {
        investmentAmount: amount,
    });
    return response.data;
});
export const getUserWallets = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/users/self/wallets/balance`);
    return response.data.wallets;
});
export const getLoyaltyLevels = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/coins/loyalty_levels`);
    return response.data.loyaltyLevels;
});
export const logActivity = (type, details) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/activity`, {
        type,
        details,
    });
    return response.data.success;
});
export const getWatchlist = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/watchlist`);
    return response.data.watchlist;
});
export const addToWatchlist = (symbol) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(`${getBaseURL()}/api/watchlist`, {
        symbol,
    });
    return response.data.success;
});
export const removeFromWatchlist = (symbol) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.delete(`${getBaseURL()}/api/watchlist/${symbol}`);
    return response.data.success;
});
export const walletTransactions = (walletId, startDate, endDate) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/users/self/wallets/${walletId}/transactions`, {
        params: {
            startDate,
            endDate,
        },
    });
    return response.data;
});
export const getWallets = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/users/self/wallets`);
    return response.data;
});
export const positionsByWallet = (walletId, startDate, endDate) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/users/self/positions/${walletId}`, {
        params: {
            startDate,
            endDate,
        },
    });
    return response.data;
});
export const getAllInstruments = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/users/instruments`);
    return response.data;
});
export const getGreyLabelLogo = (wtreferrer) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(`${getBaseURL()}/api/referrers/grey_label/logos`, {
        params: {
            wtreferrer,
        },
    });
    return response.data;
});
export function getVapidKey() {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield apiClient.get(`${getBaseURL()}/api/push_notifications/vapid_key`);
        return response.data.publicVapidKey;
    });
}
export function pushSubscribe(subscription, topic) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield apiClient.post(`${getBaseURL()}/api/push_notifications/subscriptions`, {
            subscription,
            topic,
        });
        return response.data;
    });
}
export function deleteSubscription(endpoint, topic) {
    return __awaiter(this, void 0, void 0, function* () {
        const qs = stringify({ topic, endpoint });
        const response = yield apiClient.delete(`${getBaseURL()}/api/push_notifications/subscriptions?${qs}`);
        return response.data;
    });
}
export function getSubscription(endpoint, topic) {
    return __awaiter(this, void 0, void 0, function* () {
        const qs = stringify({ topic, endpoint });
        const response = yield apiClient.get(`${getBaseURL()}/api/push_notifications/subscriptions?${qs}`);
        return response.data;
    });
}
