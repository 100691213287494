import { jsx as _jsx } from "react/jsx-runtime";
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isSet } from '../../helpers';
import { trading } from '../../colors';
const CANVAS_WIDTH = 100;
const CANVAS_HEIGHT = 24;
const MARGIN_VERTICAL = 2;
const MARGIN_HORIZONTAL = 2;
const scale = function ({ domain, range }) {
    const [domainStart, domainEnd] = domain;
    const [rangeStart, rangeEnd] = range;
    return function scale(value) {
        return rangeStart + (rangeEnd - rangeStart) * ((value - domainStart) / (domainEnd - domainStart));
    };
};
class TrendChart extends PureComponent {
    constructor() {
        super(...arguments);
        this.canvasRef = React.createRef();
        this.getDataPoints = () => {
            const { instrumentTrendQuotes } = this.props;
            if (!instrumentTrendQuotes) {
                return null;
            }
            const parsedTrendQuotes = instrumentTrendQuotes.reduce((acc, quote, index) => {
                if (quote !== null) {
                    acc.push({ x: index, y: Number(quote) });
                }
                return acc;
            }, []);
            const minX = 0;
            const maxX = instrumentTrendQuotes.length - 1;
            const minY = Math.min(...parsedTrendQuotes.map((parsedQuote) => parsedQuote.y));
            const maxY = Math.max(...parsedTrendQuotes.map((parsedQuote) => parsedQuote.y));
            const scaleX = scale({ range: [0, CANVAS_WIDTH - MARGIN_HORIZONTAL * 2], domain: [minX, maxX] });
            const scaleY = scale({ range: [CANVAS_HEIGHT - MARGIN_VERTICAL * 2, 0], domain: [minY, maxY] });
            return parsedTrendQuotes.map((item) => ({
                x: scaleX(item.x),
                y: scaleY(item.y),
            }));
        };
        this.drawChart = () => {
            var _a;
            const context = (_a = this.canvasRef.current) === null || _a === void 0 ? void 0 : _a.getContext('2d');
            if (!isSet(context)) {
                return;
            }
            const dataPoints = this.getDataPoints();
            if (dataPoints !== null) {
                // ! due to reverse canvas logic for coordinates
                const rising = !(dataPoints[0].y < dataPoints[dataPoints.length - 1].y);
                context.beginPath();
                dataPoints.forEach((dataPoint) => context.lineTo(dataPoint.x, dataPoint.y));
                context.strokeStyle = rising ? trading.buy : trading.sell;
                context.lineWidth = 2;
                context.stroke();
            }
        };
    }
    componentDidMount() {
        this.drawChart();
    }
    componentDidUpdate() {
        this.drawChart();
    }
    render() {
        return _jsx("canvas", { ref: this.canvasRef, width: CANVAS_WIDTH, height: CANVAS_HEIGHT });
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        instrumentTrendQuotes: state.trendQuotes[ownProps.instrumentSymbol],
    };
};
export default connect(mapStateToProps)(TrendChart);
