import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { actions, selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import React from 'react';
import { isSet } from '../helpers';
import buyCircleIcon from '../icons/main/buy-circle.svg?url';
import sellCircleIcon from '../icons/main/sell-circle.svg?url';
const MobileInstrumentTrendIndicator = () => {
    const activeInstrumentTrendPercentage = useAppSelector(selectors.activeInstrumentTrend);
    const dispatch = useAppDispatch();
    const showExplainerModal = () => dispatch(actions.showInstrumentTrendExplainerModal());
    if (!isSet(activeInstrumentTrendPercentage)) {
        return null;
    }
    const { buyPercentage, sellPercentage } = activeInstrumentTrendPercentage;
    return (_jsxs("div", Object.assign({ className: "mobile-instrument-trend-indicator", onClick: showExplainerModal }, { children: [_jsxs("div", Object.assign({ className: "item" }, { children: [_jsx("div", Object.assign({ className: "item__icon" }, { children: _jsx("img", { src: sellCircleIcon, alt: "sell-icon", className: "item__icon__image" }) })), _jsx("span", Object.assign({ className: "item__label" }, { children: `${sellPercentage}%` }))] })), _jsxs("div", Object.assign({ className: "item" }, { children: [_jsx("div", Object.assign({ className: "item__icon" }, { children: _jsx("img", { src: buyCircleIcon, alt: "buy-icon", className: "item__icon__image" }) })), _jsx("span", Object.assign({ className: "item__label" }, { children: `${buyPercentage}%` }))] }))] })));
};
export default React.memo(MobileInstrumentTrendIndicator);
