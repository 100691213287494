import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { borderColor, trading } from '../../colors';
import moment from 'moment';
import CustomChartTooltip from 'components/global/CustomChartTooltip';
import { formatPercentage } from '../../helpers';
const StrategyPerformanceAreaChart = ({ data, performancePercent, height, minimal = false }) => {
    const getColor = useMemo(() => {
        return performancePercent > 0 ? trading.buy : trading.sell;
    }, [performancePercent]);
    const parsedData = useMemo(() => {
        return data.map((item) => {
            return {
                date: new Date(item.date).getTime(),
                value: item.value,
            };
        });
    }, [data]);
    const dateTickFormatter = useCallback((value, index) => {
        return moment(value).format('ll');
    }, []);
    const percentageTickFormatter = useCallback((value, index) => {
        return formatPercentage(value);
    }, []);
    return (_jsx(ResponsiveContainer, Object.assign({ height: height || '100%', width: "99%" }, { children: _jsxs(AreaChart
        /* @ts-ignore */
        , Object.assign({ 
            /* @ts-ignore */
            cursor: "pointer", data: parsedData }, { children: [_jsx("defs", { children: _jsxs("linearGradient", Object.assign({ id: "gradient", x1: "0", y1: "0", x2: "0", y2: "1" }, { children: [_jsx("stop", { offset: "0%", stopColor: getColor, stopOpacity: 0.8 }), _jsx("stop", { offset: "95%", stopColor: getColor, stopOpacity: 0 })] })) }), !minimal && _jsx(CartesianGrid, { stroke: borderColor }), !minimal && (_jsx(Tooltip
                // @ts-ignore
                , { 
                    // @ts-ignore
                    content: _jsx(CustomChartTooltip, {}), itemStyle: { stroke: trading.buy }, wrapperStyle: { stroke: trading.buy }, contentStyle: { stroke: trading.buy }, labelStyle: { stroke: trading.buy } })), _jsx(XAxis, { height: minimal ? 0 : undefined, axisLine: false, tickLine: false, tick: !minimal, dataKey: "date", type: "number", domain: [data[0].date, data[data.length - 1].date], tickFormatter: dateTickFormatter, textAnchor: "end" }), _jsx(YAxis, { width: minimal ? 0 : 110, axisLine: false, tickLine: false, tick: !minimal, dataKey: "value", orientation: "right", tickFormatter: percentageTickFormatter }), _jsx(Area, { height: 100, type: "monotone", dataKey: "value", stroke: getColor, strokeWidth: 2, fill: "url(#gradient)", activeDot: { stroke: getColor, strokeWidth: 3 } })] })) })));
};
export default React.memo(StrategyPerformanceAreaChart);
