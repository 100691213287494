import React from 'react';
import 'moment/min/locales';
import Leaderboard from 'components/main/Leaderboard';
import { PageLayout } from 'components/global/PageLayout';

class PageLeaderboard extends React.Component {
	render() {
		return (
			<PageLayout className="leaderboard">
				<Leaderboard />
			</PageLayout>
		);
	}
}

export default PageLeaderboard;
