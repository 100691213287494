import React from 'react';

class ProgressBar extends React.PureComponent {
	getPercentage = () => Math.min(Math.max(0, this.props.percent || 0), 100);

	render() {
		const { value, ...rest } = this.props;
		const percentage = this.getPercentage().toFixed(2);
		return (
			<div className="progress-bar" {...rest}>
				<div className="progress-bar__bar" style={{ width: `${percentage}%` }} />
				<div className="progress-bar__value_wrapper">
					<span className="progress-bar__value">{value}</span>
				</div>
			</div>
		);
	}
}

export default ProgressBar;
