import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import _t from 'counterpart';
import marginVideoWebm from '../../../videos/margin-video.webm';
import marginVideo from '../../../videos/margin-video.mp4';
import { clearStopOutOccurred } from 'wintrado-api/src/actions';
import styles from './ModalStepOut.module.scss';
import Button from 'ui/components/Button/Button';
import { useAppDispatch, useAppSelector } from 'wintrado-api';
const ModalStepOut = () => {
    const stopOutOccurred = useAppSelector((state) => state.events.stopOutOccurred);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const showModal = () => {
        setIsOpen(true);
        dispatch(clearStopOutOccurred());
    };
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    useEffect(() => {
        if (stopOutOccurred) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopOutOccurred]);
    return (_jsx(Modal, Object.assign({ title: _t('modal.stop_out.title'), description: _t('modal.stop_out.description'), onRequestClose: handleCloseModal, isOpen: isOpen, className: styles.container }, { children: _jsxs("div", Object.assign({ className: styles.holder }, { children: [_jsx("div", Object.assign({ className: styles.videoholder }, { children: _jsxs("video", Object.assign({ muted: true, loop: true, autoPlay: true, playsInline: true }, { children: [_jsx("source", { src: marginVideoWebm, type: "video/webm" }), _jsx("source", { src: marginVideo, type: "video/mp4" })] })) })), _jsx(Button, Object.assign({ variant: "primary", className: styles.button, onClick: handleCloseModal, flexible: true }, { children: _t.translate('main.i_got_it') }))] })) })));
};
export default React.memo(ModalStepOut);
