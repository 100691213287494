import { all, delay, put, race, select, take, takeEvery, takeLatest, } from 'redux-saga/effects';
import { activePositionSelector, openPositionsSelector } from '../selectors';
import positionsHelpers from '../utils/positionsHelpers';
import { PositionCloseReason, PositionStatusCode, } from '../reducers/positions/types';
import { displayError, executePendingBalanceUpdate, INSTRUMENTS_SET_ACTIVE, openPositionsListUpdated, POSITION_CLOSED, POSITION_OPENED, POSITION_STATUS, positionClosed, positionClosingRevert, POSITIONS_CLOSE_ALL_REQUESTED, POSITIONS_CLOSE_SINGLE_FINISHED, POSITIONS_CLOSE_SINGLE_REQUESTED, POSITIONS_SET_ACTIVE, positionsClearActive, positionsCloseAllFinished, positionsCloseSingleFinished, RECEIVED_OPENED_POSITIONS_LIST, requestPositionCloseAll, requestPositionCloseSingle, setActiveInstrument, setActivePositionId, setStopOutOccurred, updateBalanceAdjustmentFromPL, updatePosition, } from '../actions';
import { getConfig } from '../utils/config';
function easeOutExpo(t, b, c, d) {
    return (c * (-Math.pow(2, (-10 * t) / d) + 1) * 1024) / 1023 + b;
}
function* positionClosedSaga(action) {
    const closingPosition = action.payload;
    const positions = yield select((state) => state.positions);
    if (positions[closingPosition.positionId]) {
        const sanitizedPosition = positionsHelpers.sanitizeSinglePosition(closingPosition);
        // @ts-ignore
        yield put(updatePosition(sanitizedPosition));
        if (sanitizedPosition.closeReason === PositionCloseReason.STOP_OUT) {
            yield put(setStopOutOccurred());
        }
        const pendingCloses = yield select((state) => state.pendingPositionCloses);
        if (pendingCloses === 0) {
            const closedPL = yield select((state) => state.closedPositionsTotalPL);
            const closingAllPositions = yield select((state) => state.closingAllPositions);
            // Countdown duration
            const duration = 1200;
            // Final duration at value 0 before hiding bubble
            const finalDelay = 400;
            // Number of steps in the count down
            const steps = 30;
            const startVal = Math.abs(closedPL);
            for (var i = 0; i < steps; i++) {
                const frameVal = startVal - easeOutExpo(i, 0, startVal, steps);
                const correctionAbs = Math.abs(closedPL) - Math.abs(frameVal);
                const balanceAdjustmentFromPL = closedPL > 0 ? correctionAbs : -correctionAbs;
                yield put(updateBalanceAdjustmentFromPL(balanceAdjustmentFromPL));
                yield delay(duration / steps);
            }
            // Set final balance as easing function gets close, but may never reach the actual value
            yield put(updateBalanceAdjustmentFromPL(closedPL));
            yield delay(finalDelay);
            yield put(positionsCloseSingleFinished(closingPosition.positionId));
            if (closingAllPositions) {
                yield put(positionsCloseAllFinished());
            }
            // If we queued a balance updating during the closing, update balance now
            const pendingBalanceUpdate = yield select((state) => state.user.pendingBalanceUpdate);
            if (pendingBalanceUpdate !== null) {
                yield put(executePendingBalanceUpdate());
            }
        }
    }
}
function* closeSinglePosition(action) {
    const positionId = action.payload;
    yield put(requestPositionCloseSingle(positionId));
    const { closed, timeout } = yield race({
        closed: take(POSITION_CLOSED),
        timeout: delay(5000),
    });
    if (timeout) {
        yield put(positionClosingRevert(positionId));
    }
}
function* positionOpenedSaga(action) {
    const { position } = action.payload;
    const instruments = yield select((state) => state.instruments);
    if (instruments[position.symbol] &&
        getConfig().features.automaticallySetActivePosition) {
        yield put(setActivePositionId(position.positionId));
    }
}
function* positionSetActive(action) {
    const activePositionId = action.payload;
    const activeInstrument = yield select((state) => state.activeInstrument);
    if (activePositionId) {
        const position = yield select((state) => state.positions[activePositionId]);
        if (activeInstrument === null) {
            throw new Error('Active instrument is null in positionSetActive saga');
        }
        if (position === undefined) {
            throw new Error('Active position is undefined in positionSetActive saga');
        }
        if (activeInstrument.symbol !== position.symbol &&
            position.symbol !== null) {
            yield put(setActiveInstrument(position.symbol));
        }
    }
}
function* positionCloseSingleFinishedSaga(action) {
    var _a;
    const { positionId: closingPositionId } = action.payload;
    const openPositions = yield select(openPositionsSelector);
    const activePositionId = yield select((state) => state.activePositionId);
    const instruments = yield select((state) => state.instruments);
    const supportedInstrumentPositions = [...openPositions].filter((position) => position.symbol && !!instruments[position.symbol]);
    if (closingPositionId === activePositionId &&
        getConfig().features.automaticallySetActivePosition) {
        if (supportedInstrumentPositions.length > 0) {
            const positionIdToActivate = (_a = supportedInstrumentPositions[supportedInstrumentPositions.length - 1]) === null || _a === void 0 ? void 0 : _a.positionId;
            if (positionIdToActivate) {
                yield put(setActivePositionId(positionIdToActivate));
            }
        }
        else {
            yield put(positionsClearActive());
        }
    }
}
function* instrumentsSetActive(action) {
    const activePosition = yield select(activePositionSelector);
    if (activePosition && activePosition.symbol !== action.payload) {
        yield put(positionsClearActive());
    }
}
function* positionsCloseAllRequested(action) {
    yield put(requestPositionCloseAll());
}
function* positionStatusSaga(action) {
    const data = action.payload;
    const positions = yield select((state) => state.positions);
    if (!positions[data.positionId] && !data.openedAt) {
        return;
    }
    const sanitizedPosition = positionsHelpers.sanitizeSinglePosition(data);
    yield put(updatePosition(sanitizedPosition));
    if (data.status === PositionStatusCode.CLOSED) {
        yield put(positionClosed(sanitizedPosition));
    }
    else if (data.status === PositionStatusCode.ERROR_ON_CLOSING) {
        yield put(displayError('position_close_rejected', data.reason));
    }
}
function* receivedOpenPositionsListSaga(action) {
    const openPositions = action.payload;
    const instruments = yield select((state) => state.instruments);
    let positionsByIds = {};
    let lastPositionId;
    if (openPositions.length !== 0) {
        const sanitizedPositions = openPositions.map((entry) => {
            entry.status = PositionStatusCode.OPEN;
            return positionsHelpers.sanitizeSinglePosition(entry);
        });
        positionsByIds = sanitizedPositions.reduce((obj, item) => {
            if (!!instruments[item.symbol] &&
                (!lastPositionId ||
                    (!!item.positionId && item.positionId > lastPositionId))) {
                lastPositionId = item.positionId;
            }
            return Object.assign(Object.assign({}, obj), { [item.positionId]: item });
        }, {});
    }
    yield put(openPositionsListUpdated(positionsByIds));
    if (!!lastPositionId && getConfig().features.automaticallySetActivePosition) {
        yield put(setActivePositionId(lastPositionId));
    }
}
function* positionSaga() {
    yield all([
        takeEvery(POSITION_CLOSED, positionClosedSaga),
        takeEvery(POSITIONS_CLOSE_SINGLE_REQUESTED, closeSinglePosition),
        takeEvery(POSITIONS_CLOSE_SINGLE_FINISHED, positionCloseSingleFinishedSaga),
        takeLatest(POSITION_OPENED, positionOpenedSaga),
        takeLatest(POSITIONS_SET_ACTIVE, positionSetActive),
        takeLatest(INSTRUMENTS_SET_ACTIVE, instrumentsSetActive),
        takeEvery(POSITIONS_CLOSE_ALL_REQUESTED, positionsCloseAllRequested),
        takeLatest(POSITION_STATUS, positionStatusSaga),
        takeEvery(RECEIVED_OPENED_POSITIONS_LIST, receivedOpenPositionsListSaga),
    ]);
}
export default positionSaga;
