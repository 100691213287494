import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { playClickSound } from '../../../helpers';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import Select from 'components/Select/Select';
import SelectItem from 'components/Select/SelectItem';
import * as SelectPrimitive from '@radix-ui/react-select';
import RxChevronDown from '../../../icons/chevron-down.svg?url';
import RxChevronUp from '../../../icons/chevron-up.svg?url';
import styles from './LanguageSelect.module.scss';
const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: 'Francais', code: 'fr' },
    { name: 'Português ', code: 'pt' },
    { name: 'عربى', code: 'ar' },
    { name: 'Türkçe', code: 'tr' },
    { name: 'Pусский', code: 'ru' },
    { name: 'Italiano', code: 'it' },
    { name: 'Deutsch', code: 'de' },
    { name: 'हिन्दी', code: 'hi' },
    { name: 'اردو', code: 'ur' },
    { name: 'Kiswahili', code: 'sw' },
    { name: 'Malay', code: 'my' },
    { name: '日本語', code: 'ja' },
    { name: 'Tiếng Việt ', code: 'vi' },
    { name: 'ភាសាខ្មែរ', code: 'km' },
    { name: '中文', code: 'zh' },
];
const LanguageSelect = () => {
    const userLanguage = useAppSelector((state) => state.settings.language);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const handleLanguageChanged = (lang) => {
        playClickSound();
        dispatch(actions.userSaveLanguage(lang));
    };
    const currentLanguage = LANGUAGES.find((obj) => obj.code === userLanguage);
    if (!currentLanguage) {
        throw Error(`Language with code ${userLanguage} cannot be found.`);
    }
    return (_jsx("div", Object.assign({ className: "dropupContainer" }, { children: _jsx(Select, Object.assign({ value: currentLanguage.code, open: isOpen, onOpenChange: (isOpen) => setIsOpen(isOpen), onValueChange: handleLanguageChanged, dropdownAlign: "center", trigger: _jsxs("button", Object.assign({ onClick: playClickSound, type: "button", className: "btn dropdown-toggle" }, { children: [_jsxs("div", { children: [_jsx("img", { alt: `${userLanguage}-icon`, className: styles.icon, src: require(`../../../images/${userLanguage}.png`) }), _jsxs("span", Object.assign({ className: "lang" }, { children: [" ", currentLanguage.name, " "] }))] }), _jsx(SelectPrimitive.Icon, Object.assign({ className: "SelectIcon" }, { children: isOpen ? _jsx("img", { src: RxChevronUp, alt: "up" }) : _jsx("img", { src: RxChevronDown, alt: "down" }) }))] })) }, { children: LANGUAGES.map((item) => {
                return (_jsxs(SelectItem, Object.assign({ className: "dropdown-item", asChild: false, value: item.code }, { children: [_jsx("img", { alt: `${item.code}-icon`, src: require(`../../../icons/countries/${item.code}.svg?url`) }), _jsxs("span", { children: [" ", item.name, " "] })] }), item.code));
            }) })) })));
};
export default LanguageSelect;
