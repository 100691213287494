import React from 'react';
import _t from 'counterpart';

export default class MarketClosedNotice extends React.PureComponent {
	render() {
		const { activeInstrument } = this.props;
		const closedUntil = activeInstrument.validTradingIntervals.next.start.toLocaleString(_t.getLocale(), {
			weekday: 'long',
			hour: '2-digit',
			minute: '2-digit',
		});
		return (
			<div className="market-closed-notice">
				<div className="market-closed-notice__wrapper">
					<img
						src={require('../icons/empty_placeholder.svg?url')}
						className="market-closed-notice__image"
						alt="market-closed"
					/>
					<div className="market-closed-notice__shadow">
						<img
							src={require('../icons/circle-shadow.svg?url')}
							className="market-closed-notice__shadow_image"
							alt="market-closed"
						/>
					</div>
					<h1 className="market-closed-notice__title">{_t.translate('main.market_closed')}</h1>
					<span className="market-closed-notice__subtitle">
						{_t.translate('main.opens_again_on')}
						<strong>{` ${closedUntil}`}</strong>
					</span>
				</div>
			</div>
		);
	}
}
