import { all, takeLatest, put, call } from 'redux-saga/effects';
import { FETCH_USER_REQUESTED, fetchUserReject, fetchUserSuccess } from '../actions';
import { getUser } from '../services/oAuthService';
function* handleUserRequested() {
    try {
        // @ts-ignore
        const response = yield call(getUser);
        yield put(fetchUserSuccess(response));
    }
    catch (e) {
        yield put(fetchUserReject(e.message));
    }
}
function* userSaga() {
    yield all([
        takeLatest(FETCH_USER_REQUESTED, handleUserRequested),
    ]);
}
export default userSaga;
