import { store } from '../store';
const getLastQuoteForSymbol = (symbol) => {
    const symbolQuotes = store.getState().quotes[symbol];
    if (!symbolQuotes || symbolQuotes.length === 0) {
        return null;
    }
    return symbolQuotes[symbolQuotes.length - 1];
};
const sanitizeQuotesDates = (quotesData) => {
    return Object.assign(Object.assign({}, quotesData), { start: new Date(quotesData.start), end: new Date(quotesData.end) });
};
const sanitizeQuotes = (quotes) => {
    return quotes.map(sanitizeSingleQuote);
};
const sanitizeSingleQuote = (quote) => {
    return {
        symbol: quote.symbol,
        ask: Number(quote.ask),
        bid: Number(quote.bid),
        timestamp: new Date(quote.timestamp),
        timestampMs: quote.timestamp,
        value: (quote.ask + quote.bid) / 2,
        spread: Math.abs(quote.ask - quote.bid),
    };
};
const clearOldQuotes = (quotes, cutoffDate) => {
    const filteredQuotes = Object.keys(quotes).reduce((clearedQuotes, currentSymbol) => {
        const currentSymbolQuotes = quotes[currentSymbol];
        if (!currentSymbolQuotes) {
            return clearedQuotes;
        }
        const filteredSymbolQuotes = currentSymbolQuotes.filter((quote) => cutoffDate.getTime() <= quote.timestampMs);
        return Object.assign(Object.assign({}, clearedQuotes), { [currentSymbol]: filteredSymbolQuotes });
    }, {});
    return filteredQuotes;
};
export default {
    getLastQuoteForSymbol,
    sanitizeSingleQuote,
    sanitizeQuotes,
    sanitizeQuotesDates,
    clearOldQuotes
};
