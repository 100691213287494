import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useIsDesktop } from 'utils/responsiveHelpers';
import { getUnreadMessagesCount } from 'wintrado-api/src/services/crmService';
import { actions } from 'wintrado-api';
import { useDispatch } from 'react-redux';
const MESSAGE_COUNT_DISPLAY_LIMIT = 99;
const INVALIDATE_MESSAGE_QUERY = 'INVALIDATE_MESSAGE_QUERY';
export default function MessagesIcon() {
    var _a, _b;
    const unreadMessagesQuery = useQuery({
        queryKey: ['unread-messages'],
        queryFn: () => getUnreadMessagesCount(),
    });
    const unreadMessagesCount = (_b = (_a = unreadMessagesQuery.data) === null || _a === void 0 ? void 0 : _a.unreadCount) !== null && _b !== void 0 ? _b : 0;
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    useEffect(() => {
        const channel = new BroadcastChannel('sw-messages');
        const handleMessage = (event) => {
            const { type } = event.data;
            if (type !== undefined && type === INVALIDATE_MESSAGE_QUERY) {
                queryClient.invalidateQueries(['unread-messages']);
                dispatch(actions.fetchMessages());
            }
        };
        channel.addEventListener('message', handleMessage);
        return () => {
            channel.removeEventListener('message', handleMessage);
            channel.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isDesktop = useIsDesktop();
    const formatMessageCount = (value) => {
        if (value > MESSAGE_COUNT_DISPLAY_LIMIT) {
            return '99+';
        }
        return value;
    };
    return (_jsxs("div", Object.assign({ className: "messages-icon" }, { children: [_jsxs("svg", Object.assign({ fill: "none", height: "16", viewBox: "0 0 18 16", width: "18", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsxs("mask", Object.assign({ id: "circle" }, { children: [_jsx("rect", { x: "0", y: "0", fill: "white", width: "18", height: "16" }), unreadMessagesCount > 0 && !isDesktop && _jsx("circle", { cx: "16", cy: "2", fill: "black", r: "6" })] })), _jsx("g", Object.assign({ mask: "url(#circle)" }, { children: _jsxs("g", Object.assign({ stroke: "#fff", strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10", strokeWidth: "1.5" }, { children: [_jsx("path", { d: "m1.5 5 7.5 4 7.5-4" }), _jsx("path", { d: "m15.5 14.5h-13c-.552 0-1-.448-1-1v-11c0-.552.448-1 1-1h13c.552 0 1 .448 1 1v11c0 .552-.448 1-1 1z" })] })) }))] })), unreadMessagesCount !== 0 && isDesktop && (_jsx("div", Object.assign({ className: "message-count" }, { children: _jsx("span", { children: formatMessageCount(unreadMessagesCount) }) }))), unreadMessagesCount > 0 && !isDesktop && _jsx("div", { className: "messages-icon__unread-badge" })] })));
}
