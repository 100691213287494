import React from 'react';
import _t from 'counterpart';
import { isOperaMini, releaseVersion, webpackHash } from '../../helpers';
import * as Sentry from '@sentry/react';
import Button from 'components/Button/Button';

export const ErrorScreen = () => {
	const onRefreshClicked = () => {
		window.location.reload();
	};

	const lastSentryId = Sentry.lastEventId();

	return (
		<div id="error-screen">
			<h2>{_t.translate('error.oops')}</h2>
			<p>{_t.translate('error.generic')}</p>
			{isOperaMini() && (
				<>
					<br />
					<p>{_t.translate('error.opera')}</p>
				</>
			)}

			<p>{_t.translate('error.refresh_to_continue')}</p>
			{!!lastSentryId && <p>{`ID: ${lastSentryId}`}</p>}

			<Button blue onClick={onRefreshClicked}>
				{_t.translate('main.refresh')}
			</Button>
			<div className="version-container">
				<span>
					<span>{`v${releaseVersion()}`}</span>
					<span>{webpackHash()}</span>
				</span>
			</div>
		</div>
	);
};
