import { RECEIVED_FEATURE_FLAGS, SET_CHART_RESOLUTION, SET_DEV_MENU_SETTINGS, SET_DONT_SHOW_CHROME_INSTALL_CTA, SET_DONT_SHOW_INSTALL_CTA, SET_INSTRUMENT_TO_PRESELECT, SET_IS_SIDEBAR_COLLAPSED, SET_PWA_INSTALL_PROMPT, SET_SOUNDSTYLE, TOGGLE_DEV_MENU, USER_SAVE_BELL_SOUND, USER_SAVE_CHART, USER_SAVE_LANGUAGE, USER_SAVE_SOUND, } from '../../actions';
export default function settingsReducer(state, action) {
    switch (action.type) {
        case USER_SAVE_SOUND: {
            const sound = action.payload;
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { sound }) });
        }
        case USER_SAVE_BELL_SOUND: {
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { bellSound: action.payload.bellSound }) });
        }
        case USER_SAVE_LANGUAGE: {
            const { language } = action.payload;
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { language }) });
        }
        case USER_SAVE_CHART: {
            const chartType = action.payload;
            const chartResolution = state.settings.chartResolution;
            const chartUpdate = {
                chartType,
            };
            if (chartType === 'candle' && chartResolution === '0') {
                chartUpdate.chartResolution = '1';
            }
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), chartUpdate) });
        }
        case SET_CHART_RESOLUTION: {
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { chartResolution: action.payload.resolution }) });
        }
        case TOGGLE_DEV_MENU: {
            return Object.assign(Object.assign({}, state), { devMenu: Object.assign(Object.assign({}, state.devMenu), { enabled: !state.devMenu.enabled, useStagingGateway: !state.devMenu.enabled
                        ? state.devMenu.useStagingGateway
                        : false, clearAllDataOnLogoff: !state.devMenu.enabled
                        ? state.devMenu.clearAllDataOnLogoff
                        : false }) });
        }
        case SET_DEV_MENU_SETTINGS: {
            return Object.assign(Object.assign({}, state), { devMenu: Object.assign(Object.assign({}, state.devMenu), action.payload.devMenuSettings) });
        }
        case SET_SOUNDSTYLE:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { soundstyle: action.payload.soundstyle }) });
        case SET_DONT_SHOW_INSTALL_CTA:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { dontShowInstallCTA: true }) });
        case SET_DONT_SHOW_CHROME_INSTALL_CTA:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { dontShowChromeInstallCTA: true }) });
        case SET_PWA_INSTALL_PROMPT:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { pwaInstallPrompt: action.payload.installPrompt }) });
        case RECEIVED_FEATURE_FLAGS:
            const featureFlags = action.payload.flags;
            const referFriendsEnabled = featureFlags.includes('REFER_FRIENDS');
            const debugEnabled = featureFlags.includes('DEBUG');
            const supportChatEnabled = featureFlags.includes('SUPPORT_CHAT');
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { features: {
                        referFriendsEnabled,
                        debugEnabled,
                        supportChatEnabled,
                    } }) });
        case SET_INSTRUMENT_TO_PRESELECT:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { instrumentToPreselect: action.payload.symbol }) });
        case SET_IS_SIDEBAR_COLLAPSED:
            return Object.assign(Object.assign({}, state), { settings: Object.assign(Object.assign({}, state.settings), { isSidebarCollapsed: action.payload.isCollapsed }) });
        default:
            return state;
    }
}
