import React from 'react';
import { connect } from 'react-redux';
import { PositionStatusCode, selectors } from 'wintrado-api';
import { formatCurrency } from 'utils/currency';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LottieBubbleBurst from 'components/main/LottieBubbleBurst';

class MainBubble extends React.Component {
	render() {
		const { isActivePositionClosing, closingAllPositions, position, profitLoss, accountCurrency, centered } =
			this.props;
		const cleanProfitLoss = profitLoss.toFixed(2) === '-0.00' ? Math.abs(profitLoss) : profitLoss;
		const formattedPL = formatCurrency(cleanProfitLoss, accountCurrency);

		return (
			<div>
				{position || closingAllPositions ? (
					<TransitionGroup>
						<CSSTransition key={position ? position.status : 'closing-all'} classNames="fade" timeout={550}>
							<div
								id="chart_profitloss"
								className={classNames({
									'position-closing': isActivePositionClosing || closingAllPositions,
									lost: Boolean(position) && position.profitLoss < 0 && position.status !== PositionStatusCode.NEW,
									won: Boolean(position) && position.profitLoss >= 0 && position.status !== PositionStatusCode.NEW,
									new: Boolean(position) && position.status === PositionStatusCode.NEW,
									centered,
								})}
							>
								{formattedPL}
							</div>
						</CSSTransition>
					</TransitionGroup>
				) : (
					''
				)}
				{(isActivePositionClosing || closingAllPositions) && this.props.position && (
					<LottieBubbleBurst profitLoss={this.props.position.profitLoss} />
				)}
			</div>
		);
	}
}

MainBubble.defaultProps = {
	centered: false,
};

const mapStateToProps = (state) => ({
	position: selectors.activePositionSelector(state),
	isActivePositionClosing: selectors.isActivePositionClosing(state),
	accountCurrency: state.user.accountCurrency,
	profitLoss: selectors.bubbleValueSelector(state),
	closingAllPositions: state.closingAllPositions,
});

export default connect(mapStateToProps)(MainBubble);
