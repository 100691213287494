import React from 'react';
import { CSSTransition } from 'react-transition-group';
import CancelIcon from "../../icons/cancel-material.svg"

const _t = require('counterpart');

class ErrorMessage extends React.PureComponent {
	state = {
		title: '',
		message: '',
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.title !== null) {
			return {
				title: nextProps.title,
				message: nextProps.message,
				extra: nextProps.extra,
			};
		}
		return null;
	}

	getTranslationOrFallback = (key, extra) => {
		let translation = '';
		if (key && key.length !== 0) {
			translation = _t.translate(`error.${key.toLowerCase()}`, {
				fallback: key,
				...extra,
			});
		}
		return translation;
	};

	render() {
		const { visible } = this.props;
		const { title, message, extra } = this.state;
		return ( 
			<CSSTransition in={visible} timeout={350}>
				<div className="errorMessage" role="alert">
					<div className="alert alert-warning">
						<div className="alert-wrapper">
							<div className="icon-container"> 
								<CancelIcon className="icon" />
							</div>
							<div className="alert-content">
								<h2>{this.getTranslationOrFallback(title, extra)}</h2>

								<span className="errorText">{this.getTranslationOrFallback(message, extra)}</span>
							</div>
						</div>
					</div>
				</div>
			</CSSTransition>
		);
	}
}

export default ErrorMessage;
