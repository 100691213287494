import { APPEND_QUOTE_FOR_SYMBOL, MERGE_QUOTES, SET_QUOTES, SET_QUOTES_FOR_SYMBOL } from "../../actions";
export default function quotesReducer(state, action) {
    var _a, _b, _c;
    switch (action.type) {
        case APPEND_QUOTE_FOR_SYMBOL:
            const currentSymbolQuotes = state.quotes[action.payload.symbol] || [];
            const lastQuote = currentSymbolQuotes[currentSymbolQuotes.length - 1];
            const newQuote = action.payload.quote;
            if (lastQuote && lastQuote.timestampMs >= newQuote.timestampMs) {
                return state;
            }
            return Object.assign(Object.assign({}, state), { quotes: Object.assign(Object.assign({}, state.quotes), { [action.payload.symbol]: [
                        ...(_a = state.quotes[action.payload.symbol]) !== null && _a !== void 0 ? _a : [],
                        newQuote,
                    ] }), tradingEnabled: state.tradingEnabled ? true : (((_b = state.activeInstrument) === null || _b === void 0 ? void 0 : _b.symbol) === action.payload.symbol) });
        case SET_QUOTES_FOR_SYMBOL:
            return Object.assign(Object.assign({}, state), { quotes: Object.assign(Object.assign({}, state.quotes), { [action.payload.symbol]: action.payload.quotes }), tradingEnabled: state.tradingEnabled ? true : (((_c = state.activeInstrument) === null || _c === void 0 ? void 0 : _c.symbol) === action.payload.symbol) });
        case MERGE_QUOTES:
            return Object.assign(Object.assign({}, state), { quotes: Object.assign(Object.assign({}, state.quotes), action.payload.quotes) });
        case SET_QUOTES: {
            return Object.assign(Object.assign({}, state), { quotes: action.payload.quotes });
        }
        default:
            return state;
    }
}
