import React from 'react';
import { BuySellIcon } from 'components/BuySellIcon';

class PositionOpenPrice extends React.PureComponent {
	render() {
		const { openPrice, side } = this.props;
		return (
			<div id="position-open-price" className="d-hide m-flex">
				<BuySellIcon side={side} />
				<span>{openPrice}</span>
			</div>
		);
	}
}

export default PositionOpenPrice;
