import React from 'react';
import _t from 'counterpart';
import maintenance from '../../images/maintenance.svg?url';
import Button from 'components/Button/Button';

export const MaintenanceScreen = () => {
	const onRefreshClicked = () => {
		window.location.reload();
	};

	return (
		<div className="maintenance-screen">
			<img
				src={require(`../../images/brands/${__BRAND__}/logo-mobile.png`)}
				className="img-fluid maintenance-screen__logo"
				alt="logo"
			/>
			<img src={maintenance} alt="maintenance" className="maintenance-screen__icon" />
			<h2 className="maintenance-screen__title">{_t.translate('error.app_issues')}</h2>
			<p className="maintenance-screen__description">{_t.translate('error.maintenance_description')}</p>

			<Button gray onClick={onRefreshClicked} className="maintenance-screen__button">
				{_t.translate('main.refresh')}
			</Button>
		</div>
	);
};
