import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { isMobileLayout } from '../../helpers';
import OpenPositionBig from 'components/OpenPositionBig';
import BigExchangeComp from 'components/BigExchangeComp';
import MainBubble from 'components/main/Bubble';
import { selectors, useAppSelector } from 'wintrado-api';
import MarketClosedNotice from 'components/MarketClosedNotice';
import classNames from 'classnames';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import ChartControls from 'components/global/ChartControls';
import Chart from 'components/Chart/Chart';
import NoInstrumentsFound from 'components/NoInstrumentFound';
import LostConnectionDialog from 'components/LostConnectionDialog/LostConnectionDialog';
const ChartWrapper = ({ openInstrumentsSlider, switchToMarkets }) => {
    const activeInstrument = useAppSelector(selectors.activeInstrumentWithIntervalsSelector);
    const activePosition = useAppSelector(selectors.activePositionSelector);
    const instrumentsCount = useAppSelector((state) => Object.entries(state.instruments).length);
    const isAttemptingReconnect = useAppSelector((state) => state.connection.isAttemptingReconnect);
    const handleInstrumentClick = useCallback((e) => {
        e.preventDefault();
        if (isMobileLayout()) {
            openInstrumentsSlider();
        }
        else {
            switchToMarkets();
        }
    }, [openInstrumentsSlider, switchToMarkets]);
    return (_jsx("div", Object.assign({ className: "chart-wrapper" }, { children: _jsxs("div", Object.assign({ className: "chart content-box", id: "chartContainer" }, { children: [_jsx(Mobile, { children: activePosition ? (_jsx(OpenPositionBig, { onClickInstrument: handleInstrumentClick, activePosition: activePosition })) : (_jsx(BigExchangeComp, { onClickInstrument: handleInstrumentClick })) }), _jsx(Desktop, { children: _jsx(BigExchangeComp, { onClickInstrument: handleInstrumentClick }) }), _jsxs("div", Object.assign({ className: classNames('chart__holder', {
                        'chart__holder--market-closed': activeInstrument && !activeInstrument.validTradingIntervals.current,
                    }) }, { children: [_jsx(Chart, {}), activePosition && _jsx(MainBubble, {}), _jsx(Mobile, { children: _jsx("div", Object.assign({ className: "controls" }, { children: _jsx(ChartControls, {}) })) }), _jsx(Mobile, { children: activeInstrument && !activeInstrument.validTradingIntervals.current && (_jsx(MarketClosedNotice, { activeInstrument: activeInstrument })) })] })), _jsx(Desktop, { children: activeInstrument && !activeInstrument.validTradingIntervals.current && (_jsx(MarketClosedNotice, { activeInstrument: activeInstrument })) }), instrumentsCount === 0 && _jsx(NoInstrumentsFound, {}), isAttemptingReconnect && _jsx(LostConnectionDialog, {})] })) })));
};
export default ChartWrapper;
