import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import MainMenuBottomDuration from 'components/main/MenuBottomDuration';
import MenuBottomControls from 'components/main/MenuBottomControls';
import MainMenuBottomStake from 'components/main/MenuBottomStake';
import MainMenuBottomMaxRisk from 'components/main/MenuBottomMaxRisk';
import MainMenuSettings from 'components/main/MenuBottomSettings';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
const TradingBar = () => {
    return (_jsx("div", Object.assign({ id: "tradingBar", className: "trading-bar" }, { children: _jsx("div", Object.assign({ className: "row" }, { children: _jsx("div", Object.assign({ className: 'trading-bar__col' }, { children: _jsxs("div", Object.assign({ className: "trading-bar__holder" }, { children: [_jsxs("div", Object.assign({ className: "trading-bar__upper vert-container m-order-1" }, { children: [_jsx(MainMenuBottomDuration, {}), _jsx(MainMenuBottomStake, {}), _jsx(Mobile, { children: _jsx(MainMenuBottomMaxRisk, {}) })] })), _jsx(MenuBottomControls, {}), _jsxs("div", Object.assign({ className: "trading-bar__right" }, { children: [_jsx(Desktop, { children: _jsx("div", Object.assign({ className: "profit-invest" }, { children: _jsx(MainMenuBottomMaxRisk, {}) })) }), _jsx("div", Object.assign({ className: "trading-bar__settings" }, { children: _jsx(MainMenuSettings, {}) }))] }))] })) })) })) })));
};
export default React.memo(TradingBar);
