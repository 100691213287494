import React from 'react';
import moment from 'moment';
import 'moment/min/locales';

const _t = require('counterpart');

export default class DateRangeHeader extends React.Component {
	render() {
		const { startDate, endDate } = this.props;

		return (
			<div className="date-range-header">
				<span className="start-date">{startDate !== null ? moment(startDate).format('l') : ''}</span>
				<span className="divider">—</span>
				<span className="end-date">{endDate !== null ? moment(endDate).format('l') : ''}</span>
			</div>
		);
	}
}
