import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import styles from "./Loader.module.scss";
const Loader = ({ opaqueBackground = false, semiTransparentBackground = false, inline = false, }) => (_jsxs("div", Object.assign({ className: classNames(styles.container, {
        [styles.containerInline]: inline,
    }) }, { children: [_jsx("div", { className: classNames(styles.loaderBackground, {
                [styles.loaderBackgroundOpaque]: opaqueBackground,
                [styles.loaderBackgroundSemiTransparent]: semiTransparentBackground
            }) }), _jsxs("div", Object.assign({ className: styles.ldsRing }, { children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }))] })));
export default Loader;
