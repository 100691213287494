import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import _t from 'counterpart';
import moment from 'moment';
import { formatPercentage } from '../../helpers';
import classNames from 'classnames';
const CustomChartTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const currentPayload = payload[0].payload;
        return (_jsxs("div", Object.assign({ className: "custom-chart-tooltip" }, { children: [_jsxs("div", Object.assign({ className: "stat-row" }, { children: [_jsxs("span", Object.assign({ className: "label" }, { children: [_t('main.date'), ": "] })), _jsx("span", Object.assign({ className: "value" }, { children: moment(currentPayload.date).format('ll') }))] })), _jsxs("div", Object.assign({ className: "stat-row" }, { children: [_jsxs("span", Object.assign({ className: "label" }, { children: [_t('main.roi'), ": "] })), _jsx("span", Object.assign({ className: classNames('value', {
                                'value--green': parseFloat(currentPayload.value) > 0,
                                'value--red': parseFloat(currentPayload.value) < 0,
                            }) }, { children: formatPercentage(currentPayload.value) }))] }))] })));
    }
    return null;
};
export default React.memo(CustomChartTooltip);
