var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './Select.module.scss';
import * as SelectPrimitive from '@radix-ui/react-select';
import { isSet } from '../../helpers';
import SelectValue from 'components/Select/SelectValue';
import classNames from 'classnames';
import RxChevronDown from "../../icons/chevron-down.svg?url";
const Select = React.forwardRef((_a, forwardedRef) => {
    var { children, trigger, dropdownAlign = 'start', dropdownClassName = '' } = _a, props = __rest(_a, ["children", "trigger", "dropdownAlign", "dropdownClassName"]);
    return (_jsxs(SelectPrimitive.Root, Object.assign({}, props, { children: [_jsx(SelectPrimitive.Trigger, Object.assign({ ref: forwardedRef, asChild: isSet(trigger), className: classNames({
                    [styles.trigger]: !isSet(trigger),
                }) }, { children: _jsxs("div", { children: [isSet(trigger) ? trigger : _jsx(SelectValue, { style: { flex: 1 } }), !isSet(trigger) && (_jsx(_Fragment, { children: _jsx(SelectPrimitive.Icon, Object.assign({ className: styles.dropdownIcon }, { children: _jsx("img", { src: RxChevronDown, alt: '' }) })) }))] }) })), _jsx(SelectPrimitive.Portal, { children: _jsxs(SelectPrimitive.Content, Object.assign({ className: `${styles.content} ${dropdownClassName}`, align: dropdownAlign, sideOffset: 5, position: "popper", 
                    // select has bug with touchEvents. this prevents event from triggering anything behind dropdown
                    // https://github.com/radix-ui/primitives/issues/1658
                    ref: (ref) => ref === null || ref === void 0 ? void 0 : ref.addEventListener('touchend', (e) => e.preventDefault()) }, { children: [_jsx(SelectPrimitive.Arrow, { className: styles.arrow }), _jsx(SelectPrimitive.Viewport, Object.assign({ className: styles.viewport }, { children: children }))] })) })] })));
});
Select.displayName = 'Select';
export default React.memo(Select);
