import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { store, persistor } from 'wintrado-api';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import Main from './Main';
import { isIos, isAndroid } from '../helpers';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import usePageTracking from '../hooks/usePageTracking';
import useScript from '../hooks/useScript';
import config from 'config';
import { QueryClient, QueryClientProvider } from 'react-query';
import InstallCTA from 'components/main/InstallCTA';
const queryClient = new QueryClient();
const App = () => {
    usePageTracking();
    useEffect(() => {
        const { body } = document;
        if (isIos()) {
            body.classList.add('ios');
        }
        if (isAndroid()) {
            body.classList.add('android');
        }
        Modal.setAppElement('#app');
    }, []);
    if (config.customScriptInject) {
        useScript(config.customScriptInject);
    }
    return (_jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsxs(PersistGate, Object.assign({ loading: null, persistor: persistor }, { children: [_jsx(InstallCTA, {}), _jsx(Main, {})] })) })) })));
};
export default App;
