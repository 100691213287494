var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
const Button = (_a) => {
    var { children, id, onClick, type, blue = false, green = false, red = false, gray = false, black = false, transparentGray = false, fullWidth = false, mobileFullWidth = false, slim = false, disabled = false, inline = false, className, withImage = false, textStart = false, faded = false, inHeader = false, withDropdownToggle = false, asLink = false, loading = false } = _a, rest = __rest(_a, ["children", "id", "onClick", "type", "blue", "green", "red", "gray", "black", "transparentGray", "fullWidth", "mobileFullWidth", "slim", "disabled", "inline", "className", "withImage", "textStart", "faded", "inHeader", "withDropdownToggle", "asLink", "loading"]);
    const classname = classNames('button', styles.button, className, {
        [styles.buttonBlue]: blue,
        [styles.buttonGreen]: green,
        [styles.buttonRed]: red,
        [styles.buttonGray]: gray,
        [styles.buttonBlack]: black,
        [styles.buttonTransparentGray]: transparentGray,
        [styles.buttonFullWidth]: fullWidth,
        [styles.buttonSlim]: slim,
        [styles.buttonInline]: inline,
        [styles.buttonImg]: withImage,
        [styles.buttonTextStart]: textStart,
        [styles.buttonFaded]: faded,
        [styles.buttonMobileFullwidth]: mobileFullWidth,
        [styles.buttonInHeader]: inHeader,
        [styles.buttonWithDropdownToggle]: withDropdownToggle,
        [styles.buttonAsLink]: asLink,
    });
    return (_jsx("button", Object.assign({ id: id, type: type, disabled: disabled || loading, className: classname, onClick: onClick }, rest, { children: loading ? _jsx(Loader, { inline: true }) : children })));
};
export default React.memo(Button);
