import React, { PureComponent } from 'react';
import { LeaderboardHeader } from 'components/main/LeaderboardHeader';
import { LeaderboardList } from 'components/main/LeaderboardList';
import { bindActionCreators } from 'redux';
import { actions } from 'wintrado-api';
import { connect } from 'react-redux';
import Loader from 'components/Loader/Loader';
import CompetitionCountdown from 'components/main/CompetitionCountdown';
import { Desktop } from 'utils/responsiveHelpers';

const _t = require('counterpart');

class LeaderboardContent extends PureComponent {
	componentDidMount() {
		const { getLeaderboardRankings, shouldRefreshRankings } = this.props;
		getLeaderboardRankings();

		if (shouldRefreshRankings) {
			this.setRefreshInterval();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.shouldRefreshRankings !== this.props.shouldRefreshRankings) {
			if (this.props.shouldRefreshRankings) {
				this.setRefreshInterval();
			} else {
				this.clearRefreshInterval();
			}
		}
	}

	componentWillUnmount() {
		this.clearRefreshInterval();
	}

	setRefreshInterval = () => {
		this.interval = setInterval(this.props.refreshLeaderboardRankings, 5 * 1000);
	};

	clearRefreshInterval = () => {
		if (this.interval) {
			clearInterval(this.interval);
		}
	};

	renderContent = () => {
		const { ranks, user, currentCompetition } = this.props;

		return (
			<>
				<div className="header-wrapper">
					<LeaderboardHeader
						competition={currentCompetition}
						firstPrize={currentCompetition.firstPrizeInUSD}
						secondPrize={currentCompetition.secondPrizeInUSD}
						thirdPrize={currentCompetition.thirdPrizeInUSD}
					/>
					<Desktop>
						<CompetitionCountdown
							endDate={new Date(currentCompetition.end)}
							onCompetitionEnded={this.props.disableRefreshRankings}
							inline
						/>
					</Desktop>
				</div>
				<LeaderboardList competition={currentCompetition} ranks={ranks.data} nickname={user.nickname} />
			</>
		);
	};

	renderEmptyLabel = () => {
		return (
			<div className="leaderboard-message">
				<span>{_t.translate('leaderboard.empty_label')}</span>
			</div>
		);
	};

	render() {
		const { ranks } = this.props;

		if (!ranks || ranks.loading) {
			return <Loader transparentBackground />;
		}

		if (ranks.error) {
			return (
				<div className="leaderboard-message">
					<span>{_t.translate('error.unexpected_error_occurred')}</span>
				</div>
			);
		}

		return (
			<div className="leaderboard-content">
				{ranks.data.top.length !== 0 ? this.renderContent() : this.renderEmptyLabel()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		ranks: state.leaderboard.ranks,
		user: state.leaderboard.user,
		currentCompetition: state.leaderboard.currentCompetition.data.competition,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			getLeaderboardRankings: actions.getLeaderboardRankings,
			refreshLeaderboardRankings: actions.refreshLeaderboardRankings,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardContent);
