import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from 'wintrado-api';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import DesktopHeader from 'components/main/responsive/DesktopHeader';
import MobileHeader from 'components/main/responsive/MobileHeader';
import { bindActionCreators } from 'redux';

class Header extends React.Component {
	render() {
		return (
			<>
				<Desktop>
					<DesktopHeader {...this.props} />
				</Desktop>
				<Mobile>
					<MobileHeader {...this.props} />
				</Mobile>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		balance: state.user.balance,
		coinsBalance: state.user.coinsBalance,
		totalPL: selectors.totalProfitLossSelector(state),
		accountCurrency: state.user.accountCurrency,
		username: state.user.name,
		features: state.settings.features,
		leaderboardUser: state.leaderboard.user,
		equity: selectors.equitySelector(state),
		freeMargin: selectors.freeMarginSelector(state),
		marginCall: selectors.marginCallSelector(state),
		supportsCoins: selectors.supportsCoinsSelector(state),
		
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			showSideDrawer: actions.showSideDrawer,
		},
		dispatch
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
