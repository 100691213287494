import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import PopupMenu from 'components/global/PopupMenu';
import { getSizeLabel, getSizeOptions } from '../../helpers';
import coinIcon from '../../icons/coin.svg?url';
import _t from 'counterpart';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
const iconStyles = [
    { width: 15, height: 15 },
    { width: 13, height: 13 },
    { width: 11, height: 11 },
    { width: 9, height: 9 },
];
const MainMenuBottomStake = () => {
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const profitLossSelected = useAppSelector((state) => state.tradingScreen.profitLossSelected);
    const investSelectionVisible = useAppSelector((state) => state.tradingScreen.investSelectionVisible);
    const instruments = useAppSelector((state) => state.instruments);
    const instrumentsCount = Object.entries(instruments).length;
    const dispatch = useAppDispatch();
    if (!activeInstrument && instrumentsCount > 0) {
        throw new Error('Active instrument not loaded');
    }
    const sizes = instrumentsCount === 0 ? [] : getSizeOptions(activeInstrument);
    const handleSizeSelected = useCallback((value) => {
        dispatch(actions.selectTradeProfitLoss(value));
    }, [dispatch]);
    const getIconStyleForIndex = (index) => {
        if (index >= iconStyles.length) {
            return iconStyles[iconStyles.length - 1];
        }
        return iconStyles[index];
    };
    const selections = sizes.map((size, ind) => {
        const iconStyle = getIconStyleForIndex(ind);
        return (_jsxs("li", Object.assign({ onClick: () => handleSizeSelected(size.value) }, { children: [_jsx("img", { src: require('../../icons/profitLoss/profitLossFire.png'), alt: "fire-icon", className: "mobile-only", style: iconStyle }), _jsx("div", Object.assign({ className: "label" }, { children: size.text })), _jsxs("div", Object.assign({ className: "coins-wrapper" }, { children: [_jsx("img", { src: coinIcon, alt: "coin" }), _jsx("div", Object.assign({ className: "coins-label" }, { children: size.value * 100 }))] })), _jsx("img", { src: require('../../icons/profitLoss/profitLossFire.png'), alt: "fire-icon", className: "mobile-only", style: iconStyle })] }), `size-${size.value}`));
    });
    return (_jsx(PopupMenu, { className: "profitloss", mobileVisible: !investSelectionVisible, label: _t.translate('main.stake'), currentValue: instrumentsCount === 0 ? _t('main.none') : getSizeLabel(profitLossSelected, activeInstrument), items: selections }));
};
export default React.memo(MainMenuBottomStake);
