/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
'use strict';

var $protobuf = require('protobufjs/minimal');

// Common aliases
var $Reader = $protobuf.Reader,
	$Writer = $protobuf.Writer,
	$util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots['default'] || ($protobuf.roots['default'] = {});

$root.Quote = (function () {
	/**
	 * Properties of a Quote.
	 * @exports IQuote
	 * @interface IQuote
	 * @property {string} symbol Quote symbol
	 * @property {number} ask Quote ask
	 * @property {number} bid Quote bid
	 * @property {number|Long} timestamp Quote timestamp
	 * @property {string|null} [group] Quote group
	 */

	/**
	 * Constructs a new Quote.
	 * @exports Quote
	 * @classdesc Represents a Quote.
	 * @implements IQuote
	 * @constructor
	 * @param {IQuote=} [properties] Properties to set
	 */
	function Quote(properties) {
		if (properties)
			for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
				if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
	}

	/**
	 * Quote symbol.
	 * @member {string} symbol
	 * @memberof Quote
	 * @instance
	 */
	Quote.prototype.symbol = '';

	/**
	 * Quote ask.
	 * @member {number} ask
	 * @memberof Quote
	 * @instance
	 */
	Quote.prototype.ask = 0;

	/**
	 * Quote bid.
	 * @member {number} bid
	 * @memberof Quote
	 * @instance
	 */
	Quote.prototype.bid = 0;

	/**
	 * Quote timestamp.
	 * @member {number|Long} timestamp
	 * @memberof Quote
	 * @instance
	 */
	Quote.prototype.timestamp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

	/**
	 * Quote group.
	 * @member {string} group
	 * @memberof Quote
	 * @instance
	 */
	Quote.prototype.group = '';

	/**
	 * Creates a new Quote instance using the specified properties.
	 * @function create
	 * @memberof Quote
	 * @static
	 * @param {IQuote=} [properties] Properties to set
	 * @returns {Quote} Quote instance
	 */
	Quote.create = function create(properties) {
		return new Quote(properties);
	};

	/**
	 * Encodes the specified Quote message. Does not implicitly {@link Quote.verify|verify} messages.
	 * @function encode
	 * @memberof Quote
	 * @static
	 * @param {IQuote} message Quote message or plain object to encode
	 * @param {$protobuf.Writer} [writer] Writer to encode to
	 * @returns {$protobuf.Writer} Writer
	 */
	Quote.encode = function encode(message, writer) {
		if (!writer) writer = $Writer.create();
		writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.symbol);
		writer.uint32(/* id 2, wireType 1 =*/ 17).double(message.ask);
		writer.uint32(/* id 3, wireType 1 =*/ 25).double(message.bid);
		writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.timestamp);
		if (message.group != null && Object.hasOwnProperty.call(message, 'group'))
			writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.group);
		return writer;
	};

	/**
	 * Encodes the specified Quote message, length delimited. Does not implicitly {@link Quote.verify|verify} messages.
	 * @function encodeDelimited
	 * @memberof Quote
	 * @static
	 * @param {IQuote} message Quote message or plain object to encode
	 * @param {$protobuf.Writer} [writer] Writer to encode to
	 * @returns {$protobuf.Writer} Writer
	 */
	Quote.encodeDelimited = function encodeDelimited(message, writer) {
		return this.encode(message, writer).ldelim();
	};

	/**
	 * Decodes a Quote message from the specified reader or buffer.
	 * @function decode
	 * @memberof Quote
	 * @static
	 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
	 * @param {number} [length] Message length if known beforehand
	 * @returns {Quote} Quote
	 * @throws {Error} If the payload is not a reader or valid buffer
	 * @throws {$protobuf.util.ProtocolError} If required fields are missing
	 */
	Quote.decode = function decode(reader, length) {
		if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
		var end = length === undefined ? reader.len : reader.pos + length,
			message = new $root.Quote();
		while (reader.pos < end) {
			var tag = reader.uint32();
			switch (tag >>> 3) {
				case 1: {
					message.symbol = reader.string();
					break;
				}
				case 2: {
					message.ask = reader.double();
					break;
				}
				case 3: {
					message.bid = reader.double();
					break;
				}
				case 4: {
					message.timestamp = reader.int64();
					break;
				}
				case 5: {
					message.group = reader.string();
					break;
				}
				default:
					reader.skipType(tag & 7);
					break;
			}
		}
		if (!message.hasOwnProperty('symbol'))
			throw $util.ProtocolError("missing required 'symbol'", { instance: message });
		if (!message.hasOwnProperty('ask')) throw $util.ProtocolError("missing required 'ask'", { instance: message });
		if (!message.hasOwnProperty('bid')) throw $util.ProtocolError("missing required 'bid'", { instance: message });
		if (!message.hasOwnProperty('timestamp'))
			throw $util.ProtocolError("missing required 'timestamp'", { instance: message });
		return message;
	};

	/**
	 * Decodes a Quote message from the specified reader or buffer, length delimited.
	 * @function decodeDelimited
	 * @memberof Quote
	 * @static
	 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
	 * @returns {Quote} Quote
	 * @throws {Error} If the payload is not a reader or valid buffer
	 * @throws {$protobuf.util.ProtocolError} If required fields are missing
	 */
	Quote.decodeDelimited = function decodeDelimited(reader) {
		if (!(reader instanceof $Reader)) reader = new $Reader(reader);
		return this.decode(reader, reader.uint32());
	};

	/**
	 * Verifies a Quote message.
	 * @function verify
	 * @memberof Quote
	 * @static
	 * @param {Object.<string,*>} message Plain object to verify
	 * @returns {string|null} `null` if valid, otherwise the reason why it is not
	 */
	Quote.verify = function verify(message) {
		if (typeof message !== 'object' || message === null) return 'object expected';
		if (!$util.isString(message.symbol)) return 'symbol: string expected';
		if (typeof message.ask !== 'number') return 'ask: number expected';
		if (typeof message.bid !== 'number') return 'bid: number expected';
		if (
			!$util.isInteger(message.timestamp) &&
			!(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high))
		)
			return 'timestamp: integer|Long expected';
		if (message.group != null && message.hasOwnProperty('group'))
			if (!$util.isString(message.group)) return 'group: string expected';
		return null;
	};

	/**
	 * Creates a Quote message from a plain object. Also converts values to their respective internal types.
	 * @function fromObject
	 * @memberof Quote
	 * @static
	 * @param {Object.<string,*>} object Plain object
	 * @returns {Quote} Quote
	 */
	Quote.fromObject = function fromObject(object) {
		if (object instanceof $root.Quote) return object;
		var message = new $root.Quote();
		if (object.symbol != null) message.symbol = String(object.symbol);
		if (object.ask != null) message.ask = Number(object.ask);
		if (object.bid != null) message.bid = Number(object.bid);
		if (object.timestamp != null)
			if ($util.Long) (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
			else if (typeof object.timestamp === 'string') message.timestamp = parseInt(object.timestamp, 10);
			else if (typeof object.timestamp === 'number') message.timestamp = object.timestamp;
			else if (typeof object.timestamp === 'object')
				message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
		if (object.group != null) message.group = String(object.group);
		return message;
	};

	/**
	 * Creates a plain object from a Quote message. Also converts values to other types if specified.
	 * @function toObject
	 * @memberof Quote
	 * @static
	 * @param {Quote} message Quote
	 * @param {$protobuf.IConversionOptions} [options] Conversion options
	 * @returns {Object.<string,*>} Plain object
	 */
	Quote.toObject = function toObject(message, options) {
		if (!options) options = {};
		var object = {};
		if (options.defaults) {
			object.symbol = '';
			object.ask = 0;
			object.bid = 0;
			if ($util.Long) {
				var long = new $util.Long(0, 0, false);
				object.timestamp =
					options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
			} else object.timestamp = options.longs === String ? '0' : 0;
			object.group = '';
		}
		if (message.symbol != null && message.hasOwnProperty('symbol')) object.symbol = message.symbol;
		if (message.ask != null && message.hasOwnProperty('ask'))
			object.ask = options.json && !isFinite(message.ask) ? String(message.ask) : message.ask;
		if (message.bid != null && message.hasOwnProperty('bid'))
			object.bid = options.json && !isFinite(message.bid) ? String(message.bid) : message.bid;
		if (message.timestamp != null && message.hasOwnProperty('timestamp'))
			if (typeof message.timestamp === 'number')
				object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
			else
				object.timestamp =
					options.longs === String
						? $util.Long.prototype.toString.call(message.timestamp)
						: options.longs === Number
						? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber()
						: message.timestamp;
		if (message.group != null && message.hasOwnProperty('group')) object.group = message.group;
		return object;
	};

	/**
	 * Converts this Quote to JSON.
	 * @function toJSON
	 * @memberof Quote
	 * @instance
	 * @returns {Object.<string,*>} JSON object
	 */
	Quote.prototype.toJSON = function toJSON() {
		return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
	};

	/**
	 * Gets the default type url for Quote
	 * @function getTypeUrl
	 * @memberof Quote
	 * @static
	 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
	 * @returns {string} The default type url
	 */
	Quote.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
		if (typeUrlPrefix === undefined) {
			typeUrlPrefix = 'type.googleapis.com';
		}
		return typeUrlPrefix + '/Quote';
	};

	return Quote;
})();

$root.QuotesBatch = (function () {
	/**
	 * Properties of a QuotesBatch.
	 * @exports IQuotesBatch
	 * @interface IQuotesBatch
	 * @property {Array.<IQuote>|null} [quotes] QuotesBatch quotes
	 */

	/**
	 * Constructs a new QuotesBatch.
	 * @exports QuotesBatch
	 * @classdesc Represents a QuotesBatch.
	 * @implements IQuotesBatch
	 * @constructor
	 * @param {IQuotesBatch=} [properties] Properties to set
	 */
	function QuotesBatch(properties) {
		this.quotes = [];
		if (properties)
			for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
				if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
	}

	/**
	 * QuotesBatch quotes.
	 * @member {Array.<IQuote>} quotes
	 * @memberof QuotesBatch
	 * @instance
	 */
	QuotesBatch.prototype.quotes = $util.emptyArray;

	/**
	 * Creates a new QuotesBatch instance using the specified properties.
	 * @function create
	 * @memberof QuotesBatch
	 * @static
	 * @param {IQuotesBatch=} [properties] Properties to set
	 * @returns {QuotesBatch} QuotesBatch instance
	 */
	QuotesBatch.create = function create(properties) {
		return new QuotesBatch(properties);
	};

	/**
	 * Encodes the specified QuotesBatch message. Does not implicitly {@link QuotesBatch.verify|verify} messages.
	 * @function encode
	 * @memberof QuotesBatch
	 * @static
	 * @param {IQuotesBatch} message QuotesBatch message or plain object to encode
	 * @param {$protobuf.Writer} [writer] Writer to encode to
	 * @returns {$protobuf.Writer} Writer
	 */
	QuotesBatch.encode = function encode(message, writer) {
		if (!writer) writer = $Writer.create();
		if (message.quotes != null && message.quotes.length)
			for (var i = 0; i < message.quotes.length; ++i)
				$root.Quote.encode(message.quotes[i], writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim();
		return writer;
	};

	/**
	 * Encodes the specified QuotesBatch message, length delimited. Does not implicitly {@link QuotesBatch.verify|verify} messages.
	 * @function encodeDelimited
	 * @memberof QuotesBatch
	 * @static
	 * @param {IQuotesBatch} message QuotesBatch message or plain object to encode
	 * @param {$protobuf.Writer} [writer] Writer to encode to
	 * @returns {$protobuf.Writer} Writer
	 */
	QuotesBatch.encodeDelimited = function encodeDelimited(message, writer) {
		return this.encode(message, writer).ldelim();
	};

	/**
	 * Decodes a QuotesBatch message from the specified reader or buffer.
	 * @function decode
	 * @memberof QuotesBatch
	 * @static
	 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
	 * @param {number} [length] Message length if known beforehand
	 * @returns {QuotesBatch} QuotesBatch
	 * @throws {Error} If the payload is not a reader or valid buffer
	 * @throws {$protobuf.util.ProtocolError} If required fields are missing
	 */
	QuotesBatch.decode = function decode(reader, length) {
		if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
		var end = length === undefined ? reader.len : reader.pos + length,
			message = new $root.QuotesBatch();
		while (reader.pos < end) {
			var tag = reader.uint32();
			switch (tag >>> 3) {
				case 1: {
					if (!(message.quotes && message.quotes.length)) message.quotes = [];
					message.quotes.push($root.Quote.decode(reader, reader.uint32()));
					break;
				}
				default:
					reader.skipType(tag & 7);
					break;
			}
		}
		return message;
	};

	/**
	 * Decodes a QuotesBatch message from the specified reader or buffer, length delimited.
	 * @function decodeDelimited
	 * @memberof QuotesBatch
	 * @static
	 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
	 * @returns {QuotesBatch} QuotesBatch
	 * @throws {Error} If the payload is not a reader or valid buffer
	 * @throws {$protobuf.util.ProtocolError} If required fields are missing
	 */
	QuotesBatch.decodeDelimited = function decodeDelimited(reader) {
		if (!(reader instanceof $Reader)) reader = new $Reader(reader);
		return this.decode(reader, reader.uint32());
	};

	/**
	 * Verifies a QuotesBatch message.
	 * @function verify
	 * @memberof QuotesBatch
	 * @static
	 * @param {Object.<string,*>} message Plain object to verify
	 * @returns {string|null} `null` if valid, otherwise the reason why it is not
	 */
	QuotesBatch.verify = function verify(message) {
		if (typeof message !== 'object' || message === null) return 'object expected';
		if (message.quotes != null && message.hasOwnProperty('quotes')) {
			if (!Array.isArray(message.quotes)) return 'quotes: array expected';
			for (var i = 0; i < message.quotes.length; ++i) {
				var error = $root.Quote.verify(message.quotes[i]);
				if (error) return 'quotes.' + error;
			}
		}
		return null;
	};

	/**
	 * Creates a QuotesBatch message from a plain object. Also converts values to their respective internal types.
	 * @function fromObject
	 * @memberof QuotesBatch
	 * @static
	 * @param {Object.<string,*>} object Plain object
	 * @returns {QuotesBatch} QuotesBatch
	 */
	QuotesBatch.fromObject = function fromObject(object) {
		if (object instanceof $root.QuotesBatch) return object;
		var message = new $root.QuotesBatch();
		if (object.quotes) {
			if (!Array.isArray(object.quotes)) throw TypeError('.QuotesBatch.quotes: array expected');
			message.quotes = [];
			for (var i = 0; i < object.quotes.length; ++i) {
				if (typeof object.quotes[i] !== 'object') throw TypeError('.QuotesBatch.quotes: object expected');
				message.quotes[i] = $root.Quote.fromObject(object.quotes[i]);
			}
		}
		return message;
	};

	/**
	 * Creates a plain object from a QuotesBatch message. Also converts values to other types if specified.
	 * @function toObject
	 * @memberof QuotesBatch
	 * @static
	 * @param {QuotesBatch} message QuotesBatch
	 * @param {$protobuf.IConversionOptions} [options] Conversion options
	 * @returns {Object.<string,*>} Plain object
	 */
	QuotesBatch.toObject = function toObject(message, options) {
		if (!options) options = {};
		var object = {};
		if (options.arrays || options.defaults) object.quotes = [];
		if (message.quotes && message.quotes.length) {
			object.quotes = [];
			for (var j = 0; j < message.quotes.length; ++j)
				object.quotes[j] = $root.Quote.toObject(message.quotes[j], options);
		}
		return object;
	};

	/**
	 * Converts this QuotesBatch to JSON.
	 * @function toJSON
	 * @memberof QuotesBatch
	 * @instance
	 * @returns {Object.<string,*>} JSON object
	 */
	QuotesBatch.prototype.toJSON = function toJSON() {
		return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
	};

	/**
	 * Gets the default type url for QuotesBatch
	 * @function getTypeUrl
	 * @memberof QuotesBatch
	 * @static
	 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
	 * @returns {string} The default type url
	 */
	QuotesBatch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
		if (typeUrlPrefix === undefined) {
			typeUrlPrefix = 'type.googleapis.com';
		}
		return typeUrlPrefix + '/QuotesBatch';
	};

	return QuotesBatch;
})();

module.exports = $root;
