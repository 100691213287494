import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import rootSaga from '../sagas/rootSaga';
import { persistStore } from 'redux-persist';
import { enableBatching } from 'redux-batched-actions';
import { sagaErrorLogger } from '../utils/logger';
import * as Sentry from '@sentry/react';
// Store setup
const sagaMiddleware = createSagaMiddleware({
    onError: sagaErrorLogger,
});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer(action) {
        const actionsToOmit = [
            'POSITONS_RECEIVED',
            'QUOTES_RECEIVED',
            'QUOTE_RECEIVED',
            'APPEND_QUOTE_FOR_SYMBOL',
            'SET_QUOTES_FOR_SYMBOL',
            'SET_QUOTE_FOR_SYMBOL',
        ];
        return actionsToOmit.includes(action.type) ? null : action;
    },
    stateTransformer: (state) => {
        const sanitizedQuotes = Object.keys(state.quotes).reduce((quotesSanitized, quote) => {
            var _a;
            return (Object.assign(Object.assign({}, quotesSanitized), { [quote]: (_a = state.quotes[quote]) === null || _a === void 0 ? void 0 : _a.length }));
        }, {});
        return Object.assign(Object.assign({}, state), { quotes: sanitizedQuotes, user: Object.assign(Object.assign({}, state.user), { token: !state.user.token ? null : state.user.token.slice(0, 6) + '**' }) });
    },
});
const middleware = [applyMiddleware(sagaMiddleware)];
export const initialState = {
    tradingEnabled: false,
    greyLabelLogo: {
        data: null,
        isLoading: false,
    },
    error: {
        type: null,
        message: null,
        extra: null,
    },
    user: {
        id: null,
        name: null,
        balance: null,
        coinsBalance: null,
        // This is for the step-wise update and will be added to the actual balance
        // during position closing
        balanceAdjustmentFromPL: 0,
        pendingBalanceUpdate: null,
        accountCurrency: 'USD',
        /**
         * The access token as received during login.
         */
        token: null,
        /**
         * We use the presence of the refresh token to determine if the user is logged in.
         */
        refreshToken: null,
        referralURL: null,
        avatarUrl: null,
        supportedFeatures: null,
        communicationLanguage: 'en',
        mtLogin: null,
        wallet: null,
        loyaltyLevel: null,
    },
    settings: {
        language: 'en',
        chartType: 'simple',
        chartResolution: '1',
        sound: true,
        bellSound: true,
        soundstyle: null,
        dontShowInstallCTA: false,
        dontShowChromeInstallCTA: false,
        pwaInstallPrompt: null,
        features: {
            referFriendsEnabled: false,
            debugEnabled: false,
            supportChatEnabled: false,
        },
        instrumentToPreselect: null,
        isSidebarCollapsed: false,
    },
    devMenu: {
        enabled: false,
        useStagingGateway: false,
        clearAllDataOnLogoff: false,
    },
    positionsDrawer: {
        isOpen: false,
        preselectedTab: 'open-positions',
    },
    receivedOpenPositionsList: false,
    receivedPendingOrdersList: false,
    positions: {},
    quotes: {},
    orders: [],
    symbolsForTick: {},
    candles: {
        // Bucket size to aggregate candles into
        bucketSizeSeconds: 60,
        symbol: null,
        data: [],
        // ms timestamp storing start date
        start: null,
        end: null,
        loading: false,
    },
    instruments: {},
    instrumentsTimezone: '',
    instrumentsIsLoading: true,
    closedPositionsTotalPL: 0,
    closingAllPositions: false,
    showCloseAllProfitLoss: false,
    pendingPositionCloses: 0,
    // TODO: Possibly merge with the above position close all attributes?
    closingSinglePosition: false,
    showSinglePositionProfitLoss: false,
    activePosition: null,
    activePositionId: null,
    activeInstrument: null,
    activeInstrumentSubscriptionId: null,
    instrumentsBatchSubscriptionId: null,
    activeInstrumentSymbol: null,
    pendingPL: null,
    closingPositionsIds: [],
    soundbankLoading: false,
    soundbankLoadingPercentage: 0,
    // Connection state to track that we have received all initialization messages.
    connection: {
        instrumentsReceived: false,
        accountInfoReceived: true,
        primeQuotesReceived: false,
        tradingOptionsReceived: false,
        marginCallLevelReceived: false,
        ready: false,
        apiConnected: false,
        reconnectingIn: 0,
        isReconnecting: false,
        apiError: false,
        apiErrorReason: null,
        online: true,
        isAttemptingReconnect: false,
        initialConnectionFailed: false,
    },
    tradingScreen: {
        closePositionAfterSeconds: null,
        profitLossSelected: 0,
        investSelected: null,
        durationOffsetBottom: 0,
        durationOffsetLeft: 0,
        durationWidth: 0,
        profitLossOffsetBottom: 0,
        profitLossOffsetLeft: 0,
        profitLossWidth: 0,
        durationSelectionVisible: false,
        profitLossSelectionVisible: false,
        investSelectionVisible: true,
        investOffsetBottom: 0,
        investOffsetLeft: 0,
        investWidth: 0,
        overlayVisible: false,
        profitLossPerInstrument: {},
        rightSideWidget: 'positions',
    },
    proTradingScreen: {
        crosshairPriceSelection: {
            enabled: false,
            livePrice: null,
            selectedPrice: null,
        },
    },
    broker: {
        coinsExchangeRate: {
            loading: false,
            error: false,
            rate: null,
            effectiveRate: null,
            starsBonus: null,
        },
        messages: {
            loading: false,
            error: false,
            data: null,
            pagination: null,
            unreadCount: 0,
        },
    },
    accountHistory: {
        startDate: null,
        endDate: null,
        trades: [],
        profitLossInAccountCurrency: null,
    },
    events: {
        installCTAHiddenAt: null,
        sideDrawerShown: false,
        userRequestedLogoff: false,
        lowMarginModalShown: false,
        stopOutOccurred: false,
        loginTimedOut: false,
    },
    leaderboard: {
        ranks: null,
        user: null,
        currentCompetition: null,
        joinCompetition: null,
        showingCompetitionRankNotice: false,
    },
    trendQuotes: {},
    maintenanceMode: false,
    timeCalculations: {
        instrumentsValidTradingIntervals: {},
        positionsTimeLeft: {},
    },
    modals: {
        insufficientFunds: {
            shown: false,
            requiredAmount: null,
        },
        instrumentTrendExplainer: {
            shown: false,
        },
        depositWithdrawal: {
            shown: false,
        },
        marketClosedError: {
            shown: false,
        },
    },
    instrumentsTrend: {},
    config: {
        marginCallLevel: 100,
    },
    priceAlerts: [],
};
const devToolsActionSanitizer = (action) => action;
const devToolsStateSanitizer = (state) => {
    return Object.assign(Object.assign({}, state), { quotes: '<<OMITTED>>' });
};
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        devToolsActionSanitizer,
        devToolsStateSanitizer,
        actionsDenylist: [
            'POSITONS_RECEIVED',
            'QUOTES_RECEIVED',
            'QUOTE_RECEIVED',
            'SET_QUOTES',
            'APPEND_QUOTE_FOR_SYMBOL',
            'SET_QUOTES_FOR_SYMBOL',
            'SET_QUOTE_FOR_SYMBOL',
            'MERGE_QUOTES',
            'SET_CANDLES_DATA',
        ],
    })
    : compose;
// @ts-ignore
const store = createStore(enableBatching(rootReducer), initialState, composeEnhancers(...middleware, sentryReduxEnhancer));
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };
