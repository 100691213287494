import { ADD_ORDER, SET_ORDERS, UPDATE_ORDER } from '../../actions';
export default function ordersReducer(state, action) {
    switch (action.type) {
        case SET_ORDERS: {
            return Object.assign(Object.assign({}, state), { receivedPendingOrdersList: true, orders: action.payload.orders });
        }
        case ADD_ORDER:
            return Object.assign(Object.assign({}, state), { orders: [...state.orders, action.payload.order] });
        case UPDATE_ORDER: {
            const { order } = action.payload;
            const orders = state.orders.map((o) => (o.id === order.id ? order : o));
            return Object.assign(Object.assign({}, state), { orders: orders });
        }
        default:
            return state;
    }
}
