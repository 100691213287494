import React from 'react';
import { playClickSound } from '../../helpers';
import _t from 'counterpart';
import Select from 'components/Select/Select';
import SelectItem from 'components/Select/SelectItem';
import * as SelectPrimitive from '@radix-ui/react-select';
import RxChevronDown from '../../icons/chevron-down.svg?url';
import RxChevronUp from '../../icons/chevron-up.svg?url';

export default class SoundstyleSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			soundstyle: [
				{ name: _t.translate('settings.classic'), code: 'classic' },
				{ name: _t.translate('settings.africa'), code: 'africa' },
			],
		};
	}


	render() {
		const { soundstyle, handleChangeSoundstyle } = this.props;
		const currentSoundstyle = this.state.soundstyle.find((obj) => obj.code === soundstyle);

		return (
			<div className="dropupContainer">
				<Select
					open={this.state.isOpen}
					onOpenChange={(isOpen) => this.setState({
						isOpen
					}) }
					dropdownClassName="dropdown-menu chart-type"
					value={currentSoundstyle.code}
					onValueChange={handleChangeSoundstyle}
					dropdownAlign="center"
					trigger={
						<button onClick={playClickSound} type="button" className="btn dropdown-toggle">
							<span> {currentSoundstyle.name} </span>
							<SelectPrimitive.Icon className="SelectIcon">							
								{this.state.isOpen ? <img src={RxChevronUp} alt="up" /> : <img src={RxChevronDown} alt='down'/>}
							</SelectPrimitive.Icon>
						</button>
					}
				> 
					{this.state.soundstyle.map((item) => {
						return (
							<SelectItem className='dropdown-item' value={item.code} asChild={false}  key={item.code}>
								{item.name} 
							</SelectItem>
						);
					})}
				</Select>
			</div>
		);
	}
}
