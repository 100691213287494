import { initialState } from '../../store';
import { API_DISCONNECTED, API_ERROR, API_RECONNECT_REQUEST, API_RECONNECTING, INSTRUMENTS_UPDATED, PRIME_QUOTES_SET, RECEIVED_MARGIN_CALL_LEVEL, RECEIVED_TRADING_OPTIONS, SET_CONNECTION_READY, SET_IS_ONLINE, SET_MAINTENANCE_MODE, } from '../../actions';
export default function connectionReducer(state, action) {
    switch (action.type) {
        case INSTRUMENTS_UPDATED: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { instrumentsReceived: true }) });
        }
        case PRIME_QUOTES_SET: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { primeQuotesReceived: true }) });
        }
        case RECEIVED_TRADING_OPTIONS: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { tradingOptionsReceived: true }) });
        }
        case RECEIVED_MARGIN_CALL_LEVEL: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { marginCallLevelReceived: true }) });
        }
        case SET_CONNECTION_READY: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { ready: action.payload.ready }) });
        }
        case API_ERROR:
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { apiError: true, apiErrorReason: action.payload.reason, initialConnectionFailed: action.payload.shouldShowErrorScreen }) });
        case API_RECONNECT_REQUEST:
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { apiError: false, apiErrorReason: null, initialConnectionFailed: false, isAttemptingReconnect: true }) });
        case API_RECONNECTING:
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { reconnectingIn: action.payload.seconds, isReconnecting: action.payload.isReconnecting }) });
        case SET_IS_ONLINE: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { online: action.payload.isOnline }) });
        }
        case API_DISCONNECTED:
            // Reset state after logoff/disconnect from API
            return Object.assign(Object.assign({}, initialState), { maintenanceMode: state.maintenanceMode, releaseVersion: state.releaseVersion, settings: state.devMenu.clearAllDataOnLogoff ? initialState.settings : state.settings, user: Object.assign(Object.assign({}, initialState.user), { token: state.user.token, refreshToken: state.user.refreshToken }), tradingScreen: Object.assign(Object.assign({}, initialState.tradingScreen), { closePositionAfterSeconds: state.tradingScreen.closePositionAfterSeconds, investSelected: state.tradingScreen.investSelected }), devMenu: state.devMenu, events: Object.assign(Object.assign({}, initialState.events), { userRequestedLogoff: state.events.userRequestedLogoff }) });
        case SET_MAINTENANCE_MODE:
            return Object.assign(Object.assign({}, state), { maintenanceMode: true });
        default:
            return state;
    }
}
