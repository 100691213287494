var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
const TextArea = React.forwardRef((_a, ref) => {
    var { error } = _a, rest = __rest(_a, ["error"]);
    return (_jsxs("div", Object.assign({ className: "form__fieldset" }, { children: [_jsx("label", Object.assign({ className: "form__label", htmlFor: rest.name }, { children: rest.label })), _jsx("textarea", Object.assign({ ref: ref }, rest)), _jsx("label", Object.assign({ htmlFor: rest.name, className: "error" }, { children: error }))] })));
});
export default React.memo(TextArea);
