import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button from 'ui/components/Button/Button';
import _t from 'counterpart';
import { hideInsufficientFundsModal } from 'wintrado-api/src/actions';
import styles from './ModalInsufficientFunds.module.scss';
import Modal from '../Modal';
import ModalFooterButtons from 'components/ModalFooterButtons';
import { freeMarginSelector } from 'wintrado-api/src/selectors';
import InsufficientBalanceAmounts from 'components/InsufficientBalanceAmounts';
import { useAppDispatch, useAppSelector } from 'wintrado-api';
const ModalInsufficientFunds = () => {
    const { accountCurrency } = useAppSelector((state) => state.user);
    const freeMargin = useAppSelector(freeMarginSelector);
    const { shown: isModalOpen, requiredAmount } = useAppSelector((state) => state.modals.insufficientFunds);
    const dispatch = useAppDispatch();
    const handleCloseModal = () => {
        dispatch(hideInsufficientFundsModal());
    };
    return (_jsx(Modal, Object.assign({ title: _t.translate('modal.insufficient_funds.title'), onRequestClose: handleCloseModal, isOpen: isModalOpen, className: `${styles.container} insufficient-funds` }, { children: _jsxs("div", Object.assign({ className: styles.insufficientFunds }, { children: [_jsx("p", Object.assign({ className: "description" }, { children: _t.translate('modal.insufficient_funds.description') })), _jsx("div", Object.assign({ className: styles.balanceAmountsWrapper }, { children: _jsx(InsufficientBalanceAmounts, { currentFreeMargin: freeMargin, requiredAmount: requiredAmount, currency: accountCurrency }) })), _jsx(ModalFooterButtons, { children: _jsx(Button, Object.assign({ variant: "primary", onClick: handleCloseModal, to: "/deposit", className: styles.button }, { children: _t.translate('page.deposit') })) })] })) })));
};
export default React.memo(ModalInsufficientFunds);
