import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { formatCurrency } from 'utils/currency';
import PopupMenu from 'components/global/PopupMenu';
import _t from 'counterpart';
import { actions, useAppDispatch, useAppSelector } from 'wintrado-api';
import { isSet } from '../../helpers';
const investIcons = [
    require('../../icons/invest/level4.png'),
    require('../../icons/invest/level3.png'),
    require('../../icons/invest/level2.png'),
    require('../../icons/invest/level1.png'),
];
const MainMenuBottomMaxRisk = () => {
    const maxRisks = useAppSelector((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.maxRisks; });
    const balance = useAppSelector((state) => state.user.balance);
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const investSelected = useAppSelector((state) => state.tradingScreen.investSelected);
    const investSelectionVisible = useAppSelector((state) => state.tradingScreen.investSelectionVisible);
    const dispatch = useAppDispatch();
    const handleHideSelection = useCallback(() => {
        dispatch(actions.hideOverlay());
    }, [dispatch]);
    const onChangeInvest = useCallback((invest) => {
        dispatch(actions.selectTradeInvest(invest));
    }, [balance, dispatch]);
    const renderItems = useMemo(() => {
        return (maxRisks || [])
            .slice()
            .reverse()
            .map((option, index) => {
            var _a;
            const investIcon = (_a = investIcons[index]) !== null && _a !== void 0 ? _a : investIcons[investIcons.length - 1];
            return (_jsxs("li", Object.assign({ onClick: () => onChangeInvest(option) }, { children: [_jsx("img", { src: investIcon, alt: "invest-icon", className: "mobile-only" }), _jsx("div", Object.assign({ className: "label" }, { children: _jsx("strong", { children: formatCurrency(option, accountCurrency) }) })), _jsx("img", { src: investIcon, alt: "invest-icon", className: "mobile-only" })] }), `invest-${option}`));
        });
    }, [accountCurrency, balance, maxRisks, onChangeInvest]);
    // FIXME: Better loader handling
    if (!isSet(maxRisks) || investSelected === null || balance === null) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(PopupMenu, { className: "invest", mobileVisible: investSelectionVisible, label: _t.translate('main.max_risk'), currentValue: formatCurrency(investSelected, accountCurrency), items: renderItems, onDismiss: handleHideSelection }));
};
export default React.memo(MainMenuBottomMaxRisk);
