import React, { Component } from 'react';
import { trading, texts } from '../colors';

export default class AskBidPrices extends Component {
	render() {
		const { ask, bid, instrument } = this.props;
		const textStyle = { color: texts.primary, lineHeight: 14, fontSize: 12 };

		const decimals = instrument ? instrument.decimals : 5;

		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<svg height="10" width="10" style={{ marginRight: 3 }}>
						<circle cx="5" cy="5" r="5" fill={trading.buy} />
					</svg>
					<span>{ask != null ? Number(ask).toFixed(decimals) : 0}</span>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 3 }}>
					<svg height="10" width="10" style={{ marginRight: 3 }}>
						<circle cx="5" cy="5" r="5" fill={trading.sell} />
					</svg>
					<span>{bid != null ? Number(bid).toFixed(decimals) : 0}</span>
				</div>
			</div>
		);
	}
}
