import { useMediaQuery } from 'react-responsive';

export const useIsDesktop = () => {
	return useMediaQuery({ minWidth: 1121 });
};

export const useIsMobile = () => {
	return useMediaQuery({ maxWidth: 1120 });
};

export const Desktop = ({ children }) => {
	const isDesktop = useIsDesktop();
	return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
	const isMobile = useIsMobile();
	return isMobile ? children : null;
};
