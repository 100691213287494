import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import styles from './RangeInputWithLabel.module.scss';
import RangePicker from 'components/RangePicker/RangePicker';
import Input from 'components/form/Input';
import Button from 'components/Button/Button';
import _t from 'counterpart';
const RangeInputWithLabel = ({ label, step, min, max, initialValues, onChange }) => {
    var _a, _b;
    const [fromValue, setFromValue] = useState((_a = initialValues === null || initialValues === void 0 ? void 0 : initialValues.from) !== null && _a !== void 0 ? _a : min);
    const [toValue, setToValue] = useState((_b = initialValues === null || initialValues === void 0 ? void 0 : initialValues.to) !== null && _b !== void 0 ? _b : max);
    useEffect(() => {
        if (fromValue === min && toValue === max) {
            onChange(null);
        }
        else {
            onChange({
                from: fromValue,
                to: toValue,
            });
        }
    }, [fromValue, toValue, onChange, min, max]);
    const handleFromChanged = useCallback((e) => {
        const value = Number.parseFloat(e.target.value);
        setFromValue(value);
    }, []);
    const handleToChanged = useCallback((e) => {
        const value = Number.parseFloat(e.target.value);
        setToValue(value);
    }, []);
    const handleRangePickerChanged = useCallback((value) => {
        setFromValue(value[0]);
        setToValue(value[1]);
    }, []);
    const handleClearFilter = useCallback(() => {
        setFromValue(min);
        setToValue(max);
    }, [max, min]);
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsxs("div", Object.assign({ className: styles.labelClearContainer }, { children: [_jsx("span", Object.assign({ className: styles.label }, { children: label })), _jsx(Button, Object.assign({ className: styles.clearButton, onClick: handleClearFilter, asLink: true }, { children: _t('main.clear') }))] })), _jsx(RangePicker, { step: step, min: min, max: max, values: [fromValue, toValue], onChange: handleRangePickerChanged }), _jsxs("div", Object.assign({ className: styles.inputsContainer }, { children: [_jsx(Input, { condensed: true, small: true, textCenter: true, type: "number", value: fromValue, onChange: handleFromChanged, min: min, max: max }), _jsx("span", Object.assign({ className: styles.dash }, { children: "\u2014" })), _jsx(Input, { condensed: true, small: true, textCenter: true, type: "number", value: toValue, onChange: handleToChanged, min: min, max: max })] }))] })));
};
export default React.memo(RangeInputWithLabel);
