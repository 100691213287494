import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Button from 'ui/components/Button/Button';
import Modal from '../Modal';
import { selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import _t from 'counterpart';
import { hideMarketClosedErrorModal } from 'wintrado-api/src/actions';
import styles from './ModalMarketClosedError.module.scss';
const ModalMarketClosedError = () => {
    var _a, _b;
    const isModalOpen = useAppSelector((state) => state.modals.marketClosedError).shown;
    const activeInstrumentWithIntervals = useAppSelector(selectors.activeInstrumentWithIntervalsSelector);
    const dispatch = useAppDispatch();
    const handleCloseModal = () => {
        dispatch(hideMarketClosedErrorModal());
    };
    if (!activeInstrumentWithIntervals)
        return null;
    const closedUntil = (_b = (_a = activeInstrumentWithIntervals === null || activeInstrumentWithIntervals === void 0 ? void 0 : activeInstrumentWithIntervals.validTradingIntervals) === null || _a === void 0 ? void 0 : _a.next) === null || _b === void 0 ? void 0 : _b.start.toLocaleString(_t.getLocale(), {
        weekday: 'long',
        hour: '2-digit',
        minute: '2-digit',
    });
    return (_jsx(Modal, Object.assign({ title: _t.translate('modal.market_closed_error', {
            instrument: activeInstrumentWithIntervals.symbol,
            closed_until: closedUntil,
        }), onRequestClose: handleCloseModal, isOpen: isModalOpen, className: styles.container }, { children: _jsx("div", Object.assign({ className: styles.holder }, { children: _jsx("div", Object.assign({ className: styles.actions }, { children: _jsx("div", Object.assign({ className: styles.holder }, { children: _jsx(Button, Object.assign({ variant: "primary", onClick: handleCloseModal, className: styles.button }, { children: _t.translate('modal.okay') })) })) })) })) })));
};
export default React.memo(ModalMarketClosedError);
