import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import successIcon from '../../icons/success_circle.svg?url';
import errorIcon from '../../icons/error_circle.svg?url';
import AnyLink from 'components/global/AnyLink';
import Button from 'components/Button/Button';
const Notice = ({ type, title, subtitle, buttonLabel, buttonClickHandler, buttonNavigateTo, }) => {
    const isSuccess = useMemo(() => type === 'success', [type]);
    return (_jsxs("div", Object.assign({ className: "notice" }, { children: [isSuccess && _jsx("img", { src: successIcon, alt: "success-icon", className: "notice__icon" }), !isSuccess && _jsx("img", { src: errorIcon, alt: "error-icon", className: "notice__icon" }), _jsx("h2", Object.assign({ className: "notice__title" }, { children: title })), _jsx("p", Object.assign({ className: "notice__subtitle" }, { children: subtitle })), buttonNavigateTo && (_jsx(AnyLink, Object.assign({ to: buttonNavigateTo, className: "notice__button" }, { children: _jsx(Button, Object.assign({ green: type === 'success' }, { children: buttonLabel })) }))), buttonClickHandler && (_jsx(Button, Object.assign({ green: type === 'success', onClick: buttonClickHandler, className: "notice__button" }, { children: buttonLabel })))] })));
};
export default React.memo(Notice);
