import React from 'react';
import { PageLayout } from 'components/global/PageLayout';
import _t from 'counterpart';
import { MessageItem } from 'components/MessageItem';
import { actions } from 'wintrado-api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from 'components/Loader/Loader';
import { ErrorScreen } from 'components/global/ErrorScreen';
import InfiniteScroll from 'react-infinite-scroll-component';
import InlineLoader from 'components/InlineLoader';
import NoMessages from 'components/NoMessages';

class PageMessages extends React.PureComponent {
	componentDidMount() {
		this.props.fetchMessages();
	}

	renderMessagesContent = () => {
		const { messages } = this.props;

		if (!messages || messages.loading) {
			return <Loader />;
		}
		if (messages.data !== null && messages.data.length > 0) {
			const { data, pagination } = messages;
			return (
				<PageLayout className="messages" title={_t('page.messages')}>
					<div id="scroll-controller" className="messages-wrapper">
						<InfiniteScroll
							next={this.props.fetchMessagesNextPage}
							hasMore={pagination.total > pagination.offset + pagination.limit}
							loader={<InlineLoader />}
							dataLength={data.length}
							scrollableTarget="scroll-controller"
						>
							{data.map((message) => (
								<MessageItem
									key={message.id}
									subject={message.subject}
									id={message.id}
									content={message.text}
									unread={message.readAt === null}
									date={new Date(message.createdAt)}
								/>
							))}
						</InfiniteScroll>
					</div>
				</PageLayout>
			);
		}
		if (messages.data !== null && messages.data.length === 0) {
			return <NoMessages />;
		}
		return <ErrorScreen />;
	};

	render() {
		return this.renderMessagesContent() ;
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		messages: state.broker.messages,
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			fetchMessages: actions.fetchMessages,
			fetchMessagesNextPage: actions.fetchMessagesNextPage,
		},
		dispatch
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(PageMessages);
