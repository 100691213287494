import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import _t from 'counterpart';
import { formatCurrency } from 'utils/currency';
import PieChartWithLegend from 'components/copyTrading/PieChartWithLegend';
import StrategyPerformanceAreaChart from 'components/copyTrading/StrategyPerformanceAreaChart';
import arrowRightIcon from '../../icons/arrow_right.svg?url';
import AnyLink from 'components/global/AnyLink';
const TradingStrategyRow = ({ strategy }) => {
    return (_jsx(AnyLink, Object.assign({ to: `/copy-trading/strategies/${strategy.id}`, className: "trading-strategy-row__link" }, { children: _jsxs("div", Object.assign({ className: "trading-strategy-row" }, { children: [_jsxs("div", Object.assign({ className: "basic-info" }, { children: [_jsx("h6", Object.assign({ className: "name" }, { children: strategy.name })), _jsx("span", Object.assign({ className: "copiers" }, { children: _t('copy_trading.copiers', { count: strategy.noFollowers }) }))] })), _jsx("div", Object.assign({ className: "stats-wrapper" }, { children: _jsxs("div", Object.assign({ className: "stats" }, { children: [_jsxs("div", Object.assign({ className: "stats__item" }, { children: [_jsxs("span", Object.assign({ className: "label" }, { children: [_t('copy_trading.min_investment'), ":"] })), _jsx("span", Object.assign({ className: "value" }, { children: formatCurrency(Number(strategy.minimumInvest), strategy.currency) }))] })), _jsxs("div", Object.assign({ className: "stats__item" }, { children: [_jsxs("span", Object.assign({ className: "label" }, { children: [_t('copy_trading.performance_since_start'), ":"] })), _jsx("span", Object.assign({ className: "value" }, { children: `${strategy.performancePercent}%` }))] }))] })) })), _jsx("div", Object.assign({ className: "breakdown-chart" }, { children: _jsx(PieChartWithLegend, { data: strategy.structure }) })), _jsx("div", Object.assign({ className: "performance-chart" }, { children: _jsx(StrategyPerformanceAreaChart, { data: strategy.performanceChart, performancePercent: strategy.performancePercent, minimal: true }) })), _jsx("div", Object.assign({ className: "icon" }, { children: _jsx("img", { src: arrowRightIcon, alt: "arrow-right-icon" }) }))] })) })));
};
export default React.memo(TradingStrategyRow);
