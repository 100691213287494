import { useMemo, useState } from 'react';
import { StrategySortBy } from 'wintrado-api';
const initialFilters = {
    minInvestmentAmount: null,
    roiSinceStart: null,
    followers: null,
};
const useStrategyFilters = () => {
    const [searchValue, setSearchValue] = useState('');
    const [sortValue, setSortValue] = useState(StrategySortBy.Date);
    const [rangeFilters, setRangeFilters] = useState(initialFilters);
    const filters = useMemo(() => ({
        searchValue,
        sortValue,
        rangeFilters,
    }), [searchValue, sortValue, rangeFilters]);
    return [filters, setSearchValue, setSortValue, setRangeFilters];
};
export default useStrategyFilters;
