export const ApiMessages = {
    bankSwitch: (bankName, currency) => ({
        messageType: 'BankSwitch',
        bankName,
        currency,
    }),
    requestHistoryQuotes: (symbol, start, end, resolution = '0') => ({
        messageType: 'HistoryQuotesRequest',
        symbol,
        start,
        end,
        resolution,
    }),
    setDisplayName: (displayName) => ({
        messageType: 'SetDisplayNameRequest',
        displayName,
    }),
    requestLogin: (token, version) => ({
        messageType: 'Login',
        token,
        version,
    }),
    pong: () => ({
        messageType: 'Pong',
    }),
    requestPositionCloseAll: () => ({
        messageType: 'PositionCloseAllRequest',
    }),
    requestPositionCloseSingle: (positionId) => ({
        messageType: 'PositionCloseRequest',
        positionId,
    }),
    requestOpenPositions: () => ({
        messageType: 'OpenPositionsListRequest',
    }),
    setActiveSymbol: (symbol) => ({
        messageType: 'SetActiveSymbolRequest',
        symbol,
    }),
    openPosition: (requestId, symbol, side, size, duration, invest) => ({
        messageType: 'PositionOpenRequest',
        requestId,
        symbol,
        side,
        size,
        duration,
        invest,
    }),
    getAccountHistory: () => ({
        messageType: 'AccountHistoryRequest',
    }),
    getCoinsBalance: () => ({
        messageType: 'CoinsBalanceRequest',
    }),
    getTrendQuotes: () => ({
        messageType: 'TrendQuotesRequest',
        bucketSizeMins: 60,
        timeRange: 86400,
    }),
    subscribeToQuotes: (symbols, format) => ({
        messageType: 'SubscribeQuotesRequest',
        symbols,
        format,
    }),
    subscribeToBatchQuotes: (symbols, format, intervalMs = 3000) => ({
        messageType: 'SubscribeQuotesBatchRequest',
        symbols,
        format,
        intervalMs,
    }),
    unsubscribeFromQuotes: (subscriptionId) => ({
        messageType: 'CancelQuotesRequest',
        subscriptionId,
    }),
    unsubscribeFromBatchQuotes: (subscriptionId) => ({
        messageType: 'CancelQuotesBatchRequest',
        subscriptionId,
    }),
};
