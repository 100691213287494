import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { formatCurrency } from 'utils/currency';
import idIcon from '../icons/main/id.svg?url';
import clockIcon from '../icons/main/clock.svg?url';
import starIcon from '../icons/main/star.svg?url';
import timeIcon from '../icons/main/time.svg?url';
import warningIcon from '../icons/main/warning.svg?url';
import dollarIcon from '../icons/main/dollar.svg?url';
import { getDurationLabel, getSizeLabel } from '../helpers';
import moment from 'moment';
const _t = require('counterpart');
const PositionStats = React.memo(({ position, instrument, accountCurrency, showId = false }) => {
    const { openedAt, closePositionAfterSeconds, size, positionId, invest, commission } = position;
    const sizeText = getSizeLabel(size, instrument);
    const durationLabel = getDurationLabel(closePositionAfterSeconds !== null && closePositionAfterSeconds !== void 0 ? closePositionAfterSeconds : null);
    return (_jsxs("div", Object.assign({ className: "position-stats" }, { children: [showId && (_jsxs("div", Object.assign({ className: "position-stats__item" }, { children: [_jsx("img", { src: idIcon, alt: "id" }), _jsx("span", { children: positionId })] }))), _jsxs("div", Object.assign({ className: "position-stats__item" }, { children: [_jsx("img", { src: timeIcon, alt: "calendar" }), _jsx("span", { children: `${moment(openedAt).format('HH:mm:ss')} ` })] })), _jsxs("div", Object.assign({ className: "position-stats__item clock" }, { children: [_jsx("img", { src: clockIcon, alt: "clock" }), _jsx("span", { children: durationLabel })] })), sizeText && (_jsxs("div", Object.assign({ className: "position-stats__item" }, { children: [_jsx("img", { src: starIcon, alt: "point" }), _jsx("span", { children: `${sizeText} ` })] }))), _jsxs("div", Object.assign({ className: "position-stats__item" }, { children: [_jsx("img", { src: warningIcon }), _jsx("span", { children: formatCurrency(invest, accountCurrency) })] })), _jsxs("div", Object.assign({ className: "position-stats__item" }, { children: [_jsx("img", { src: dollarIcon }), _jsx("span", { children: commission ? formatCurrency(commission, accountCurrency) : '-' })] }))] })));
});
PositionStats.displayName = 'PositionStats';
export default PositionStats;
