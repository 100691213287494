var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { isSet } from '../../helpers';
import AnyLink from 'components/AnyLink/AnyLink';
const ButtonBase = React.forwardRef((_a, ref) => {
    var { to, onClick, className, children, disabled = false, activeClassName, loading = false } = _a, props = __rest(_a, ["to", "onClick", "className", "children", "disabled", "activeClassName", "loading"]);
    if (isSet(to)) {
        return (_jsx(AnyLink, Object.assign({ ref: ref, to: to, onClick: onClick, disabled: disabled || loading, className: className, activeClassName: activeClassName }, props, { children: children })));
    }
    else {
        return (
        // @ts-expect-error button
        _jsx("button", Object.assign({ ref: ref, onClick: onClick, disabled: disabled || loading, className: className }, props, { children: children })));
    }
});
ButtonBase.displayName = "ButtonBase";
export default React.memo(ButtonBase);
