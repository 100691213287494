import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _t from 'counterpart';
import { isSet } from '../helpers';
import RiseFallIndicator, { Direction } from 'components/RiseFallIndicator';
import InstrumentQuoteIndicator from 'components/InstrumentQuoteIndicator';
import { Desktop, Mobile } from 'utils/responsiveHelpers';
import ChartControls from 'components/global/ChartControls';
import MobileInstrumentTrendIndicator from 'components/MobileInstrumentTrendIndicator';
import { selectors } from 'wintrado-api';
import InstrumentIcon from 'components/InstrumentIcon/InstrumentIcon';

class BigExchangeComp extends React.Component {
	render() {
		if (!this.props.activeInstrument || !this.props.quotes) {
			return null;
		}

		const quote = this.props.quotes[this.props.quotes.length - 1] || {
			ask: 0,
			bid: 0,
			value: 0,
		};
		const prevQuote = this.props.quotes[this.props.quotes.length - 2] || {
			ask: 0,
			bid: 0,
			value: 0,
		};
		const rising = prevQuote.bid < quote.bid;
		const instrument = this.props.activeInstrument;

		return (
			<div className="chart__meta" id="chartMeta">
				<div className="info-left">
					<Mobile>
						<div className="trend-container">
							{isSet(this.props.activeInstrumentTrend) && <MobileInstrumentTrendIndicator />}
							{!isSet(this.props.activeInstrumentTrend) && (
								<div className="rise-fall-wrapper">
									<RiseFallIndicator direction={rising ? Direction.Up : Direction.Down} />
								</div>
							)}
						</div>
					</Mobile>
					<Desktop>
						<div className="chart-controls-desktop">
							<ChartControls />
						</div>
					</Desktop>
					<div className="trade-instrument-info" onClick={this.props.onClickInstrument}>
						<div className="icon-holder">
							<div className="trade-instrument-icon">
								<InstrumentIcon symbol={instrument.symbol} imgUrl={instrument.iconUrl} />
							</div>
						</div>
						<span className="trade-instrument-name">{instrument.symbol}</span>
					</div>
				</div>
				<div className="prices-mobile">
					{quote && (
						<div className="buy-sell-prices">
							<span className="trade-instrument-name">{instrument.symbol}</span>
							<div className="desktop">
								<div className="amount-label">
									<span className="amount-label__title">{_t.translate('main.buy')}</span>
									<span className="amount-label__price amount-label__price--green">
										{quote.ask.toFixed(instrument.decimals)}
									</span>
								</div>
								<div className="amount-label">
									<span className="amount-label__title">{_t.translate('main.sell')}</span>
									<span className="amount-label__price amount-label__price--red">
										{quote.bid.toFixed(instrument.decimals)}
									</span>
								</div>
							</div>
							<div className="instrument-quote-container">
								<InstrumentQuoteIndicator symbol={instrument.symbol} showRiseFall />
							</div>
						</div>
					)}
					<div className="buy-sell-mobile">
						<span className="buy">{quote.ask.toFixed(instrument.decimals)}</span>
						<span className="sell">{quote.bid.toFixed(instrument.decimals)}</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		activeInstrument: state.activeInstrument,
		quotes: state.activeInstrument ? state.quotes[state.activeInstrument.symbol] : null,
		activePosition: state.activePosition,
		activeInstrumentTrend: selectors.activeInstrumentTrend(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BigExchangeComp);
