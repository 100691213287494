import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import PopupMenu from 'components/global/PopupMenu';
import classNames from 'classnames';
import _t from 'counterpart';
import { actions, instrumentsHelpers, selectors, useAppDispatch, useAppSelector } from 'wintrado-api';
import { getDurationLabel } from '../../helpers';
const MainMenuBottomDuration = () => {
    const activeInstrumentWithValidTradingIntervals = useAppSelector(selectors.activeInstrumentWithIntervalsSelector);
    const instrumentsCount = useAppSelector((state) => Object.entries(state.instruments).length);
    const closePositionAfterSeconds = useAppSelector((state) => state.tradingScreen.closePositionAfterSeconds);
    const positionDurations = useAppSelector((state) => { var _a; return (_a = state.config) === null || _a === void 0 ? void 0 : _a.positionDurations; });
    const dispatch = useAppDispatch();
    const sortedPositionDurations = [...(positionDurations || [])].sort((a, b) => b - a);
    const buttons = useMemo(() => {
        if (instrumentsCount === 0 || !activeInstrumentWithValidTradingIntervals) {
            return null;
        }
        return sortedPositionDurations.map((duration) => {
            const validDuration = duration === 0
                ? true
                : instrumentsHelpers.isValidTradingDurationForInstrument(activeInstrumentWithValidTradingIntervals, duration);
            return (_jsx("li", Object.assign({ onClick: () => {
                    if (validDuration) {
                        dispatch(actions.selectTradeDuration(duration));
                    }
                }, className: classNames({ disabled: !validDuration }) }, { children: _jsx("div", Object.assign({ className: "duration-label" }, { children: _jsx("strong", { children: getDurationLabel(duration) }) })) }), duration.toString()));
        });
    }, [activeInstrumentWithValidTradingIntervals, dispatch, sortedPositionDurations, instrumentsCount]);
    return (_jsx(PopupMenu, { className: "period", label: _t.translate('main.duration'), currentValue: instrumentsCount === 0 || closePositionAfterSeconds === null
            ? _t('main.none')
            : getDurationLabel(closePositionAfterSeconds), items: buttons }));
};
export default MainMenuBottomDuration;
