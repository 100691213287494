import React from 'react';
import _t from 'counterpart';
import config from 'config';
import AnyLink from 'components/global/AnyLink';
import { wintradoHomeUrl } from '../../constants';

const Footer = () => (
	<footer className="footer">
		<div className="footer__lost">{_t.translate('main.copyright')}</div>
		<div className="footer__links">
			{!!config.crm.regulationURL && (
				<a href={config.crm.regulationURL} target="_blank" rel="noreferrer">
					{_t.translate('main.regulation')}
				</a>
			)}
			{!!config.crm.regulationURL && !!config.crm.faqURL && <span>|</span>}
			{!!config.crm.faqURL && (
				<a href={config.crm.faqURL} target="_blank" rel="noreferrer">
					{_t.translate('main.faqs')}
				</a>
			)}
		</div>
		<div className="powered-by">
			<span className="powered-by__label">{_t('main.powered_by')} &nbsp;</span>
			<AnyLink className="powered-by__link" to={wintradoHomeUrl}>
				Wintrado
			</AnyLink>
		</div>
	</footer>
);

export default Footer;
