import * as _t from 'counterpart';

const localeDefaults = {
  counterpart: {
    pluralize: (entry, count) => entry[count === 0 && 'zero' in entry ? 'zero' : count === 1 ? 'one' : 'other'],
  },
};

export const initializeTranslations = () => {
  _t.registerTranslations('en', require('../../locales/en.json'));

  _t.registerTranslations('de', { ...localeDefaults, ...require('../../locales/de.json') });
  _t.registerTranslations('zh', { ...localeDefaults, ...require('../../locales/zh.json') });
  _t.registerTranslations('es', { ...localeDefaults, ...require('../../locales/es.json') });
  _t.registerTranslations('fr', { ...localeDefaults, ...require('../../locales/fr.json') });
  _t.registerTranslations('it', { ...localeDefaults, ...require('../../locales/it.json') });
  _t.registerTranslations('pt', { ...localeDefaults, ...require('../../locales/pt.json') });
  _t.registerTranslations('ru', { ...localeDefaults, ...require('../../locales/ru.json') });
  _t.registerTranslations('vi', { ...localeDefaults, ...require('../../locales/vi.json') });
  _t.registerTranslations('my', { ...localeDefaults, ...require('../../locales/my.json') });
  _t.registerTranslations('km', { ...localeDefaults, ...require('../../locales/km.json') });
  _t.registerTranslations('hi', { ...localeDefaults, ...require('../../locales/hi.json') });
  _t.registerTranslations('ja', { ...localeDefaults, ...require('../../locales/ja.json') });
  _t.registerTranslations('tr', { ...localeDefaults, ...require('../../locales/tr.json') });
  _t.registerTranslations('sw', { ...localeDefaults, ...require('../../locales/sw.json') });
  _t.registerTranslations('ur', { ...localeDefaults, ...require('../../locales/ur.json') });
  _t.registerTranslations('ar', { ...localeDefaults, ...require('../../locales/ar.json') });

  _t.setLocale('en');
}
