import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PositionSide, selectors } from 'wintrado-api';
import SoundBoard from 'soundboard';

let soundBoard;

const BELL_GRACE_PERIOD = 3000;

class SoundEffectPlayer extends PureComponent {
	constructor(props) {
		super(props);

		this.lastBellTimestamp = 0;
		soundBoard = new SoundBoard(props.soundstyle);
	}

	static playClick() {
		if (soundBoard) {
			soundBoard.playClick();
		}
	}

	static muteAllSounds() {
		if (soundBoard) {
			soundBoard.stopPositionOpen();
		}
	}

	playPositionSound() {
		if (!this.props.enabled || !soundBoard) {
			return;
		}
		soundBoard.playPositionOpen();
	}

	playBellSound() {
		if (this.props.bellEnabled && soundBoard) {
			soundBoard.playBell();
		}
	}

	componentWillUnmount() {
		this.muteAllPositionSounds();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.positionId && !this.props.positionId) {
			this.resetAllPositionSounds();
			return;
		}

		if (!prevProps.closing && this.props.closing) {
			this.resetAllPositionSounds();
			this.playPositionCloseSound();
			return;
		}

		if (prevProps.enabled && !this.props.enabled) {
			this.resetAllPositionSounds();
			return;
		}

		if (this.props.enabled && !prevProps.enabled && this.props.positionId) {
			this.playPositionSound();
		}

		if (this.props.profitLoss !== prevProps.profitLoss) {
			this.updateProfitLoss();
		}

		if (prevProps.positionId !== this.props.positionId && this.props.positionId) {
			this.playPositionSound();
			// Keep this return so that the bell doesn't ring on active position change
			return;
		}

		if (this.props.profitLoss > 0 && prevProps.profitLoss && prevProps.profitLoss <= 0) {
			const bellGracePeriodExpired = new Date().getTime() - this.lastBellTimestamp >= BELL_GRACE_PERIOD;
			if (bellGracePeriodExpired) {
				this.playBellSound();
				this.lastBellTimestamp = new Date().getTime();
			}
		}

		if (this.props.soundstyle !== prevProps.soundstyle) {
			soundBoard.changeSoundstyle(this.props.soundstyle);
		}
	}

	playPositionCloseSound() {
		if (this.props.enabled && soundBoard) {
			soundBoard.playTradeStop();
		}
	}

	updateProfitLoss = () => {
		const { position } = this.props;

		if (!position || !soundBoard) {
			return;
		}

		const currentPosPL = this.props.profitLossPoints;
		this.setPositionPL(this.calculateSoundPLParameter(currentPosPL, position.side));
	};

	calculateSoundPLParameter(rawPLPoints, side) {
		const plPoints = side === PositionSide.BUY ? rawPLPoints : rawPLPoints * -1;

		if (plPoints < 1) {
			return 0;
		}

		// TODO: Lower for testing
		const calculatedPoints = plPoints * 5 + 10;
		return calculatedPoints;
	}

	setPositionPL(profitLoss) {
		if (soundBoard) {
			soundBoard.setProfitLoss(Number(profitLoss));
		}
	}

	resetAllPositionSounds() {
		this.muteAllPositionSounds();
	}

	muteAllPositionSounds() {
		if (soundBoard) {
			soundBoard.stopPositionOpen();
		}
	}

	render() {
		return null;
	}
}

const mapStateToProps = (state, ownProps) => {
	const position = selectors.activePositionSelector(state);

	return {
		position,
		profitLoss: position ? position.profitLoss : null,
		profitLossPoints: selectors.activePositionPLPoints(state),
		positionId: state.activePositionId,
		enabled: state.settings.sound,
		bellEnabled: state.settings.bellSound,
		closing: state.closingAllPositions || state.closingSinglePosition,
		soundstyle: state.settings.soundstyle,
	};
};

export default connect(mapStateToProps)(SoundEffectPlayer);
