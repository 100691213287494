import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RiseFallIndicator, { Direction } from './RiseFallIndicator';
import AskBidPrices from './AskBidPrices';
import moment from 'moment';
import { getIconForCloseReasonIfExists } from '../helpers';
import { formatCurrency } from 'utils/currency';
import PositionStats from 'components/PositionStats';
import { PositionPlatform } from 'wintrado-api';
import Pill from 'components/Pill';
import InstrumentIcon from "components/InstrumentIcon/InstrumentIcon";
const _t = require('counterpart');
const ClosedPosition = ({ position, accountCurrency, instruments }) => {
    const { symbol, openPrice, closePrice, closedAt, profitLossInAccountCurrency } = position;
    const instrument = instruments[symbol];
    if (!instrument) {
        throw new Error(`Instrument ${symbol} not found`);
    }
    const positiveOrNegative = profitLossInAccountCurrency >= 0 ? 'positive' : 'negative';
    const iconForCloseReason = getIconForCloseReasonIfExists(position.closeReason);
    return (_jsxs("div", Object.assign({ className: "closed-position" }, { children: [_jsxs("div", Object.assign({ className: "closed-position__main-row" }, { children: [_jsxs("div", Object.assign({ className: "rise-fall-image-container" }, { children: [_jsxs("div", Object.assign({ className: "rise-fall-container" }, { children: [_jsx(RiseFallIndicator, { direction: profitLossInAccountCurrency >= 0.0 ? Direction.Up : Direction.Down, size: "big" }), _jsx(Pill, Object.assign({ blue: position.platform === PositionPlatform.MT4 }, { children: position.platform === PositionPlatform.MT4 ? 'MT4' : 'APP' }))] })), _jsx(InstrumentIcon, { symbol: instrument.symbol, imgUrl: instrument.iconUrl })] })), _jsxs("div", Object.assign({ className: "label-bid-container" }, { children: [_jsx("span", Object.assign({ className: "currency" }, { children: instrument ? instrument.symbol : symbol })), _jsx(AskBidPrices, { ask: openPrice, bid: closePrice, instrument: instrument })] })), _jsx(PositionStats, { position: position, instrument: instrument, accountCurrency: accountCurrency, showId: true })] })), _jsxs("div", Object.assign({ className: "closed-position__second-row" }, { children: [_jsx("div", Object.assign({ className: "close-time" }, { children: _jsx("span", { children: moment(closedAt).format('lll') }) })), _jsx("div", Object.assign({ className: "stop-reasons" }, { children: _jsxs("div", Object.assign({ className: "wrap" }, { children: [Boolean(iconForCloseReason) && _jsx("img", { src: iconForCloseReason, alt: position.closeReason }), _jsx("span", { children: _t.translate(`stop_reasons.${position.closeReason}`) })] })) })), _jsx("div", Object.assign({ className: `profit-loss ${positiveOrNegative}` }, { children: _jsx("span", { children: formatCurrency(profitLossInAccountCurrency, accountCurrency) }) }))] }))] })));
};
export default ClosedPosition;
