var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConfig } from "../utils/config";
import apiClient from './apiClient';
import queryString from 'query-string';
const getOAuthConfig = () => {
    const config = getConfig();
    if (!config) {
        throw Error('config not loaded');
    }
    if (!config.oAuth) {
        throw Error('oAuth config missing');
    }
    return config.oAuth;
};
export const getUser = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.get(getOAuthConfig().userInfoURL);
    return response.data;
});
export const getOAuthTokensWithCode = (code, clientId, clientSecret, redirectUri, grantType) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(getOAuthConfig().tokenURL, queryString.stringify({
        grant_type: grantType,
        code: code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        response_type: 'token',
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.data;
});
export const getOAuthTokensWithRefreshToken = (refreshToken, clientId, clientSecret, redirectUri) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(getOAuthConfig().tokenURL, queryString.stringify({
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        refresh_token: refreshToken,
        response_type: 'token',
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.data;
});
/**
 * Generates a logout link with the auth server which we need to redirect to. When performing a GET
 * request to that link, it will log the user out on the auth server and redirect back to the
 * originally provided `redirectUri` (which is given as an argument here).
 */
export const logout = (refreshToken, redirectUri) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield apiClient.post(getOAuthConfig().logoutURL, {
        refreshToken,
        redirectUri,
    });
    return response.data.redirectUri;
});
