import { API_LISTENER_CONNECTED, SET_SOUNDBANK_LOADING, SET_SOUNDBANK_LOADING_PERCENTAGE, SET_RELEASE_VERSION, FETCH_GREY_LABEL_LOGO_SUCCESS, FETCH_GREY_LABEL_LOGO_REQUESTED, FETCH_GREY_LABEL_LOGO_REJECTED, } from '../../actions';
export default function coreApiReducer(state, action) {
    switch (action.type) {
        case API_LISTENER_CONNECTED: {
            return Object.assign(Object.assign({}, state), { connection: Object.assign(Object.assign({}, state.connection), { apiConnected: true, isAttemptingReconnect: false, isReconnecting: false }) });
        }
        case SET_SOUNDBANK_LOADING:
            return Object.assign(Object.assign({}, state), { soundbankLoading: action.payload.loading });
        case SET_SOUNDBANK_LOADING_PERCENTAGE:
            return Object.assign(Object.assign({}, state), { soundbankLoadingPercentage: action.payload.percentage });
        case SET_RELEASE_VERSION: {
            return Object.assign(Object.assign({}, state), { releaseVersion: action.payload.version });
        }
        case FETCH_GREY_LABEL_LOGO_SUCCESS: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    data: action.payload.logos,
                    isLoading: false,
                } });
        }
        case FETCH_GREY_LABEL_LOGO_REQUESTED: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    isLoading: true,
                    data: null,
                } });
        }
        case FETCH_GREY_LABEL_LOGO_REJECTED: {
            return Object.assign(Object.assign({}, state), { greyLabelLogo: {
                    data: null,
                    isLoading: false,
                } });
        }
        default:
            return state;
    }
}
