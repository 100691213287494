import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { PageLayout } from 'components/global/PageLayout';
import _t from 'counterpart';
import classNames from 'classnames';
import Loader from 'components/Loader/Loader';
import Error from 'components/Error/Error';
import { Desktop } from 'utils/responsiveHelpers';
import { ErrorScreen } from 'components/global/ErrorScreen';
import { BackButton } from 'components/BackButton';
import AnyLink from 'components/global/AnyLink';
import Button from 'components/Button/Button';
import { useParams } from 'react-router-dom';
import { getMessage, markMessageAsRead } from 'wintrado-api/src/services/crmService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { extractErrorMessage } from '../../helpers';
const PageMessageInner = () => {
    var _a, _b, _c, _d, _e;
    const { id } = useParams();
    const queryClient = useQueryClient();
    const messageQuery = useQuery({
        queryKey: ['unread-message', id],
        queryFn: () => getMessage(id),
        refetchOnWindowFocus: true,
        enabled: id !== undefined,
        retry: false,
    });
    const markMessageAsReadMutation = useMutation({
        mutationFn: (msgId) => markMessageAsRead(msgId),
        onSuccess: () => {
            queryClient.invalidateQueries(['unread-messages']);
            queryClient.invalidateQueries(['unread-message', id]);
        },
    });
    useEffect(() => {
        var _a;
        if (markMessageAsReadMutation.status === 'idle' &&
            messageQuery.isSuccess &&
            ((_a = messageQuery.data) === null || _a === void 0 ? void 0 : _a.message.readAt) === null) {
            markMessageAsReadMutation.mutate(id);
        }
    }, [messageQuery.isSuccess, markMessageAsReadMutation, id, (_a = messageQuery.data) === null || _a === void 0 ? void 0 : _a.message.readAt]);
    const renderMessageInnerContent = () => {
        var _a;
        if (messageQuery.isLoading || messageQuery.isIdle) {
            return _jsx(Loader, {});
        }
        if (messageQuery.isError) {
            return _jsx(Error, { message: extractErrorMessage(messageQuery.error) });
        }
        const { message } = messageQuery.data;
        if (message) {
            return (_jsxs("div", Object.assign({ className: "message-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "message-wrapper__content" }, { children: [_jsx("span", Object.assign({ className: "message-wrapper__date" }, { children: new Date(message.createdAt).toLocaleDateString() })), _jsx("p", Object.assign({ className: "message-wrapper__title" }, { children: message.subject })), _jsx("p", Object.assign({ className: "message-wrapper__message" }, { children: message.text }))] })), ((_a = message.actions) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx("div", Object.assign({ className: "message-wrapper__actions" }, { children: message.actions.map((item) => (_jsx(AnyLink, Object.assign({ className: `message-action ${item.type}`, to: item.url }, { children: _jsx(Button, Object.assign({ green: item.type === 'yes', red: item.type === 'no', gray: item.type === 'learn-more' }, { children: _t.translate(`main.${item.type}`) })) }), item.type))) })))] })));
        }
        return _jsx(ErrorScreen, {});
    };
    const actionsCount = (_e = (_d = (_c = (_b = messageQuery.data) === null || _b === void 0 ? void 0 : _b.message) === null || _c === void 0 ? void 0 : _c.actions) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0;
    return (_jsxs(PageLayout, Object.assign({ className: classNames('message-inner', {
            'message-inner--with-actions': actionsCount > 0,
        }), title: _t('page.messages') }, { children: [_jsx(Desktop, { children: _jsx(BackButton, {}) }), renderMessageInnerContent()] })));
};
export default PageMessageInner;
