import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import RiseFallIndicator, { Direction } from './RiseFallIndicator';

class InstrumentQuoteIndicator extends PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.instruments) return null;
		const instrument = this.props.instruments[this.props.symbol];
		if (!instrument) {
			throw new Error(`Instrument ${this.props.symbol} not found in store.`);
		}

		const rising = this.props.lastQuote < this.props.currentQuote;
		const quotedValue = this.props.currentQuote.toFixed(instrument.decimals);

		return (
			<div className="instrument-quote-indicator">
				{this.props.showRiseFall && (
					<RiseFallIndicator direction={rising ? Direction.Up : Direction.Down} size="small" />
				)}
				<span style={this.props.textStyle}>{quotedValue}</span>
			</div>
		);
	}
}

export default connect((state, ownProps) => {
	const getValue = (quote) => {
		switch (ownProps.type) {
			case 'ask':
				return quote.ask;
			case 'bid':
				return quote.bid;
			default:
				return (lastQuote.ask + lastQuote.bid) / 2;
		}
	};

	const quotesForSymbol = state.quotes[ownProps.symbol];
	if (!quotesForSymbol || quotesForSymbol.length < 2) {
		return { ...ownProps, lastQuote: 0, currentQuote: 0 };
	}

	const lastQuote = quotesForSymbol[quotesForSymbol.length - 2];
	const currentQuote = quotesForSymbol[quotesForSymbol.length - 1];

	return {
		instruments: state.instruments,
		lastQuote: getValue(lastQuote),
		currentQuote: getValue(currentQuote),
	};
})(InstrumentQuoteIndicator);
