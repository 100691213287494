var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './SelectItem.module.scss';
import * as SelectPrimitive from '@radix-ui/react-select';
import classNames from 'classnames';
import FiCheck from "../../icons/fi-checked.svg?url";
const SelectItem = React.forwardRef((_a, forwardedRef) => {
    var { children, asChild = true, hideIcon = false } = _a, props = __rest(_a, ["children", "asChild", "hideIcon"]);
    return (_jsxs(SelectPrimitive.Item, Object.assign({}, props, { ref: forwardedRef, className: classNames(styles.item, props.className), asChild: asChild }, { children: [_jsx(SelectPrimitive.ItemText, { children: children }), !hideIcon && (_jsx(SelectPrimitive.ItemIndicator, { children: _jsx("img", { src: FiCheck, alt: "FiCheck" }) }))] })));
});
SelectItem.displayName = 'SelectItem';
export default React.memo(SelectItem);
