import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { selectors, useAppSelector } from 'wintrado-api';
import React from 'react';
import Tooltip from 'components/Tooltip/Tooltip';
import _t from 'counterpart';
import { isSet } from '../helpers';
const DesktopInstrumentTrendIndicator = () => {
    const activeInstrumentTrendPercentage = useAppSelector(selectors.activeInstrumentTrend);
    if (!isSet(activeInstrumentTrendPercentage)) {
        return null;
    }
    const { buyPercentage, sellPercentage } = activeInstrumentTrendPercentage;
    return (_jsx("div", Object.assign({ className: "desktop-instrument-trend-indicator" }, { children: _jsx(Tooltip, Object.assign({ content: _jsxs("div", Object.assign({ className: "content-wrapper" }, { children: [_jsx("p", { className: "indicator-description indicator-description--sell", dangerouslySetInnerHTML: {
                            __html: _t.translate('trend-indicator.sell-percentage-description', {
                                percentage: `<strong>${sellPercentage}%</strong>`,
                            }),
                        } }), _jsx("p", { className: "indicator-description indicator-description--buy", dangerouslySetInnerHTML: {
                            __html: _t.translate('trend-indicator.buy-percentage-description', {
                                percentage: `<strong>${buyPercentage}%</strong>`,
                            }),
                        } })] })), className: "trend-indicator-tooltip", side: "top" }, { children: _jsxs("div", Object.assign({ className: "desktop-instrument-trend-indicator__wrapper" }, { children: [_jsx("span", Object.assign({ className: "label label-sell" }, { children: `${sellPercentage}%` })), _jsxs("div", Object.assign({ className: "bar-wrapper" }, { children: [_jsx("div", { className: "sell-bar", style: { width: `${sellPercentage}%` } }), _jsx("div", { className: "buy-bar", style: { width: `${buyPercentage}%` } })] })), _jsx("span", Object.assign({ className: "label label-buy" }, { children: `${buyPercentage}%` }))] })) })) })));
};
export default React.memo(DesktopInstrumentTrendIndicator);
