import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const BackButton = ({ useCloseIcon = false, useRouterBack = false }) => {
	const location = useLocation();
	const history = useHistory();

	const getIcon = useMemo(() => {
		return useCloseIcon ? require('../icons/close.svg?url') : require('../icons/back-icon.svg?url');
	}, [useCloseIcon]);

	const goBack = () => {
		// custom back handling for going back without relying on window.history
		const currentRoute = location.pathname;
		const backRoute = currentRoute.split('/').slice(0, -1).join('/');

		history.replace(backRoute);
	};

	return (
		<div className="back-button" onClick={goBack}>
			<img src={getIcon} alt="back-icon" className="back-button__icon" />
		</div>
	);
};
