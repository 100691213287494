import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BuySellIconAlternative } from 'components/BuySellIconAlternative';
import { isSet } from '../helpers';
import PositionStats from 'components/PositionStats';
import { PositionSide, selectors, useAppSelector } from 'wintrado-api';
import InstrumentQuoteIndicator from 'components/InstrumentQuoteIndicator';
import * as Sentry from '@sentry/react';
import MobileInstrumentTrendIndicator from './MobileInstrumentTrendIndicator';
import InstrumentIcon from './InstrumentIcon/InstrumentIcon';
const OpenPositionBig = ({ activePosition, onClickInstrument }) => {
    const accountCurrency = useAppSelector((state) => state.user.accountCurrency);
    const activeInstrument = useAppSelector((state) => state.activeInstrument);
    const activeInstrumentTrend = useAppSelector(selectors.activeInstrumentTrend);
    if (!activeInstrument || !activePosition) {
        Sentry.captureException(new Error('activeInstrument and/or activePosition undefined'));
        return null;
    }
    return (_jsx("div", Object.assign({ className: "open-position-big" }, { children: _jsxs("div", Object.assign({ className: "position-info-row" }, { children: [_jsxs("div", Object.assign({ className: "buy-sell-trend-wrapper" }, { children: [isSet(activeInstrumentTrend) && _jsx(MobileInstrumentTrendIndicator, {}), !isSet(activeInstrumentTrend) && (_jsx("div", Object.assign({ className: "buy-sell-wrapper" }, { children: _jsx(BuySellIconAlternative, { side: activePosition.side, width: 40, height: 40 }) })))] })), _jsx("div", Object.assign({ className: "icon-wrapper", onClick: onClickInstrument }, { children: _jsx("div", Object.assign({ className: "icon-wrapper__holder" }, { children: _jsx("div", Object.assign({ className: "relative-wrapper" }, { children: _jsx(InstrumentIcon, { symbol: activeInstrument.symbol, imgUrl: activeInstrument.iconUrl }) })) })) })), _jsxs("div", Object.assign({ className: "instrument-info" }, { children: [_jsxs("div", Object.assign({ className: "instrument-info__general" }, { children: [_jsx("div", Object.assign({ className: "currency" }, { children: _jsx("span", { children: activeInstrument.symbol }) })), _jsx("div", Object.assign({ className: "rise-fall" }, { children: _jsx(InstrumentQuoteIndicator, { symbol: activePosition.symbol, showRiseFall: true, type: activePosition.side === PositionSide.BUY ? 'bid' : 'ask' }) }))] })), _jsx("div", Object.assign({ className: "instrument-info__details" }, { children: _jsx(PositionStats, { position: activePosition, instrument: activeInstrument, accountCurrency: accountCurrency }) }))] }))] })) })));
};
export default React.memo(OpenPositionBig);
